<template>
  <v-text-field
      density="compact"
      label="Nazwa produktu"
      :modelValue="title"
      variant="outlined"
      @input="$emit('update:title', $event.target.value)"
      :rules="nameRule"
  />
  <v-text-field
      density="compact"
      variant="outlined"
      :modelValue="ean"
      label="EAN"
      @input="$emit('update:ean', $event.target.value)"
      :rules="eanRule"
  ></v-text-field>
  <v-textarea
      label="Opis produktu"
      :modelValue="description"
      variant="outlined"
      @input="$emit('update:description', $event.target.value)"
      :rules="descriptionRule"
  >
  </v-textarea>
  <v-text-field
      density="compact"
      label="Wskaźniki / Tagi"
      :modelValue="tags"
      variant="outlined"
      @input="$emit('update:tags', $event.target.value)"
  />
</template>
<script>
export default {
  name: 'ProductDefaultForm',
  props: {
    title: String,
    description: String,
    tags: String,
    ean: String,
  },
  emits: [
    'update:title',
    'update:description',
    'update:tags'
  ],
  data() {
    return {
      nameRule: [
        value => {
          if (value) return true
          return 'Wymagane.'
        },
        value => {
          if (value.length <= 255) return true
          return 'Maksymalna liczba znaków to 255.'
        },
      ],
      eanRule: [
/*        value => {
          if (value) return true
          return 'Wymagane.'
        },*/
        value => {
          if (value && value.length <= 255) return true
          return 'Maksymalna liczba znaków to 255.'
        },
      ],
      descriptionRule: [
        value => {
          if (!value || value.length <= 255) return true
          return 'Maksymalna liczba znaków to 255.'
        },
      ],
    }
  }
}
</script>

