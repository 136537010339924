<template>
  <v-layout ref="app" style="height:90vh;">
    <v-app-bar density="compact" class="px-2">
      <v-row>
        <v-col cols="auto">
          <BreadcrumbsComponent :items="breadcrumbs"/>
        </v-col>
        <v-col style="z-index: 2;">
          <v-btn
              color="blue"
              style="margin-top:6px; "
              class="float-start mx-1"
              v-bind="props"
              size="x-small"
              prepend-icon="mdi-file-tree-outline"
              to="/projects/element"
              v-if="checkRoles(['ROLE_TICKETS_READ','ROLE_ADMIN'])"
              @click="methodClearInterval()"
          >
            Gałęzie drzewa
          </v-btn>
          <v-btn
              color="green"
              style="margin-top:6px; "
              class="float-start mx-1"
              size="x-small"
              :loading="loadingSave"
              prepend-icon="mdi-content-save"
              @click="saveProject(typeProject)"
              :disabled="!isFormValid"
          >
            Zapisz
            <template v-if="loadingSaveTime">({{ loadingSaveTime }})</template>
          </v-btn>
          <v-btn
              color="green"
              style="margin-top:6px;"
              class="float-start mx-1"
              size="x-small"
              prepend-icon="mdi-calculator"
              @click="getMath"
          >
          </v-btn>

          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                  color="green"
                  style="margin-top:6px; "
                  class="float-start mx-1"
                  size="x-small"
                  prepend-icon="mdi-update"
                  v-bind="props"
              >
                Aktualizuj
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="updateProducts">
                <v-list-item-title>Aktualizuj materiały w tym projekcie</v-list-item-title>
              </v-list-item>
              <v-list-item @click="updateProducts(all)">
                <v-list-item-title>Aktualizuj materiały wszędzie</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-select
              class="x-small float-start mx-1 mt-1"
              :items="[{id: '0', title: 'Pionowo'},{id: '1', title: 'Poziomo'}]"
              item-text="title"
              item-value="id"
              v-model="treeOrientation"
              variant="outlined"
              density="compact"
              hide-details
          >
          </v-select>
          <v-btn
              :to="'/products/tree2/' + lastProject" v-if="lastProject !== null"
              color="green"
              style="margin-top:6px;"
              class="float-start mx-1"
              size="x-small"
              prepend-icon="mdi-arrow-left-bold-box"
          >
            Powrót do projektu
          </v-btn>
        </v-col>

      </v-row>
    </v-app-bar>
    <v-main class="mt-3" style="height:100%">

      <v-form v-model="isFormValid">
        <v-container style="z-index:0;">
          <div v-if="selected !== null" id="scene">
            <div class="">
              <div v-if="productsTree !== null" class="text-center tree" ref="headRef" style="position:relative;">
                <blocks-tree :data="productsTree.valueOf()"
                             :horizontal="treeOrientation==='1'"
                             class=" scrolled"
                             :collapsable="collapsable"
                             @change="getMath">
                  <template #node="{data}">
                    <v-card v-if="data.id === 0"
                            color="blue"
                            style="z-index: 1"
                            class="overflow-visible position-relative"
                    >
                      <v-card-item>
                      </v-card-item>
                    </v-card>

                    <v-card v-if="data.id !== 0"
                            :style="'z-index: 1; min-width:400px; position:relative; ' +
                       'border-size: 4px;'"
                            :class="'position-relative overflow-visible rounded-ts-0'"

                    >
                      <v-btn prepend-icon="mdi-palette"
                             size="x-small"
                             class="rounded-t-sm rounded-b-0 elevation-0"
                             color="secondaryDarken"
                             style="position:absolute; top: -20px; left: 0; ">
                        Kolor karty
                        <v-tooltip activator="parent"
                        >
                          Wybierz kolor karty
                        </v-tooltip>

                        <v-dialog
                            v-model="dialogColor[data.id]"
                            activator="parent"
                            width="auto"
                        >
                          <v-card>
                            <v-card-text>
                              <v-row>
                                <v-col>
                                  Tło
                                  <v-color-picker
                                      class="ma-2"
                                      show-swatches
                                      swatches-max-height="400px"
                                      @update:modelValue="updateTree($event, data.id, 'color')"
                                      :modelValue="data.color"
                                  ></v-color-picker>
                                </v-col>
                                <v-col>
                                  Tekst
                                  <v-color-picker
                                      class="ma-2"
                                      show-swatches
                                      swatches-max-height="400px"
                                      @update:modelValue="updateTree($event, data.id, 'colorText')"
                                      :modelValue="data.colorText"
                                  ></v-color-picker>
                                </v-col>
                              </v-row>

                            </v-card-text>
                            <v-card-actions>
                              <AnnulledButton @click="dialogColor[data.id] = false" label="Zamknij"></AnnulledButton>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-btn>
                      <v-btn prepend-icon="mdi-cog"
                             size="x-small"
                             class="rounded-t-sm rounded-b-0 elevation-0"
                             color="secondaryDarken"
                             v-show="data.id !== 1"
                             style="position:absolute; top: -20px; right: 0; ">
                        Ustawienia
                        <v-tooltip activator="parent"
                        >
                          Dodatkowe ustawienia
                        </v-tooltip>

                        <v-dialog
                            v-model="dialogSetting[data.id]"
                            activator="parent"
                            width="auto"
                        >
                          <v-card>
                            <v-card-text>
                              <ProductType

                                  :isScreen="data.isScreen"
                                  @update:isScreen="updateTree($event, data.id, 'isScreen')"
                                  :isPublic="data.isPublic"
                                  @update:isPublic="updateTree($event, data.id, 'isPublic')"
                                  :oneChildren="data.oneChildren"
                                  @update:oneChildren="updateTree($event, data.id, 'oneChildren')"
                                  :isRequired="data.isRequired"
                                  @update:isRequired="updateTree($event, data.id, 'isRequired')"
                              />
                            </v-card-text>
                            <v-card-actions>
                              <AnnulledButton @click="dialogSetting[data.id] = false" label="Zamknij"></AnnulledButton>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-btn>

                      <v-btn :to="'/products/tree2/' + data.element" v-if="data.element !== undefined"
                             class="position-absolute" @click="goToElement()"
                             style="top:-5px; right: -5px; width:20px; height: 20px; background-color: #910000;">
                        <v-tooltip activator="parent" location="end">
                          Element drzewa
                        </v-tooltip>
                      </v-btn>

                      <v-code size="x-small"
                              v-if="data.product"
                              class="rounded-t-sm rounded-b-0 elevation-0"
                              color="secondaryDarken"
                              style="position:absolute; top: -20px; right: 0; ">
                        {{ data.product }}
                        <v-tooltip activator="parent"
                        >
                          Id Produktu
                        </v-tooltip>
                      </v-code>
                      <v-code size="x-small"
                              v-if="data.product"
                              class="rounded-t-sm rounded-b-0 elevation-0"
                              color="secondaryDarken"
                              style="position:absolute; top: -40px; right: 0; ">
                        {{ data.id }}
                        <v-tooltip activator="parent"
                        >
                          Id Gałęzi
                        </v-tooltip>
                      </v-code>

                      <!--                              v-if="data.oneChildren"
                      searchProductById(data.parentId)?.oneChildren
                      -->
                      <v-code size="x-small"
                              v-if="checkIfParentHasOneChildren(data)"
                              class="rounded-t-sm rounded-b-0 elevation-0"
                              style="position:absolute; top: 25px; right: 25px; z-index: 10; background: transparent;">
                        <input style="width: 25px; height: 25px;"
                               type="radio"
                               @click="handleRadioCheckParentCalculator(data)"
                               id="oneChildren"
                               :name="'childrenType' + (data.parentId ? data.parentId : '')">
                        <v-tooltip activator="parent"
                        >
                          <label for="oneChildren">Wybierz element</label>
                        </v-tooltip>
                      </v-code>
                      <v-card-title v-show="data.label"
                                    :style="'background-color:' + data.color + '; color:' + data.colorText + '; width: 430px; word-break: break-word;'">
                        <h1 class="pa-4" style="font-size: 25pt;">{{ data.label }} <span
                            v-if="data.isRequired=== true">*</span></h1>
                      </v-card-title>

                      <BranchDetail
                          :tree="productsTree"
                          :branch="data"
                          :chips="chips"
                          :chipsMath="chipsMath"
                          :math="math"
                          @update:chips="(value,feature, idBranch) => updateTree(value, idBranch, 'feature',feature)"
                          @update:newChips="(value, idBranch) => this.chips[idBranch] = value"
                          @update:newChipsMath="(value, idBranch) => this.chipsMath[idBranch] = value"
                          @update:math="(value, featureId, idBranch) => updateTree(value, idBranch, 'math', featureId)"
                          @update:product="(event ,branchId) => updateTree(event, branchId,'product')"
                          @update:addchip="(sF1, sF2, title,value) => addChip(sF1,sF2,title,value)"
                      ></BranchDetail>

                      <!--

                          -->
                      <template v-if="!data.label">
                        test
                        <div v-show="false">{{ settingPanel[data.id] = 0 }}</div>
                      </template>

                      <v-list-item
                          v-for="n in data.features"
                          :key="n.id"
                          :title="n.title"
                          :subtitle="n.value"
                      ></v-list-item>
                      <v-expansion-panels v-model="settingPanel[data.id]">
                        <v-expansion-panel v-model="settingPanel[data.id]"
                                           @click="getProductsFromCategory(data.categories)">
                          <v-expansion-panel-title
                              style="height:20px; font-size: 10px; padding: 5px; min-height:20px;"

                          >
                            Szczegóły
                          </v-expansion-panel-title>
                          <v-expansion-panel-text>
                            <v-card-text class="pt-3">
                              <v-select
                                  class="x-small"
                                  variant="outlined"
                                  density="compact"
                                  :items="productType"
                                  item-title="label"
                                  item-value="value"
                                  hide-details
                                  @update:modelValue="updateTree($event, data.id, 'type')"
                                  :modelValue="data.type"
                              ></v-select>
                              <v-text-field
                                  class="mt-4"
                                  label="Nazwa"
                                  variant="outlined"
                                  density="compact"
                                  @input="updateTree($event, data.id,'label')"
                                  :modelValue="data.label"
                                  :rules="nameRules"
                              ></v-text-field>
                              <v-autocomplete
                                  v-if="data.type === 'build'"
                                  class="x-small"
                                  density="compact"
                                  variant="outlined"
                                  label="Wybierz kategorię"
                                  :items="categoryOwn"
                                  item-title="title"
                                  item-value="id"
                                  @update:modelValue="updateTree($event, data.id,'categories')"
                                  :modelValue="data.categories"
                              ></v-autocomplete>
                              <v-autocomplete
                                  v-if="data.type === 'ready'"
                                  class="x-small"
                                  density="compact"
                                  variant="outlined"
                                  label="Wybierz kategorię"
                                  :items="categoryCommercial"
                                  item-title="title"
                                  item-value="id"
                                  @update:modelValue="updateTree($event, data.id,'categories')"
                                  :modelValue="data.categories"
                              ></v-autocomplete>
                              <!-- Cechy -->

                              <template v-if="data.categories && data.type === 'ready'">
                                <v-autocomplete
                                    density="compact"
                                    label="Produkt"
                                    variant="outlined"
                                    class="x-small"
                                    :items="readyProductsFromCategory[data.categories]"
                                    item-value="id"
                                    item-title="title"
                                    @update:modelValue="updateTree($event, data.id,'product')"
                                    :modelValue="data.product"
                                ></v-autocomplete>
                              </template>


                              <div v-if="data.features && data.features.length > 0">
                                <v-expansion-panels>
                                  <v-expansion-panel>
                                    <v-expansion-panel-title
                                        style="height:20px; font-size: 10px; padding: 5px; min-height:20px;">
                                      Cechy
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text>
                                      <FeaturesView
                                          :id-branch="data.id"
                                          :features="data.features"
                                          :branch="data"
                                          :chips="chips"
                                          :chips-math="chipsMath"
                                          :math="math"
                                          @update:chips="(featureId, value) => updateTree(value, data.id, 'feature',featureId)"
                                          @update:newChips="(value) => this.chips[data.id] = value"
                                          @update:newChipsMath="(value) => this.chipsMath[data.id] = value"
                                          @update:math="(value, featureId) => updateTree(value, data.id, 'math', featureId)"
                                          @update:addchip="(sF1, sF2, title,value) => addChip(sF1,sF2,title,value)"
                                      ></FeaturesView>

                                    </v-expansion-panel-text>
                                  </v-expansion-panel>
                                </v-expansion-panels>
                              </div>


                            </v-card-text>
                          </v-expansion-panel-text>
                        </v-expansion-panel>
                      </v-expansion-panels>


                      <!-- ########################### -->

                      <v-card v-if="data.product && data.type === 'ready' || data.id === 0" flex
                              class="position-relative product-detail"
                              style="width:450px;"
                      >
                        <v-card-text v-if="data.price" flex>
                          <strong style="display:block; position:relative;" v-if="data.type === 'ready'">
                          <span v-show="data.price">Cena całkowita: {{ data.price }} {{
                              data.priceLabelUnit
                            }}</span><br>
                            <span v-show="data.priceUnit">Cena jednostki: {{ data.priceUnit }} {{
                                data.priceReadyLabelUnit
                              }}</span> <br>
                            <div v-for="itemf in data.features" :key="itemf.id">
                              <span v-show="itemf.value">
                                {{ itemf.title }}: {{ itemf.value }}
                                {{ itemf.units[0].title }}
                              </span><br>
                            </div>
                          </strong>
                        </v-card-text>
                      </v-card>

                      <v-card-item v-if="data.image || data.images">
                        <v-avatar v-if="data.image"
                                  class="ma-3"
                                  size="225"
                                  rounded="0"
                        >
                          <v-img :src="process.env.VUE_APP_API_LOCATION + '/'+data.image"></v-img>
                        </v-avatar>
                        <div v-if="data.images">
                          <v-img style="width:50px; float:left; " v-for="image in data.images" :key="image"
                                 :src="process.env.VUE_APP_API_LOCATION + '/'+image.name"></v-img>
                        </div>
                      </v-card-item>


                      <v-card-item>
                        <v-btn
                            color="green"
                            style="margin-top:6px;margin-bottom:5px;"
                            class="float-end ms-1"
                            size="x-small"
                            prepend-icon="mdi-plus-box"
                            @click="addBranch(data.id,productsTree,data.color,data.colorText)">Dodaj
                        </v-btn>
                        <v-btn
                            v-if="data.product"
                            color="orange"
                            style="margin-top:6px;margin-bottom:5px;"
                            class="float-end ms-1"
                            size="x-small"
                            prepend-icon="mdi mdi-warehouse"
                            @click="productDialog[data.product] = true"
                        >Produkt
                        </v-btn>

                        <v-dialog v-model="productDialog[data.product]" persistent max-width="900px">
                          <ProductsSingleDialogView :productId="data.product"
                                                    @closeDialog="productDialog[data.product] = false">
                          </ProductsSingleDialogView>
                        </v-dialog>

                        <v-menu class="float-end ms-1" v-model="menuD[data.id]">
                          <template v-slot:activator="{ props: menu }">
                            <v-tooltip location="top">
                              <template v-slot:activator="{ props: tooltip }">
                                <v-btn
                                    color="blue"
                                    v-bind="mergeProps(menu, tooltip)"
                                    size="x-small"
                                    class="float-end ms-1"
                                    style="margin-top:6px; margin-bottom: 5px;"
                                    prepend-icon="mdi mdi-cog-outline"
                                >
                                  Opcje
                                </v-btn>
                              </template>
                              <span>Zobacz wszystkie opcje</span>
                            </v-tooltip>
                          </template>
                          <v-list>
                            <v-list-item v-if="data.element" @click="updateElement(data.id,data.element)">
                              <v-list-item-title class="title">Aktualizuj gałąź</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title class="title">Podepnij gałąź</v-list-item-title>
                              <v-dialog
                                  v-model="dialogTreeUpload[data.id]"
                                  activator="parent"
                                  width="auto"
                              >
                                <v-card>
                                  <v-card-text>
                                    <v-select
                                        class="x-small"
                                        variant="outlined"
                                        density="compact"
                                        hide-details
                                        :items="projectsElements.projectsElements"
                                        item-value="id"
                                        item-title="title"
                                        @update:modelValue="loadTree($event)"
                                    ></v-select>

                                  </v-card-text>
                                  <ActionsButton @annulled="dialogTreeUpload[data.id] = false"
                                                 @save="loadTreeDown(data.id)"></ActionsButton>

                                </v-card>
                              </v-dialog>
                            </v-list-item>
                            <v-list-item v-if="data.element" @click="saveProject('element', data.id, data.element)">
                              <v-list-item-title class="title">Zapisz element</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="!data.element">
                              <v-list-item-title class="title">Zapisz jako gałąź</v-list-item-title>
                              <v-dialog
                                  v-model="dialogTree[data.id]"
                                  activator="parent"
                                  width="auto"
                              >
                                <v-card>
                                  <v-card-text>
                                    <v-text-field style="width: 550px;" label="Nazwa elementu" variant="outlined"
                                                  v-model="titleProjectTree[data.id]"></v-text-field>
                                  </v-card-text>
                                  <ActionsButton
                                      @annulled="dialogTree[data.id] = false" @save="saveProject('element',data.id )"
                                  ></ActionsButton>
                                </v-card>
                              </v-dialog>
                            </v-list-item>
                            <v-list-item @click="deleteBranch(data,productsTree)">
                              <v-list-item-title class="title">Usuń</v-list-item-title>
                            </v-list-item>

                            <v-list-item v-if="data.image && !data.product">
                              <v-list-item-title class="title">Usuń zdjęcie</v-list-item-title>
                              <v-dialog
                                  v-model="dialogTreeDeleteFile[data.id]"
                                  activator="parent"
                                  width="auto"
                              >

                                <v-card>
                                  <!-- TODO: Funkcja usuwająca zdjęcie, ale musi dostać productId, a nie zawsze dostaje  -->
                                  <form @submit="deleteFile">

                                    <v-card-text>
                                      <input type="hidden" ref="productId" :value="data.product"/>
                                      <input type="hidden" ref="idTree" :value="data.id"/>
                                    </v-card-text>
                                    <ActionsButton
                                        actionLabel="Usuń załącznik"
                                        actionColor="red"
                                        @annulled="dialogTreeDeleteFile[data.id] = false" @save="deleteFile"
                                    ></ActionsButton>
                                  </form>
                                </v-card>
                              </v-dialog>
                            </v-list-item>
                            <v-list-item v-if="!data.image && !data.product">
                              <v-list-item-title class="title">Dodaj zdjęcie</v-list-item-title>
                              <v-dialog
                                  v-model="dialogTreeUploadFile[data.id]"
                                  activator="parent"
                                  width="auto"
                              >
                                <v-card>
                                  <form @submit="uploadFile">
                                    <v-card-text>
                                      <input type="file" ref="fileInput"/>
                                      <input type="hidden" ref="productTitle" :value="data.label"/>
                                      <input type="hidden" ref="productId" :value="data.product"/>
                                      <input type="hidden" ref="idTree" :value="data.id"/>
                                    </v-card-text>
                                    <ActionsButton
                                        actionLabel="Wgraj"
                                        actionColor="green"
                                        @annulled="dialogTreeUploadFile[data.id] = false" @save="uploadFile"
                                    ></ActionsButton>
                                  </form>
                                </v-card>
                              </v-dialog>
                            </v-list-item>


                          </v-list>
                        </v-menu>

                        <!--                  <label class="form-switch form-switch-lg">
                           <input class="form-check-input"
                                  type="radio"
                                  :name="'radio_' + checkParent(data.id)" :value="data.id"
                                  :checked="selected.indexOf(data.id) > -1"
                                  @change="toggleSelect(data,$event.target.checked)"
                           ></label>-->
                      </v-card-item>
                    </v-card>

                  </template>
                </blocks-tree>
              </div>
              <div v-else-if="productsTree === null">
                <div class="text-center">
                  <v-progress-circular
                      indeterminate
                      color="red"
                      :size="70"
                      :width="7"
                  ></v-progress-circular>
                </div>
              </div>
            </div>
          </div>
          <v-bottom-navigation :elevation="0" color="teal"
                               grow style="position:absolute; left:50%;
                             margin-left: -250px; bottom: 10px; width:500px;">

            <v-btn disabled="true" size="small">Przesuwanie z klawiszem: ALT</v-btn>

            <v-btn class="float-start mx-1"
                   size="small"
                   icon="mdi-plus-box"
                   color="grey"
                   @click="zoomIn"></v-btn>
            <v-btn class="float-start mx-1"
                   size="small"
                   icon="mdi-minus-box"
                   color="grey"
                   @click="zoomOut"></v-btn>
            <v-btn class="float-start mx-1"
                   size="small"
                   icon="mdi-overscan"
                   color="grey"
                   @click="zoomReset"></v-btn>
          </v-bottom-navigation>
        </v-container>
      </v-form>
    </v-main>
  </v-layout>
</template>
<script>
//import axios from "axios";
//import {generateUniqueId, addMath, rozdzielDzialanie} from '@/views/tree/js/main.js'


import VueBlocksTree from 'vue3-blocks-tree';
import 'vue3-blocks-tree/dist/vue3-blocks-tree.css';
import * as panzoom from "panzoom";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
//import {mapGetters, mapState} from "vuex";
import ProductsSingleDialogView from "@/views/products/ProductsSingleDialogView.vue";
import {mergeProps} from 'vue'

import ProductType from "@/views/products/products/ProductType.vue";
//import {useStorage} from "vue3-storage";
import BranchDetail from "@/views/tree/components/BranchDetail.vue";
import FeaturesView from "@/views/tree/components/FeaturesView.vue";
import {useStorage} from "vue3-storage";
import {mapGetters, mapState} from "vuex";
import {
  addMath,
  generateUniqueId, mathBranchValues,
  mathSpecificValueOfaFeature,
  mathSpecificValueOfaFeatureOfTheSelectedProduct, mathSumOfAllChildren, mathSumOfAllChildrenFeature, mathVariableValue,
  rozdzielDzialanie
} from "@/views/tree/js/main";
import ActionsButton from "@/components/navigation/ActionsButton.vue";
import AnnulledButton from "@/components/navigation/element/AnnulledButton.vue";
import axios from "axios";


export default {
  name: "ProductTree2",
  components: {
    AnnulledButton,
    ActionsButton,
    FeaturesView,
    BranchDetail,
    ProductType,
    ProductsSingleDialogView,
    BreadcrumbsComponent,
    'blocks-tree': VueBlocksTree,
  },

  data() {
    return {
      loadingSaveTime: null,
      zapis: null,
      isPublic: [],
      loadingSave: false,
      oneChildren: [],
      isScreen: [],
      isRequired: [],
      menuD: [],
      dialog: false,
      error: [],
      dialogColor: [],
      dialogSetting: [],
      settingPanel: [],
      changeBranchId: null,
      blockColor: [],
      loadTreeData: null,
      readyProductsFromCategory: [],
      items: ['Gaming', 'Programming', 'Vue', 'Vuetify'],
      model: ['Vuetify'],
      search: null,
      duplicated: [],
      titleProject: '',
      titleProjectTree: [],
      dialogTree: [],
      dialogTreeUpload: [],
      dialogTreeUploadFile: [],
      dialogTreeDeleteFile: [],

      clear: '',
      dialogProduct: [],
      x: 0,
      y: 0,
      searchTerm: "",
      chips: [],
      chipsMath: [],

      chipsO: [],
      chipsMathO: [],

      dialogCalc: [],
      itemSelect: [],
      itemSelect_: "",
      sF1: null,
      sF2: null,

      productType: [
        {
          value: 'category',
          label: 'Wirtualny',
        },
        {
          value: 'build',
          label: 'Produkt własny',
        },
        {
          value: 'ready',
          label: 'Produkt handlowy',
        },
      ]
      ,

      changeTree: null,

      typeProject: null,
      breadcrumbs: [
        {
          title: 'Projekt',
          disabled: true,
        },
        {
          title: this.titleProject,
          disabled: true,
        },
      ],

      categoryFeatures: [],

      math: [],
      productsTree: null,
      treeItemList: [],
      selected: [],
      treeOrientation: '0',
      collapsable: true,
      intervalId: null,

      menu: [],
      selection: null,
      currentTag: null,
      isFormValid: false,

      /*const toggleSelect = (node, isSelected) => {
        isSelected ? selected.value.push(node.some_id) : selected.value.splice(selected.value.indexOf(node.some_id), 1);
        if(node.children && node.children.length) {
          node.children.forEach(ch=>{
            toggleSelect(ch,isSelected)
          })
        }
      }*/
      lastProject: null,
      input: [],
      empty: {
        id: 0,
        label: "Wycena",
        children: [],
        expand: true,
        isPublic: true,
        isScreen: true,
        type: null
      },
      productDialog: [],
      categoryOwn: [],
      categoryCommercial: [],


      newFirstIdTree: null

    }
  },

  beforeUnmount() {
  },
  beforeRouteLeave() {
  },
  mounted() {
    this.panzoom();
  },
  async created() {


    this.assignCategory();
    //console.log(this.productsTree);
    await this.getListTree();
    //console.log(this.productsTree);
    await this.getMath();
    //console.log(this.productsTree);

  },
  computed: {
    ...mapGetters(['getProjectById', 'getCategoryById', 'getCategoriesOwn', 'getCategoriesCommercial', 'getProjectElementsById', 'getReadyProductById', 'getVariableById', 'getVariables']),
    ...mapState(['readyProducts', 'categories', 'projectsElements']),
    nameRules() {
      return [
        (v) => {
          if (!v) {
            return "Proszę wprowadzić nazwę";
          }

          if (v.length > 255) {
            return 'Maksymalna liczba znaków to 255.'
          }

          return true;
        }
      ];
    },
  },
  methods: {
    goToElement() {
      //TODO: zweryfikować czy działa clearInterval
      clearInterval(this.intervalId);
      document.cookie = "lastProject=" + this.$route.params.id + "; expires=Fri, 31 Dec 2500 23:59:59 GMT; path=/";
    },

    methodClearInterval() {
      clearInterval(this.intervalId);
    },
    assignCategory() {
      const _ = require('lodash');
      this.categoryOwn = _.cloneDeep(this.getCategoriesOwn);
      this.categoryCommercial = _.cloneDeep(this.getCategoriesCommercial);
    },
    mergeProps,
    panzoom() {
      // just grab a DOM element
      var element = document.querySelector('#scene')

      // And pass it to panzoom
      this.zoom = panzoom(element, {
        beforeMouseDown: function (e) {
          // allow mouse-down panning only if altKey is down. Otherwise - ignore
          let shouldIgnore = !e.altKey;
          return shouldIgnore;
        },
        filterKey: function (/* e, dx, dy, dz */) {

          ////////console.logdx);

          // don't let panzoom handle this event:
          return true;
        },
        setTransform: (_, {scale, x, y}) => {
          panzoom.setStyle('transform', `rotate(${x / 20}deg) scale(${scale}) translate(${x}px, ${y}px)`)
        }
      });

      this.zoom.zoomAbs(0, 0, 0.2);
    },
    checkRoles(roles) {
      let allRoles = this.$store.state.roles = useStorage().getStorageSync("roles");
      return roles.some(role => allRoles.includes(role));
    },
    cleanProjectCookie() {
      if (this.typeProject == "project") {
        document.cookie = "lastProject=0; expires=01 Jan 1970 00:00:00 UTC; path=/";
        this.lastProject = null;
      }
    },
    recursiveSearch(branch) {
      if (branch.id > this.highestID) {
        this.highestID = branch.id;
      }
      if (Array.isArray(branch.children)) {
        branch.children.forEach((child) => {
          this.recursiveSearch(child);
        });
      }
    },
    findHighestID() {
      this.highestID = 0;
      this.recursiveSearch(this.productsTree);
    },
    addColorToChildren(node, color) {
      if (node.children) {
        for (const child of node.children) {
          child.color = color;
          this.addColorToChildren(child, color);
        }
      }
    },
    addColorTextToChildren(node, color) {
      if (node.children) {
        for (const child of node.children) {
          child.colorText = color;
          this.addColorTextToChildren(child, color);
        }
      }
    },


    async getListTree() {
      const _ = require('lodash');
      this.typeProject = 'project';


      this.itemId = this.$route.params.id;
      let data = _.cloneDeep(await this.getProjectById(this.itemId));

      //console.log(data);

      if (data === undefined) {
        this.typeProject = 'element';
        data = _.cloneDeep(await this.getProjectElementsById(this.itemId));
      }
      //console.log(data);
      this.cleanProjectCookie();
      //console.log(data);
      if (data) {
        this.titleProject = data.title ?? '';
        this.productsTree = data.tree ? JSON.parse(data.tree) : this.empty;
        this.selected = data.selected;
        this.math = data.math ? JSON.parse(data.math) : [];
        this.chips = data.chips ? JSON.parse(data.chips) : [];
        this.chipsMath = data.chipsMath ? JSON.parse(data.chipsMath) : [];

        //console.log('Zaczytane drzwo',this.productsTree)

      }
      //console.log(this.productsTree);

      if (!data.tree) {

        this.productsTree =
            {
              id: 0,
              label: "Wycena",
              children: [
                {
                  id: 1,
                  label: data.title,
                  children: [],
                  expand: true,
                  isPublic: true,
                  isScreen: true,
                  type: 'build',
                }
              ],
              expand: true,
              isPublic: true,
              isScreen: true,
              type: null
            };
        this.selected = [];
        this.math = [];
        this.chips = [];
        this.chipsMath = [];
        this.saveProject(this.typeProject);

      }
      //console.log(this.productsTree);

    },
    addBranch(parentId, tree, color, colorText) {
      if (Number(tree.id) === 1) {
        tree.screen = true;
      }
      const isParent = Number(tree.id) === Number(parentId);
      if (isParent) {
        this.findHighestID();
        let some_id = this.highestID + 1;
        let leaf = {
          label: ``,
          isPublic: true,
          id: some_id,
          color: color,
          colorText: colorText,
          type: 'build',
          parentId: parentId,
          expand: true,
          isScreen: true,
          children: [],
          value: 0,
          categories: 1
        }
        tree.children.push(leaf);
      } else if (tree.children) {
        tree.children.forEach(ch => this.addBranch(parentId, ch, color, colorText))
      }
      this.saveProject(this.typeProject);
    },
    deleteBranch(node, tree) {

      ////console.log('deleteBranch',this.math);

      let math = this.math.find(p => p.idTree === node.id);
      if (math) {
        this.math.splice(this.math.indexOf(math), 1)
      }

      this.chips.splice(node.id, 1);
      this.chipsMath.splice(node.id, 1);

      let parent = tree.children ? tree.children.find(p => p.id === node.id) : null;
      if (parent) {
        tree.children.splice(tree.children.indexOf(node), 1)
      } else if (tree.children) {
        tree.children.forEach(ch => this.deleteBranch(node, ch))
      }

      this.saveProject(this.typeProject);

    },

    getProductsFromCategory(idCategory) {

      console.log('getProductsFromCategory', idCategory);

      if (!this.readyProductsFromCategory[idCategory]) {
        this.readyProductsFromCategory[idCategory] = [];
      }
      this.readyProducts.readyProducts.filter((product) => {
        if (idCategory === product.category.id) {
          let findProduct = this.readyProductsFromCategory[idCategory].find(p => Number(p.id) === Number(product.id));
          if (!findProduct) {
            this.readyProductsFromCategory[idCategory].push({
              'id': product.id,
              'title': product.title,
            });
          }
        }
      });
    },
    searchBranchById(tree, idBranch) {
      if (Number(tree.id) === Number(idBranch)) {
        return tree;
      } else if (tree.children != null) {
        var i;
        var result = null;
        for (i = 0; result == null && i < tree.children.length; i++) {
          result = this.searchBranchById(tree.children[i], idBranch);
        }
        return result;
      }
      return null;
    },
    addNodeToItemsOfTree(child, feature, children, parentId) {

      let idFeature = feature.id;
      let idTree = child.id;
      let name = feature.title;
      let parent = child.label;

      const existingItemIndex = this.treeItemList.findIndex(existingItem =>
          existingItem.idFeature === idFeature &&
          existingItem.idTree === idTree &&
          existingItem.name === name &&
          existingItem.parent === parent
      );

      if (existingItemIndex === -1) {
        // jeśli nie isnieje to dodaj
        const mathItem = this.math.find(item => item.feature === idFeature && item.idTree === idTree);

        if(mathItem !== undefined) {
          this.treeItemList.push({
            'children': children,
            'idFeature': idFeature,
            'idTree': idTree,
            'isScreen': true,
            'name': name,
            'parent': parent,
            'radioSelected': false,
            'parentId': parentId,
            'unit': mathItem.unit,
            'value': mathItem.value
          });
        }

      }
    },
    prepareAllNodesOfTree(tree, parentId, existingElements) {
      tree.children.forEach(child => {
        let children = [];
        if (child.children && child.children.length > 0) {
          child.children.forEach(ch => children.push(ch.id));
        }

        // const parent = this.searchProductById(Number(parentId));

        if (child.features) {
          child.features.forEach(feature => {
            this.addNodeToItemsOfTree(child, feature, children, parentId);
            existingElements.push({
              'idFeature': feature.id,
              'idTree': child.id
            });
          });
        }

        if (child.children && child.children.length > 0) {
          this.prepareAllNodesOfTree(child, parentId, existingElements);
        }
      });

    },
    updateAllNodesOfTree(value, idTree, feature){
      for (let i = 0; i < this.treeItemList.length; i++) {
        if (this.treeItemList[i].idTree === idTree && this.treeItemList[i].idFeature === feature) {
          this.treeItemList[i].value = value;
          break;
        }
      }
    },
    removeAllOldNodesOfTree(existingElements) {
      // Sprawdzamy, czy długość tablicy existingElements jest różna od długości tablicy treeItemList
      if (existingElements.length !== this.treeItemList.length) {
        // Przechodzimy przez tablicę docelową
        for (let i = this.treeItemList.length - 1; i >= 0; i--) {
          const node = this.treeItemList[i];
          // Sprawdzamy, czy element nie znajduje się w existingElements
          if (!existingElements.some(elem => elem.idFeature === node.idFeature && elem.idTree === node.idTree)) {
            // Jeśli element nie znajduje się w existingElements, usuwamy go z tablicy docelowej
            this.treeItemList.splice(i, 1);
          }
        }
      }

      // Sprawdzamy, czy długość tablicy existingElements jest różna od długości tablicy math
      if (existingElements.length !== this.math.length) {
        // Przechodzimy przez tablicę docelową
        for (let i = this.math.length - 1; i >= 0; i--) {
          const node = this.math[i];
          // Sprawdzamy, czy element nie znajduje się w existingElements
          if (!existingElements.some(elem => elem.feature === node.idFeature && elem.idTree === node.idTree)) {
            // Jeśli element nie znajduje się w existingElements, usuwamy go z tablicy docelowej
            this.math.splice(i, 1);
          }
        }
      }
      // feature
    },
    addEyeKeyToObjectArray(objArray) {
      for (let i = 0; i < objArray.length; i++) {
        objArray[i]["eye"] = true;
      }
      return objArray;
    },
    getCategoryFeatures(idCategory, branch) {
      branch.features = null;
      if (idCategory !== undefined && idCategory !== "") {
        const _ = require('lodash');
        let category = _.cloneDeep(this.getCategoryById(idCategory));

        if (category) {
          if (category.features !== undefined) {
            ////console.log('category.features', category, branch);
            branch.features = category.features;
          }
        }
      }
    },
    cleanMathExpression(expression) {
      // Usunięcie wszystkich znaków, które nie są cyframi, kropką lub znakami matematycznymi (+, -, *, /, (, ))
      const cleanedExpression = expression.replace(/[^0-9.+\-*/()]/g, '');

      // Usunięcie zbędnych znaków operatorów na początku oraz przy występowaniu obok siebie

      //////////console.logcleanedExpression);

      let fixedExpression = cleanedExpression.replaceAll(/^([+\-*/]+)|([+\-*/]+)$/g, '');
      fixedExpression = fixedExpression.replaceAll('++', '+');
      fixedExpression = fixedExpression.replaceAll('--', '-');
      fixedExpression = fixedExpression.replaceAll('**', '*');
      fixedExpression = fixedExpression.replaceAll('//', '/');

      fixedExpression = fixedExpression.replaceAll('+++', '+');
      fixedExpression = fixedExpression.replaceAll('---', '-');
      fixedExpression = fixedExpression.replaceAll('***', '*');
      fixedExpression = fixedExpression.replaceAll('///', '/');
      //////////console.logfixedExpression);
      return fixedExpression;
    },
    getCalculations() {

      // console.log(this.math);
      let existingElements = [];
      this.prepareAllNodesOfTree(this.productsTree, 0, existingElements); // root = 0
      this.removeAllOldNodesOfTree(existingElements);

      this.math.filter((itemStart, index) => {
            const _ = require('lodash');
            const item = _.cloneDeep(itemStart);

            if (item.type === 'build' && item.math !== undefined) {
              let mathElements;
              mathElements = rozdzielDzialanie(item.math);
              console.log(item.math, mathElements);
              let _math = '';
              mathElements.filter((mathElement) => {
                    console.log(mathElement)
                    if (mathElement) {
                      let pattern = /^[0-9]+([.,][0-9]+)?$/;

                      console.log(mathElement);

                      if (mathElement === "*" || mathElement === "/" || mathElement === "+" || mathElement === "-" || mathElement === "(" || mathElement === ")"
                          || pattern.test(mathElement)) {
                        _math += mathElement;
                      } else {

                        // Rozbijamy działanie matematyczne na części
                        // Wyciągamy identyfikatory
                        const mathId = mathElement.split('_');

                        let mathProduct = mathId[0];
                        let mathFeature = mathId[1];
                        let mathContent = mathId[2];

                        // pobierz dzieci od danego wierzchołka(item) z drzewa
                        let childrens = [];
                        let _selectChildren = null;
                        let searchData = this.searchBranchById(this.productsTree, item.idTree);
                        if (searchData.children && searchData.children.length > 0) {
                          searchData.children.forEach(ch => {
                            childrens.push(ch.id);

                            // TODO if + jeżeli radio jest zaznaczone u dziecka
                            if (searchData.oneChildren && ch.radioSelected) {
                              _selectChildren = ch.id;
                            }
                          });
                        }

                        ////console.log('if 5', _math);
                        let dataToFunction = {
                          product: mathProduct,
                          feature: mathFeature,
                          content: mathContent,
                          children: _selectChildren,  // TODO tu ma być podana wartość kiedy dany element ma wybrane Jeden wybór i jakieś dziecko ma zaznaczone radio
                          childrens: childrens,       // array id (int) dzieci od danego wierzchołwka
                          select: this.treeItemList,  // wszystkie wierzchołki drzewa w formacie z wskazanym rodzicem, dziećmi i cechą
                          originalMath: this.math
                        }

                        //console.log('dataToFunction',dataToFunction);


                        // Konkretna wartość cechy (checkbox)
                        if (mathProduct !== "??" && mathProduct !== "00" && mathProduct !== 'Z' &&
                            mathFeature !== "??" && mathFeature !== "00") {
                          _math += mathSpecificValueOfaFeature(dataToFunction)
                        }


                        // Konkretna wartość cechy wybranego produktu (radio)
                        if (mathProduct === "??" &&
                            mathFeature !== "??" && mathFeature !== "00") {
                          ////console.log('Konkretna wartość cechy wybranego produktu (radio)')
                          _math += mathSpecificValueOfaFeatureOfTheSelectedProduct(dataToFunction)
                        }


                        // Ogólne wartości gałęzi
                        if (mathProduct !== "??" && mathProduct !== "00" && mathProduct !== 'Z' &&
                            mathFeature === "??") {
                          ////console.log('Ogólne wartości gałęzi')
                          _math += mathBranchValues(dataToFunction)
                        }

                        //Suma wszystkich  dzieci - główne wartości
                        if (mathProduct === "00" &&
                            mathFeature === "00") {
                          ////console.log('Suma wszystkich  dzieci - główne wartości')
                          _math += mathSumOfAllChildren(dataToFunction)
                        }

                        //Suma wszystkich  dzieci - cechy
                        if (mathProduct === "00" &&
                            Number(mathFeature)) {
                          ////console.log('Suma wszystkich  dzieci - główne wartości')
                          _math += mathSumOfAllChildrenFeature(dataToFunction)
                        }

                        // W sumie pojawiła się zmienna
                        if (mathProduct === "Z" &&
                            Number(mathFeature)) {
                          _math += mathVariableValue(dataToFunction, this.getVariables)
                        }

                      }
                    }
                  }
              )


              if (_math === "") {
                _math = '---';
              }

              //console.log('_math', _math)
              try {
                let resultMath = eval(this.cleanMathExpression(_math));
                if (resultMath !== undefined) {
                  if (item.value !== resultMath || this.math[index].value !== resultMath) {
                    item.value = resultMath;
                    this.math[index].value = resultMath;
                    this.updateAllNodesOfTree(resultMath, item.idTree, item.feature);
                  }
                  this.updateTree(resultMath, item.idTree, 'value', item.feature);
                }
              } catch (error) {
                ////////////////////console.logerror);
                //this.error[item.idTree] = [];
                //this.error[item.idTree][item.feature] = true;
                //this.changeValue(item.idTree, '', item.feature)
              }

            }

          }
      )
      ;
    },
    async getMath() {
      //console.log(this.productsTree);
      this.getCalculations();
      //console.log(this.productsTree);
    },

    addChip(a, b, c, task = null) {

      if (c !== ' ' && c !== '') {
        let array = this.chips;

        if (!Array.isArray(array[a])) {
          array[a] = [];
        }
        if (!Array.isArray(array[a][b])) {
          array[a][b] = [];
        }

        array[a][b].push(c);
        this.chips = array;

        let arrayMath = this.chipsMath;

        if (!Array.isArray(arrayMath[a])) {
          arrayMath[a] = [];
        }
        if (!Array.isArray(arrayMath[a][b])) {
          arrayMath[a][b] = [];
        }
        if (task !== null) {
          arrayMath[a][b].push(task);
        } else {
          arrayMath[a][b].push(c);
        }
        this.chipsMath = arrayMath;

        let math = addMath.call(this, arrayMath, a, b);

        console.log('test', math, this.canBeMathCounting(math));


        if (this.canBeMathCounting(math) === true) {
          let row = this.math.find(e => Number(e.idTree) === Number(a) && Number(e.feature) === Number(b));
          if (row !== -1) {
            console.log('podmieniamy', math);
            row.math = math;
            row.value = c.toString().replace(',', '.');
          } else {
            console.log('dodajemy', math);
            this.math.push(
                {
                  idTree: Number(a),
                  feature: Number(b),
                  math: math,
                  type: 'build'
                }
            );
          }
          this.clear = '';
        }else{
          console.log('nic nie robimy', math);
        }

        console.log(this.math);

      }
      this.getMath();
    },
    canBeMathCounting(array) {
      if (typeof array == "string") {
        array = rozdzielDzialanie(array);
      }
      let mathMarks = ["+", "-", "*", "/"];
      for (let i = 1; i < array.length; ++i) {
        if (array[i] === "" || array[i] === " " || array[i] === undefined) {
          array.splice(i, 1);
        }
      }

      console.log(array);

      if (!mathMarks.includes(array[0]) && !mathMarks.includes(array[array.length - 1])) {
        console.log('wchodze')
        let isPreviusNumber = true;
        for (let i = 1; i < array.length; ++i) {

          if(array[i] !== "(" && array[i] !== ")"){
            if (isPreviusNumber == true) {
              if (!mathMarks.includes(array[i])) {
                this.$notify({type: 'error', title: "Błędny format działania matematycznego, proszę poprawić błąd1."});
                return false;
              } else {
                isPreviusNumber = false;
              }
            } else {
              if (!mathMarks.includes(array[i])) {
                isPreviusNumber = true;
              } else {
                this.$notify({type: 'error', title: "Błędny format działania matematycznego, proszę poprawić błąd3."});
                return false;
              }
            }
          }


        }
      } else {
        console.log('nie wchodze')
        return false;
      }
      return true;
    },
    async updateTree(event, id, type, feature = null) {
      //console.log(this.productsTree);
      console.log('updateTree', event, typeof event, id, type, feature);
      let product = null;
      let finded = null;
      const branch = this.searchBranchById(this.productsTree, id);
      if (branch) {
        const _ = require('lodash');
        if (feature === null) {
          switch (type) {
            case 'isPublic':
              branch.isPublic = event;
              break;
            case 'isScreen':
              branch.isScreen = event;
              break;
            case 'oneChildren':
              if (event) {
                this.oneChildren.push(id);
              } else {
                const index = this.oneChildren.indexOf(id);
                if (index !== -1) {
                  this.oneChildren.splice(index, 1);
                }
              }
              branch.oneChildren = event;
              break;
            case 'isRequired':
              branch.isRequired = event;
              break;
            case 'type':
              branch.type = event;
              branch.categories = null
              break;
            case 'color':
              branch.color = event;
              this.addColorToChildren(branch, event);
              break;
            case 'colorText':
              branch.colorText = event;
              this.addColorTextToChildren(branch, event);
              break;
            case 'label':
              branch.label = event.target.value;
              break;
            case 'categories':
              branch.categories = _.cloneDeep(event);
              this.getCategoryFeatures(branch.categories, branch);
              break;
            case 'product':
              branch.product = event;
              product = this.getReadyProductById(event);

              branch.images = product.images;

              branch.label = product.title;

              finded = this.math.find((m) => Number(m.idTree) === Number(branch.id));
              if (finded) {
                finded.priceReady = product.price;
                finded.priceUnit = product.priceUnit;
              } else {
                this.math.push({
                  idTree: branch.id,
                  features: '??',
                  priceReady: product.price,
                  priceUnit: product.priceUnit,
                })
              }


              break;
          }
        } else {


          let feature_;
          let newMaths;
          switch (type) {

            case 'math':
              newMaths = this.math.find((m) => Number(m.idTree) === Number(id) && Number(m.feature) === Number(feature));
              if (newMaths) {
                newMaths.math = event;
              }
              this.getMath();
              break;

            case 'feature':
              this.addChip(id, feature, event)
              break;
            case 'value':

              feature_ = _.cloneDeep(branch.features);

              feature_.find((f) => {
                if (Number(f.id) === Number(feature)) {
                  ////console.log("Number(f.id)",Number(f.id));
                  ////console.log("Number(feature)",Number(feature));
                  ////console.log("feature_",feature_);
                  ////console.log("event",event);
                  f.value = event;
                }
              });

              branch.features = feature_;
              break;
          }
        }
      }
      //////console.log("feature",feature);

      //console.log(this.productsTree);

      return null;
    },
    async saveProject(type, id = null, interval = false) {

      //console.log(this.productsTree);

      if (this.isFormValid === true) {
        if (type === 'element') {
          this.typeProject = type;
        }

        this.loadingSave = true;
        const currentDate = new Date();
        this.loadingSaveTime = currentDate.toLocaleTimeString();

        let idProject = null;
        idProject = this.$route.params.id;


        if (interval === true && idProject === null) {
          idProject = this.$route.params.id;
        }

        if (interval === true && idProject === null) {
          ////////console.log'ech')
        } else {

          if (this.typeProject === null) {
            type = 'project'
          } else {
            type = this.typeProject;
          }

          let tree = {};

          let title;
          tree = this.productsTree
          title = this.titleProject;
          if (title === '') {
            title = "(brak nazwy)";
            this.titleProject = title;
          }

          if (this.titleProjectTree[id] !== undefined) {
            tree =
                {
                  id: 0,
                  label: "Wycena",
                  children: [this.searchBranchById(this.productsTree, id)],
                  expand: true,
                  isPublic: true,
                  type: null
                };
          }

          const dataToUpdate = {
            title: this.titleProject,
            math: JSON.stringify(this.math),
            tree: JSON.stringify(tree),
            selected: JSON.stringify(this.selected),
            chips: JSON.stringify(this.chips),
            chipsMath: JSON.stringify(this.chipsMath),
            type: type,
          }

          //console.log(tree);


          if (type === 'project') {
            if (idProject) {
              await this.$store.dispatch('updateProject', {item: dataToUpdate, idProject: idProject});
            } else {
              await this.$store.dispatch('addProject', dataToUpdate);
              if (!idProject) {
                this.$route.params.id = this.$store.state.projects.currentIdProject;
              }
            }
          } else {
            if (!idProject) {
              this.$route.params.id = this.$store.state.projects.currentIdProject;
            }

            if (idProject) {
              await this.$store.dispatch('updateProjectElement', {item: dataToUpdate, idProject: idProject});
            } else {
              await this.$store.dispatch('addProjectElement', dataToUpdate);
            }
          }


          if (this.dialogTree[id] === true) {
            this.$notify({
              type: this.$store.state.users.responseStatus,
              title: this.$store.state.users.responseMessage
            });
          }
          this.dialogTree[id] = false;
          this.dialog = false;
          this.loadingSave = false;
        }
      }

    },
    changeDuplicates(node, idsToCheck) {
      if (idsToCheck.includes(node.id)) {
        let old = node.id;
        node.id = generateUniqueId(idsToCheck);
        this.duplicated[old] = node.id;
      }
      if (node.children && node.children.length > 0) {
        for (const childNode of node.children) {
          this.changeDuplicates(childNode, idsToCheck);
        }
      }
    },
    getAllIds(node) {
      // Rekurencyjna funkcja do pobrania wszystkich identyfikatorów z drzewa
      let ids = [];
      if (node.id) {
        ids.push(node.id);
      }
      if (node.children && node.children.length > 0) {
        for (const childNode of node.children) {
          ids = ids.concat(this.getAllIds(childNode));
        }
      }
      return ids;
    },
    findAndChangeDuplicates() {
      if (this.loadTreeData) {
        const tree1Ids = this.getAllIds(this.productsTree); // Pobranie wszystkich identyfikatorów z drzewa pierwszego
        this.changeTree = JSON.parse(this.loadTreeData.tree);
        this.changeDuplicates(this.changeTree, tree1Ids); // Zmiana duplikatów w drzewie drugim
        this.$notify({type: 'success', title: "Element został zaaktualizowany"});
      } else {
        this.$notify({type: 'error', title: "Nie można zaaktualizować. Brak elementu źródłowego"});
      }

    },
    async loadTree(event) {

      const _ = require('lodash');
      this.loadTreeData = _.cloneDeep(await this.getProjectElementsById(event));

      if (this.loadTreeData.tree !== undefined) {
        await this.findAndChangeDuplicates();
        return true;
      } else {
        this.$notify({
          title: "Podpinana gałąź jest pusta",
          type: 'error',
        });
        return false;
      }

    },
    replacePrefixWith(obj, targetPrefix, replacementPrefix) {
      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          if (typeof obj[i] === 'string' && obj[i].startsWith(targetPrefix)) {
            obj[i] = replacementPrefix + obj[i].substring(targetPrefix.length);
          } else if (typeof obj[i] === 'object' || Array.isArray(obj[i])) {
            this.replacePrefixWith(obj[i], targetPrefix, replacementPrefix); // Rekurencyjnie przeszukuj podtablice i obiekty
          }
        }
      } else if (typeof obj === 'object') {
        for (const key in obj) {
          if (typeof obj[key] === 'string' && obj[key].startsWith(targetPrefix)) {
            obj[key] = replacementPrefix + obj[key].substring(targetPrefix.length);
          } else if (typeof obj[key] === 'object' || Array.isArray(obj[key])) {
            this.replacePrefixWith(obj[key], targetPrefix, replacementPrefix); // Rekurencyjnie przeszukuj obiekty
          }
        }
      }
    },

    replaceMathPrefixWith(data, targetPrefix, replacementPrefix) {
      //console.log('targetPrefix',targetPrefix);
      //const regex = new RegExp(`(^|\\D)${targetPrefix}(\\d+)(_\\d+_W|\\D|$)`, 'g');
      const regex = new RegExp(`(^${targetPrefix}|\\b${targetPrefix})([0-9_?]+)([C_W])`, 'g');

      data.forEach(item => {
        if (item && item.math) {

          item.math = item.math.replace(regex, (match, prefix, number, suffix) => {

            //console.log( `${replacementPrefix}${number}${suffix}`);
            return `${replacementPrefix}${number}${suffix}`;
          });
        }
        if (item && item.children) {
          this.replaceMathPrefixWith(item.children, targetPrefix, replacementPrefix); // Rekurencyjnie przeszukuj dzieci
        }
      });
    },

    replaceMathInTreePrefixWith(tree, targetExpression, replacementExpression) {
      if (Array.isArray(tree)) {
        // Jeśli to jest tablica obiektów, przeszukaj każdy element
        tree.forEach(item => {
          this.replaceMathInTreePrefixWith(item, targetExpression, replacementExpression);
        });
      } else if (typeof tree === 'object') {
        // Jeśli to jest obiekt, sprawdź, czy ma pole "features"
        if (tree.features && Array.isArray(tree.features)) {
          tree.features.forEach(feature => {
            if (feature.math && typeof feature.math === 'string') {
              // Zastąp wyrażenia w polu "math"
              feature.math = feature.math.replace(new RegExp(`(^|\\s)${targetExpression}`, 'g'), `$1${replacementExpression}`);
            }
          });
        }

        // Przeszukaj dzieci
        if (tree.children && Array.isArray(tree.children)) {
          this.replaceMathInTreePrefixWith(tree.children, targetExpression, replacementExpression);
        }
      }
    },
    getAllChildrenIds(node, ids = []) {
      if (node) {
        if (node.children && node.children.length > 0) {
          node.children.forEach(child => {
            ids.push(child.id);
            this.getAllChildrenIds(child, ids);
          });
        }
        return ids;
      }
    },


    zamienIDNaWartosci(obj, arr) {
      function zastapID(obiekt) {
        if (typeof obiekt !== 'object' || obiekt === null) {
          return;
        }
        if (Array.isArray(obiekt)) {
          obiekt.forEach((elem) => {
            if (typeof elem === 'object' && elem !== null) {
              zastapID(elem);
            }
          });
        } else {
          for (const prop in obiekt) {
            if (prop === 'id' && typeof obiekt[prop] === 'number' && arr[obiekt[prop]] !== undefined && arr[obiekt[prop]] !== null) {
              obiekt[prop] = arr[obiekt[prop]];
            } else if (typeof obiekt[prop] === 'object' && obiekt[prop] !== null) {
              zastapID(obiekt[prop]);
            }
          }
        }
      }

      const kopiaObiektu = JSON.parse(JSON.stringify(obj));
      zastapID(kopiaObiektu);
      return kopiaObiektu;
    },
    znajdzZmianyId(objekt1, objekt2) {
      let zmianyId = [];

      // Sprawdź Id pierwszego poziomu
      if (objekt1.id !== objekt2.id) {
        zmianyId.push([objekt1.id, objekt2.id]);
      }

      // Sprawdź dzieci pierwszego poziomu
      const children1 = objekt1.children || [];
      const children2 = objekt2.children || [];

      for (let i = 0; i < children1.length && i < children2.length; i++) {
        if (children1[i].id !== children2[i].id) {
          zmianyId.push([children1[i].id, children2[i].id]);
        }
      }

      return zmianyId;
    }
    ,
    changeBranch(root, id, newBranch, currentElement) {
      if (root.id === id) {
        newBranch.element = currentElement;
        return newBranch;
      }

      if (root.children && root.children.length > 0) {
        root.children = root.children.map(child => this.changeBranch(child, id, newBranch, currentElement));
      }

      return root;
    },

    changeValue(element, staraWartosc, nowaWartosc) {
      const nowyElement = {...element};
      nowyElement.math = nowyElement.math.replace(new RegExp(staraWartosc, 'g'), nowaWartosc);
      nowyElement.idTree = parseInt(nowyElement.math.split('_')[0]); // Aktualizacja idTree
      return nowyElement;
    },
    loadTreeDown(id, idBranch = null) {

      // id - id rodzica

      // Wyszukaj w tree
      const _ = require('lodash');
      const oldTree = _.cloneDeep(this.productsTree);

      if (this.loadTreeData && this.loadTreeData.tree !== undefined) {

        //console.log('Dane wejściowe', id, idBranch);
        let newTreeFromBranches = this.searchBranchById(JSON.parse(this.loadTreeData.tree), 1);
        newTreeFromBranches.parentId = id;
        newTreeFromBranches.radioSelected = false;
        console.log('Nowe drzewo', newTreeFromBranches)
        newTreeFromBranches.expand = false;
        //console.log('Nowe klucze', this.duplicated);
        const changedTree = this.zamienIDNaWartosci(newTreeFromBranches, this.duplicated);
        console.log('Nowe drzewo po zmianach', changedTree);

        if (idBranch !== null) {
          let oldTreeFromBranches = this.searchBranchById(oldTree, idBranch);
          const currentElement = oldTreeFromBranches.element;
          console.log('Stare drzewo od gałęzi', oldTreeFromBranches)
          const changedID = this.znajdzZmianyId(oldTreeFromBranches, changedTree);

          console.log('znajdzZmianyId', changedID);

          //console.log('Zmiany ID',this.znajdzZmianyId(oldTreeFromBranches,changedTree) );
          changedID.filter((data) => {
            const oldKey = data[0];
            const newKey = data[1];

            //Kasujemy stare wartości
            let findOldMath = this.math.findIndex((m) => Number(m.idTree) === Number(oldKey));
            if (findOldMath !== -1) {
              this.math.splice(findOldMath, 1);
            }

            console.log(oldKey, newKey);


            this.chipsMath.splice(oldKey, 1);
            this.chips.splice(oldKey, 1);


            const replacementOldKey = oldKey + "_";
            const replacementNewKey = newKey + "_";


            // Podpinamy wartości
            const chipsNew = JSON.parse(this.loadTreeData.chips);
            const chipsMathNew = JSON.parse(this.loadTreeData.chipsMath);
            const mathNew = JSON.parse(this.loadTreeData.math);

            //console.log('Dane do wrzucenia ', chipsNew, chipsMathNew, mathNew);
            //console.log('Podmieniamy id w tych elementach')

            this.duplicated.forEach((newKey, index) => {
              chipsNew[newKey] = chipsNew[index];
              delete chipsNew[index];

              chipsMathNew[newKey] = chipsMathNew[index];
              delete chipsMathNew[index];

              mathNew.find((m) => {
                if (Number(m.idTree) === Number(index)) {
                  m.idTree = newKey;
                }
              })
            })
            //console.log('Dane do wrzucenia po zmianie ID', chipsNew, chipsMathNew, mathNew);
            this.chips = Object.assign(this.chips, chipsNew);
            this.chipsMath = Object.assign(this.chipsMath, chipsMathNew);
            this.math.push(...mathNew);


            //console.log(this.chipsMath);


            // Przeszukjemy ChipsMath
            this.replacePrefixWith(this.chipsMath, replacementOldKey, replacementNewKey);


            //console.log('Szukomy');

            // Przeszukujemy Math
            this.replaceMathPrefixWith(this.math, replacementOldKey, replacementNewKey);

            // Przeszukjemy Tree
            this.replaceMathInTreePrefixWith(this.productsTree, replacementOldKey, replacementNewKey);
          })
          // Podmieniamy wczytany element
          this.changeBranch(this.productsTree, idBranch, changedTree, currentElement)
        } else {
          let currentTree = this.searchBranchById(this.productsTree, id);
          //console.log('Obecne drzewo',currentTree);

          changedTree.element = this.loadTreeData.id;

          if (currentTree.children === undefined) {
            //console.log('Nie posiada dzieci');
            currentTree.children = [];
          }
          //console.log('Podpinamy dzieci');
          currentTree.children.push(changedTree);


          // Podpinamy wartości
          const chipsNew = JSON.parse(this.loadTreeData.chips);
          const chipsMathNew = JSON.parse(this.loadTreeData.chipsMath);
          const mathNew = JSON.parse(this.loadTreeData.math);

          //console.log('Dane do wrzucenia ', chipsNew, chipsMathNew, mathNew);
          //console.log('Podmieniamy id w tych elementach')


          console.log(this.duplicated);

          this.duplicated.forEach((newKey, index) => {
            chipsNew[newKey] = chipsNew[index];
            delete chipsNew[index];

            chipsMathNew[newKey] = chipsMathNew[index];
            delete chipsMathNew[index];

            mathNew.find((m) => {
              if (Number(m.idTree) === Number(index)) {
                m.idTree = newKey;
              }
            })
          })
          //console.log('Dane do wrzucenia po zmianie ID', chipsNew, chipsMathNew, mathNew);
          this.chips = Object.assign(this.chips, chipsNew);
          this.chipsMath = Object.assign(this.chipsMath, chipsMathNew);
          this.math.push(...mathNew);


        }


        console.log(this.filterNonNullValuesWithKeys(this.chipsMath));


        this.dialogTreeUpload[id] = false;
        this.menuD[id] = false;

        this.saveProject(this.typeProject);

      } else {
        this.$notify({
          title: "Podpinana gałąź jest pusta",
          type: 'error',
        });
      }
    },


    filterNonNullValuesWithKeys(arr) {
      if (!Array.isArray(arr)) {
        return arr;
      }

      return arr.reduce((acc, val, index) => {
        if (val === null) {
          return acc;
        } else if (Array.isArray(val)) {
          const filtered = this.filterNonNullValuesWithKeys(val);
          if (filtered.length > 0) {
            acc.push({index, value: filtered});
          }
        } else {
          acc.push({index, value: val});
        }
        return acc;
      }, []);
    },

    async updateElement(treeId, branchId) {
      this.changeBranchId = Number(treeId);
      let statusLoadTree = await this.loadTree(branchId);
      if (statusLoadTree === true) {
        await this.loadTreeDown(this.checkParent(treeId), treeId);
      }
    },
    checkParent(data) {
      let product = this.getParentId(this.productsTree.children, data, -1);
      return product;
    },
    getParentId(array, id, parentId = null) {
      // For every entry in the array
      for (const entry of array) {
        // If the ID matches, return the current parent ID
        if (entry.id === id) {
          return parentId;
        }
        let deeperParentId = null;
        // Otherwise, call the same function on its children, passing itself as the parent.
        // If there was a match, return it.
        if (entry.children && (deeperParentId = this.getParentId(entry.children, id, entry.id))) {
          return deeperParentId;
        }
      }
      // No match was found
      return null;
    },

    // Wgrywanie plików
    uploadFile() {

      // event.preventDefault();

      const idTree = this.$refs.idTree.value;
      const file = this.$refs.fileInput.files[0];
      const title = this.$refs.productTitle.value;
      const productId = this.$refs.productId.value;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('productId', productId);
      formData.append('title', title);

      this.dialog = false;
      this.menuD[idTree] = false;

      // Wykonaj żądanie API przy użyciu preferowanej metody (np. Axios)
      axios.post(process.env.VUE_APP_API_LOCATION + '/api/c/products/importFile/', formData)
          .then(response => {
            ////console.logresponse.data);
            if (response.data.error) {
              this.$notify({
                title: response.data.error.text,
                type: 'error',
              });
            } else {
              // Obsłuż odpowiedź API
              let elementTree = this.searchProductById(this.productsTree, idTree);

              //////console.log'photo', elementTree);

              elementTree.productB = response.data.product_id;
              elementTree.image = response.data.images[0];
              elementTree.images = response.data.images;
              elementTree.files = response.data.files;
              this.$notify({
                title: "Plik został wgrany",
                type: 'success',
              });

              this.saveProject(this.typeProject);
            }
          })
          .catch(error => {
            // Obsłuż ewentualne błędy
            console.error(error);
            this.$notify({
              title: "Wystąpił problem podczas wgrywania produktów",
              type: 'error',
            });
          });


    },
    deleteFile() {

      //////console.log"productId", this.$refs.productId.value);
      const productId = this.$refs.productId.value;
      const idTree = this.$refs.idTree.value;
      const formData = new FormData();
      formData.append('productId', productId);
      formData.append('idTree', idTree);

      ////console.log"formData", formData);
      this.dialog = false;

      // Wykonaj żądanie API przy użyciu preferowanej metody (np. Axios)
      axios.post(process.env.VUE_APP_API_LOCATION + '/api/c/products/removeImage/', formData)
          .then(response => {
            ////console.logresponse.data);
            if (response.data.error) {
              this.$notify({
                title: response.data.error.text,
                type: 'error',
              });
            } else {
              // Obsłuż odpowiedź API
              let elementTree = this.searchProductById(this.productsTree, idTree);

              elementTree.image = null;
              elementTree.images = null;

              this.$notify({
                title: "Zdjęcie zostało usunięte",
                type: 'success',
              });

              this.dialogTreeDeleteFile[idTree] = false;
            }
          })
          .catch(() => {
            // Obsłuż ewentualne błędy
            //console.error(error);
            this.$notify({
              title: "Wystąpił problem podczas aktualizacji produktów",
              type: 'error',
            });
          });
      this.saveProject(this.typeProject);
    },
    checkIfParentHasOneChildren(data) {
      if (data && data.parentId === undefined) {
        return false;
      }

      const parentProduct = this.searchBranchById(this.productsTree, data.parentId);
      return parentProduct && parentProduct.oneChildren;
    },
    handleRadioCheckParentCalculator(element){
      if (element && element.parentId === undefined) {
        return false;
      }
      const parentProduct = this.searchBranchById(this.productsTree, element.parentId);
      parentProduct.children.forEach((child) => {
        child.radioSelected = false;
      });

      element.radioSelected = true;
    },
    searchProductById(element, element_id) {
      if (Number(element.id) === Number(element_id)) {
        return element;
      } else if (element.children != null) {
        var i;
        var result = null;
        for (i = 0; result == null && i < element.children.length; i++) {
          result = this.searchProductById(element.children[i], element_id);
        }
        return result;
      }
      return null;
    },

    async updateProducts(all = false) {

      let url = null;
      if (all === true) {
        url = process.env.VUE_APP_API_LOCATION + '/api/projects/updateAllProducts';
      } else {
        if (this.$route.params.id !== "") {
          url = process.env.VUE_APP_API_LOCATION + '/api/projects/updateProducts/' + this.$route.params.id;
        }
      }

      if (url != null) {
        await axios.get(url, {
          headers: {
            Authorization: `Bearer ${this.getToken}`
          }
        }).then(response => {
          let products = response.data;
          products.filter(p => {

            // update price in math
            let row = this.math.findIndex(e => e.product === p.id);
            if (row !== -1) {
              this.math[row]['priceReady'] = Number(p.priceReady);
              this.math[row]['priceUnit'] = Number(p.priceUnit);
            }

            // update tree
            const product = this.searchProduct(this.productsTree, p.id);
            if (product) {

              ////////////////console.logproduct, p)

              product.price = Number(p.priceReady);
              product.priceUnit = Number(p.priceUnit);
              product.features = p.features;
              product.priceLabelUnit = p.priceLabelUnit;
              product.priceReadyLabelUnit = p.priceReadyLabelUnit;
            }

            // update features
            if (p.features) {
              Object.entries(p.features).forEach(([key, value]) => {

                let row = this.math.findIndex(e => e.product === p.id && e.feature === Number(key));
                if (row !== -1) {
                  this.math[row]['value'] = value;
                }
              });
            }
          });

          this.$notify({
            title: "Produkty zostały zaktualizowane",
            type: 'success',
          });
        });
      }

    },

  }
}


</script>

<style src="./style/style.css" lang="css">
</style>
