<template>
  Przykro nam, ale nie posiadasz uprawnień do przeglądania tej strony
</template>

<script>
export default {
  name: "DenyAccessPage"
}
</script>

<style scoped>

</style>
