import axios from "axios";
export const variables = {
    state: {
        variables: [],
        responseStatus: '',
        responseMessage: ''
    },
    getters: {
        getVariables: state => {
            return state.variables;
        },
        getVariableById: (state) => (id) => {
            return state.variables.find(item => item.id === Number(id));
        },
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        },
        setResponseStatus(state, status) {
            state.responseStatus = status;
        },
        setVariables(state, items) {
            state.variables = items;
        },
        addVariable(state, items) {
            state.variables.push(items);
        },
        updateVariable(state, data) {
            const index = state.variables.findIndex(item => Number(item.id) === Number(data.idVariable));
            state.variables.splice(index, 1, {id: data.idVariable , title: data.item.title, value: data.item.value});
        },
        removeVariable(state, itemId) {
            state.variables = state.variables.filter(item => item.id !== itemId);
        },
    },
    actions: {
        async loadVariables({commit, rootState }) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_LOCATION + '/api/variables',
                    {headers: {Authorization: `Bearer ${rootState .token}`}});
                const items = response.data;
                commit('setVariables', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async addVariable({commit, rootState }, data) {
            try {
                await axios.post(process.env.VUE_APP_API_LOCATION + '/api/variables',
                    data,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('addVariable', items);
                        commit('setResponseMessage', 'Jednostka została dodana');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateVariable({commit, rootState }, data) {
           //////console.log'updateVariable', data)
            try {
                await axios.put(process.env.VUE_APP_API_LOCATION + '/api/variables/' + data.idVariable,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(() => {
                        commit('updateVariable', data);
                        commit('setResponseMessage', 'Jednostka została zaktualizowana');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async removeVariable({commit, rootState }, itemId) {
            try {
                await axios.delete(process.env.VUE_APP_API_LOCATION + '/api/variables/' + itemId,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(() => {
                        commit('removeVariable', itemId);
                        commit('setResponseMessage', 'Jednostka została usunięta');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
    }
}
