<template>
  <v-btn class="mx-2" append-icon="mdi-trash-can" size="x-small" fab dark color="green"
         @click="dialogT[$props.ticketId] = true">
    Zobacz
  </v-btn>
  <v-dialog
      v-model="dialogT[$props.ticketId]"
      height="600"
      persistent
  >
    <v-card>
      <v-card-title>
        ID: {{ $props.ticketId }}
        <v-btn icon @click="dialogT[$props.ticketId] = false" class="float-end"
               variant="plain"
               density="compact">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-item>
        <v-tabs
            v-model="tab"
            color="deep-purple-accent-4"
            align-tabs="center"
        >
          <v-tab value="1">Dane klienta</v-tab>
          <v-tab value="2">Lista produktów</v-tab>
        </v-tabs>
        <v-window v-model="tab">
          <v-window-item value="1">
            <v-form v-model="isFormValid">

              <v-row>
                <v-col>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                          :disabled="!isEditing"
                          :items="customers.customers"
                          :custom-filter="customFilter"
                          item-title="title"
                          item-value="id"
                          variant="outlined"
                          density="compact"
                          label="Wyszukaj klienta"
                          v-model="existCustomer"
                          class="mt-2"
                      ></v-autocomplete>
                      <v-divider></v-divider>
                    </v-col>

                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                          density="compact"
                          label="Imię"
                          v-model="name"
                          hide-details="auto"
                          :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                          :rules="onlyLettersRules"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                          density="compact"
                          label="Nazwisko"
                          v-model="lastName"
                          hide-details="auto"
                          :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                          :rules="onlyLettersRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                          density="compact"
                          label="Ulica"
                          v-model="street"
                          hide-details="auto"
                          :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                          density="compact"
                          label="Nr domu"
                          v-model="homeNumber"
                          hide-details="auto"
                          :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                          density="compact"
                          label="Kod"
                          v-model="postcode"
                          hide-details="auto"
                          :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                          density="compact"
                          label="Miasto"
                          v-model="city"
                          hide-details="auto"
                          :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                          density="compact"
                          label="Telefon"
                          v-model="phone"
                          hide-details="auto"
                          :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                          :rules="phoneRules"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                          density="compact"
                          label="Email"
                          v-model="emailCustomer"
                          hide-details="auto"
                          :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                          :rules="emailRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!--                      <v-row>


                                        </v-row>-->
                </v-col>
                <v-col v-if="ticket !== null">
                  <v-row>
                    <v-col>
                      <v-text-field v-model="title"
                                    density="compact"
                                    label="Tytuł"
                                    class="mt-2"
                                    hide-details="auto"
                                    :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                                    :rules="titleNameRules"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <strong>Data wprowadzenia: </strong>
                      <span v-if="ticket.createAt!=null">{{ formatDate(ticket.createAt) }}</span>
                      <span v-else>Brak danych</span>
                    </v-col>
                    <v-col>
                      <strong>Data modyfikacji: </strong>
                      <span v-if="ticket.modifiedAt!=null">{{ formatDate(ticket.modifiedAt) }}</span>
                      <span v-else>Brak danych</span>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="auto"><strong>Data ważności: </strong></v-col>
                    <v-col>
                      <VueDatePicker
                          v-model="expirationDate"
                          auto-apply locale="pl"
                          month-name-format="long"
                          :enable-time-picker="false"
                          model-type="yyyy-MM-dd"
                          :escClose="true"
                          :teleport="true"

                          :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                          @update:model-value="this.saveExpirationDate()"

                      ></VueDatePicker>
                    </v-col>
                    <v-col cols="auto"><strong>Status: </strong></v-col>
                    <v-col>
                      <v-select v-model="status" item-title="label" density="compact" hide-details
                                :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                                item-value="key" :items="[
                        {key: 'new', label : 'Nowy'},
                        {key:'progress' ,label: 'Wycena'},
                        {key:'atCustomer' ,label: 'U klienta'},
                        {key:'annulled' ,label: 'Anulowany'},
                        ]"></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mt-3">
                    <v-col>
                      <v-btn prepend-icon="mdi-file-tree-outline" width="100%"
                             color="blue" size="x-large" stacked variant="elevated"
                             :to="'/zlecenie/' + ticket.id"
                      >
                        Wycena
                      </v-btn>
                    </v-col>
                    <v-col>
                      <v-btn prepend-icon="mdi-file-pdf-box"
                             target="_blank" width="100%" color="red" size="x-large" stacked variant="elevated"
                             :href="'https://' + host + '/api/zlecenie-pdf/'+ ticket.id"
                      >
                        Oferta
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ActionsButton @annulled="dialogT[$props.ticketId] = false" @save="saveCustomerData()"
                                 :disabledSave="!isFormValid"></ActionsButton>
                </v-col>
              </v-row>

              <!--
                                  <v-card-item>


                                    </v-row>

                                  </v-card-item>
                                </v-card>

                                <ActionsButton @annulled="dialogT[$props.ticketId] = false" @save="saveCustomerData()"
                                               :disabledSave="!isFormValid"></ActionsButton>

                              </v-col>

                            </v-row>-->
            </v-form>
          </v-window-item>
          <v-window-item value="2">
            <TableProducts
                :projectName="projectName"
                :itemsWithIsScreenTrue="itemsWithIsScreenTrue"
                :getValueFromScreen="getValueFromScreen"
                :selectedProducts="selectedProducts"
            >
            </TableProducts>
          </v-window-item>
        </v-window>

        <!--
                <v-window v-model="tab">
                  <v-window-item value="1">
                    <v-form v-model="isFormValid">
                      <v-row>
                        <v-col>
                          <v-card>
                            <v-card-item>
                              <v-autocomplete
                                  :disabled="!isEditing"
                                  :items="customers.customers"
                                  :custom-filter="customFilter"
                                  item-title="title"
                                  item-value="id"
                                  variant="outlined"
                                  density="compact"
                                  label="Wyszukaj klienta"
                                  v-model="existCustomer"
                              ></v-autocomplete>
                            </v-card-item>
                            <v-card-item>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                      density="compact"
                                      label="Imię"
                                      v-model="name"
                                      hide-details="auto"
                                      :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                                      :rules="onlyLettersRules"
                                  ></v-text-field>
                                </v-col>
                                <v-col>
                                  <v-text-field
                                      density="compact"
                                      label="Nazwisko"
                                      v-model="lastName"
                                      hide-details="auto"
                                      :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                                      :rules="onlyLettersRules"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                      density="compact"
                                      label="Ulica"
                                      v-model="street"
                                      hide-details="auto"
                                      :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                                  ></v-text-field>
                                </v-col>
                                <v-col>
                                  <v-text-field
                                      density="compact"
                                      label="Nr domu"
                                      v-model="homeNumber"
                                      hide-details="auto"
                                      :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                      density="compact"
                                      label="Kod"
                                      v-model="postcode"
                                      hide-details="auto"
                                      :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                                  ></v-text-field>
                                </v-col>
                                <v-col>
                                  <v-text-field
                                      density="compact"
                                      label="Miasto"
                                      v-model="city"
                                      hide-details="auto"
                                      :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                      density="compact"
                                      label="Telefon"
                                      v-model="phone"
                                      hide-details="auto"
                                      :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                                      :rules="phoneRules"
                                  ></v-text-field>
                                </v-col>
                                <v-col>
                                  <v-text-field
                                      density="compact"
                                      label="Email"
                                      v-model="emailCustomer"
                                      hide-details="auto"
                                      :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                                      :rules="emailRules"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-card-item>
                          </v-card>

                          <ActionsButton @annulled="dialogT[$props.ticketId] = false" @save="saveCustomerData()"
                                         :disabledSave="!isFormValid"></ActionsButton>

                        </v-col>
                        <v-col v-if="ticket !== null">
                          <v-card>
                            <v-card-item>
                              <v-text-field v-model="title"
                                            density="compact"
                                            label="Tytuł"
                                            style="margin-top:6px;"
                                            hide-details="auto"
                                            :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                                            :rules="titleNameRules"
                              >
                              </v-text-field>
                            </v-card-item>
                            <v-card-item>
                              <strong>Data wprowadzenia: </strong>
                              <span v-if="ticket.createAt!=null">{{ formatDate(ticket.createAt) }}</span>
                              <span v-else>Brak danych</span>
                            </v-card-item>
                            <v-card-item>
                              <strong>Data modyfikacji: </strong>
                              <span v-if="ticket.modifiedAt!=null">{{ formatDate(ticket.modifiedAt) }}</span>
                              <span v-else>Brak danych</span>
                            </v-card-item>
                            <v-card-item>
                              <v-row>
                                <v-col cols="auto"><strong>Data ważności: </strong></v-col>
                                <v-col>
                                  <VueDatePicker
                                      v-model="expirationDate"
                                      auto-apply locale="pl"
                                      month-name-format="long"
                                      :enable-time-picker="false"
                                      model-type="yyyy-MM-dd"
                                      :escClose="true"
                                      :teleport="true"
                                      :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                                      @update:model-value="this.saveExpirationDate()"
                                  ></VueDatePicker>
                                </v-col>
                                <v-col cols="auto"><strong>Status: </strong></v-col>
                                <v-col>
                                  <v-select v-model="status" item-title="label" density="compact" hide-details
                                            :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                                            item-value="key" :items="[
                                {key: 'new', label : 'Nowy'},
                                {key:'progress' ,label: 'Wycena'},
                                {key:'atCustomer' ,label: 'U klienta'},
                                {key:'annulled' ,label: 'Anulowany'},
                                ]"></v-select>
                                </v-col>
                              </v-row>
                            </v-card-item>
                          </v-card>
                          <v-row class="mt-3">
                            <v-col>
                              <v-btn prepend-icon="mdi-file-tree-outline" width="100%"
                                     color="blue" size="x-large" stacked variant="elevated"
                                     :to="'/zlecenie/' + ticket.id"
                              >
                                Wycena
                              </v-btn>
                            </v-col>
                            <v-col>
                              <v-btn prepend-icon="mdi-file-pdf-box"
                                     target="_blank" width="100%" color="red" size="x-large" stacked variant="elevated"
                                     :href="'https://' + host + '/api/zlecenie-pdf/'+ ticket.id"
                              >
                                Oferta
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-window-item>
                  <v-window-item value="2">
                    <TableProducts
                        :projectName="projectName"
                        :itemsWithIsScreenTrue="itemsWithIsScreenTrue"
                        :getValueFromScreen="getValueFromScreen"
                        :selectedProducts="selectedProducts"
                    >
                    </TableProducts>
                  </v-window-item>
                </v-window>-->
      </v-card-item>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
//import TableProducts from "@/components/TableProducts.vue";
import {useStorage} from "vue3-storage";
import TableProducts from "@/components/TableProducts.vue";
import ActionsButton from "@/components/navigation/ActionsButton.vue";
//import ActionsButton from "@/components/navigation/ActionsButton.vue";
//import TableProducts from "@/components/TableProducts.vue";
//import ActionsButton from "@/components/navigation/ActionsButton.vue";
//import ActionsButton from "@/components/navigation/ActionsButton.vue";


export default {
  name: "TicketDetail",
  components: {ActionsButton, TableProducts, VueDatePicker: VueDatePicker},
  //components: {ActionsButton, TableProducts},
  //components: {ActionsButton, TableProducts, VueDatePicker: VueDatePicker},
  props: ['ticketId'],
  data() {
    return {
      ticket: null,
      hasSaved: false,
      existCustomer: null,
      isEditing: true,
      selectedProducts: null,
      tab: null,
      dialogT: [],
      title: null,
      name: "",
      lastName: "",
      street: "",
      homeNumber: "",
      postcode: "",
      city: "",
      phone: "",
      emailCustomer: "",
      expirationDate: "",
      itemsWithIsScreenTrue: null,
      projectName: null,
      host: '127.0.0.1:8000',
      customerId: null,
      customerAddressId: null,
      status: null,
      isFormValid: false,
      titleNameRules: [
        value => {
          if (value) return true
          return 'Wymagane.'
        },
        value => {
          if (value.length <= 255) return true
          return 'Maksymalna liczba znaków to 255.'
        },
      ],
      onlyLettersRules: [
        value => {
          if (!/\d/.test(value)) return true
          return 'Pole nie może zawierać liczb.'
        },
      ],
      phoneRules: [
        value => {
          if (/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/.test(value) || !value) return true
          return 'Numer telefonu może zawierać wyłącznie cyfry (minimum 9) i dodatkowo znak "+".'
        },
      ],
      emailRules: [
        value => {
          if (/.+@.+\..+/.test(value) || !value) return true
          return 'E-mail musi być prawidłowy'
        },
      ],
    }
  },
  watch: {
    existCustomer(newValue) {
      if (newValue !== null) {
        const issetCustomer = this.getCustomerById(newValue);

        //////console.log(issetCustomer);

        this.customerId = issetCustomer.id;
        this.name = issetCustomer.firstName
        this.lastName = issetCustomer.lastName
        this.phone = issetCustomer.phone
        this.emailCustomer = issetCustomer.email
        this.customerAddressId = issetCustomer.address.id;
        this.street = issetCustomer.address.street;
        this.homeNumber = issetCustomer.address.homeNumber;
        this.postcode = issetCustomer.address.postcode;
        this.city = issetCustomer.address.city;
      }

    }
  },
  methods: {
    onClose() {
      ////console.log(event);
      this.dialogT[this.$props.ticketId] = true;
    },

    customFilter(itemTitle, queryText, item) {
      const textOne = item.raw.firstName.toLowerCase()
      const textTwo = item.raw.lastName.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
    },
    save() {
      this.isEditing = !this.isEditing
      this.hasSaved = true
    },

    checkRoles(roles) {
      let allRoles = this.$store.state.roles;
      if (allRoles === null) {
        allRoles = useStorage().getStorageSync("roles");
      }
      return roles.some(role => allRoles.includes(role));
    },

    getValueFromScreen(idScreen) {
      let result = false;
      if (this.selectedProducts.find((s) => s.idTree === idScreen)) {
        result = true;
      }
      return result;
    },

    async getProject() {

      if (this.tree) {
        this.itemsWithIsScreenTrue = this.extractItemsWithIsScreenTrue(this.tree.children);
        let project = this.getProjectById(this.ticket.selectedProject)
        if (project) {
          this.projectName = project.title;
          //////console.log("project", project);
        }
      }


    },
    extractItemsWithIsScreenTrue(items) {
      const result = [];
      if (items) {
        for (const item of items) {
          if (item.isScreen === true) {
            result.push(item);
          }
          if (item.children && Array.isArray(item.children)) {
            result.push(...this.extractItemsWithIsScreenTrue(item.children));
          }
        }
      }
      return result;
    },
    async saveExpirationDate() {

      this.dialogT[this.$props.ticketId] = true;
      ////////console.logthis.expirationDate);
      await this.$store.dispatch('updateTicket', {
        item: {
          expirationDate: this.expirationDate
        },
        idTicket: this.ticketId,
        idCustomers: this.ticket.customers !== undefined ? this.ticket.customers.id : null,
      });
      //this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    async saveCustomerData() {
      let today = new Date();
      let day = String(today.getDate()).padStart(2, '0');
      let month = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let year = today.getFullYear();
      let hour = today.getHours();
      let minutes = today.getMinutes();
      let seconds = today.getSeconds();

      today = year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds;
      ////////console.log'saveCustomerData', this.ticket.customersDetail);


      await this.$store.dispatch('updateTicket', {
        item: {
          title: this.title,
          status: this.status,
          math: JSON.stringify(this.math),
          selectedProducts: JSON.stringify(this.selectedProducts),
          selectedProject: JSON.stringify(this.selectedProject),
          tree: JSON.stringify(this.tree),
          customers: {
            id: this.customerId,
            name: this.name,
            lastName: this.lastName,
            phone: this.phone,
            email: this.emailCustomer,
            addresses: {
              id: this.customerAddressId,
              street: this.street,
              homeNumber: this.homeNumber,
              postcode: this.postcode,
              city: this.city
            }
          }
        },

        idTicket: this.ticketId,
        idCustomers: this.ticket.customers !== undefined ? this.ticket.customers.id : null,
      });


      this.customerId = this.$store.state.tickets.currentIdCustomers;
      this.customerAddressId = this.$store.state.tickets.currentIdCustomersAddress;
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    formatDate(date) {
      // Format the date as needed
      return new Date(date).toLocaleDateString('pl-PL', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });
    },
  },
  computed: {
    ...mapGetters(['getTicketById', 'getToken', 'getProjectById', 'getCustomerById']),
    ...mapState(['customers'])
  },
  created() {

    this.customers.customers = this.customers.customers.map(obj => {
      if (obj !== undefined) {
        obj.title = obj.firstName + ' ' + obj.lastName
      }
      return obj;
    })
    ////////console.logthis.ticketId);

    this.ticket = this.getTicketById(this.ticketId);

    ////////console.log'this.ticket', this.ticket)

    this.title = this.ticket.title;
    this.expirationDate = this.ticket.expirationDate ? this.ticket.expirationDate.split('T')[0] : '';
    this.status = this.ticket.status;
    this.selectedProducts = JSON.parse(this.ticket.selectedProducts ?? null);
    this.selectedProject = JSON.parse(this.ticket.selectedProject ?? null);
    this.tree = JSON.parse(this.ticket.tree ?? null);
    this.math = JSON.parse(this.ticket.math ?? null);
    if (this.ticket.customers) {
      this.customerId = this.ticket.customers.id;
      this.name = this.ticket.customers.name;
      this.lastName = this.ticket.customers.lastName;
      this.phone = this.ticket.customers.phone;
      this.emailCustomer = this.ticket.customers.email;
      if (this.ticket.customers.addresses) {
        this.street = this.ticket.customers.addresses.street;
        this.homeNumber = this.ticket.customers.addresses.homeNumber;
        this.postcode = this.ticket.customers.addresses.postcode;
        this.city = this.ticket.customers.addresses.city;
      }
    }
    this.getProject();
  }


}
</script>

<style scoped>

</style>
