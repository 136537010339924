import axios from "axios";
export const products = {
    state: {
        readyProducts: [],
        currentProduct: null,
    },
    getters: {
        getReadyProducts: state => {
            return state.readyProducts;
        },
        totalProducts: state => {
            return state.readyProducts.length;
        },
        getReadyProductById: (state) => (id) => {
           //////console.log'getReadyProductById',state.readyProducts.find(item => item.id === id))
            return state.readyProducts.find(item => item.id === id);
        },

    },
    mutations: {

        setCurrentProduct(state, id) {
            state.currentProduct = id;
        },
        setReadyProducts(state, items) {
            state.readyProducts = items;
        },
        removeReadyProduct(state, itemId) {
            state.readyProducts = state.readyProducts.filter(item => item.id !== itemId);
        },
        addProduct(state, items) {
           console.log('addProduct',items);
            state.readyProducts.push(items);
        },
        updateProduct(state, updatedProduct) {

            console.log('updatedProduct', updatedProduct);

            const index = state.readyProducts.findIndex(item => item.id === updatedProduct.id);
            if (index !== -1) {
                // If the product with the updated ID exists, update it in the readyProducts array
                state.readyProducts.splice(index, 1, updatedProduct);
            } else {
                // If the product doesn't exist, you can choose to handle it as per your application's requirement.
                //console.error(`Product with ID ${updatedProduct.id} not found.`);
            }
        },
    },
    actions: {
        async loadProductById({ commit ,rootState}, id) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_LOCATION + '/api/products/' + id, {
                    headers: { Authorization: `Bearer ${rootState.token}`}
                });
                const items = response.data;
                commit('updateProduct', items);
            } catch (error) {
                commit('setNetworkError', true);
            }
        },
        async loadReadyProducts({ commit ,rootState}) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_LOCATION + '/api/products', {
                    headers: { Authorization: `Bearer ${rootState.token}`}
                });
                const items = response.data;
                commit('setReadyProducts', items);
            } catch (error) {
                commit('setNetworkError', true);
            }
        },
        async removeReadyProduct({commit, rootState }, itemId) {
            try {
                await axios.delete(process.env.VUE_APP_API_LOCATION + `/api/products/${itemId}`,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(() => {
                        // Commit the mutation to update the state
                        commit('removeReadyProduct', itemId);
                        commit('setResponseMessage', 'Produkt został usunięty');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setNetworkError', true);
            }
        },
        async addProduct({commit, rootState}, data) {
            try {
                await axios.post(process.env.VUE_APP_API_LOCATION + '/api/products',
                    data,
                    {headers: {Authorization: `Bearer ${rootState.token}`, Accept: 'application/json'}})
                    .then(response => {
                        const items = response.data;
                        commit('addProduct', items);
                        commit('setResponseMessage', 'Produkt został dodany');
                        commit('setResponseStatus', 'success');
                        commit('setCurrentProduct', items.id);
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },

        async cloneProduct({commit, rootState}, data) {
            try {
                await axios.put(process.env.VUE_APP_API_LOCATION + '/api/products/' + data + '/clone',
                    data,
                    {headers: {Authorization: `Bearer ${rootState.token}`, Accept: 'application/json'}})
                    .then(response => {
                        const items = response.data;
                        commit('addProduct', items);
                        commit('setResponseMessage', 'Produkt został skopiowany');
                        commit('setResponseStatus', 'success');
                        commit('setCurrentProduct', items.id);
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateProduct({commit, rootState}, item) {

            let data = item.item;
            let productId = item.productId

           //////console.log'updateProduct',data.unit, data.unit2, fetchData);

            try {
                await axios.put(process.env.VUE_APP_API_LOCATION + '/api/products/' + productId,
                    data,
                    {headers: {Authorization: `Bearer ${rootState .token}`, Accept: 'application/json'}})
                    .then(response => {
                        const items = response.data;

                        commit('updateProduct', items);
                        commit('setResponseMessage', 'Produkt został zaktualizowany');
                        commit('setResponseStatus', 'success');
                        commit('setCurrentProduct', items.id);
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
    }
}
