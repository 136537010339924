<template>
  <v-dialog
      v-model="dialog"
      persistent
      width="auto"
  >
    <template v-slot:activator="{ props }">
      <v-btn
          color="red"
          v-bind="props"
          append-icon="mdi-trash-can" size="x-small"
      >
        Usuń
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        Czy jesteś pewien swojej decyzji?
      </v-card-title>
      <v-card-text>Usunięcie wpisu jest nie odwracalne.</v-card-text>
        <ActionsButton
            action-label="Potwierdzam"
            action-color="red"
            action-icon="mdi-delete-forever"
            @save="confirm()" @annulled="dialog = false"></ActionsButton>
    </v-card>
  </v-dialog>
</template>

<script>
import ActionsButton from "@/components/navigation/ActionsButton.vue";

export default {
  name: "RemoveButton",
  components: {ActionsButton},
  data () {
    return {
      dialog: false,
    }
  },
  methods:{
    confirm(){
      this.$emit('confirm');
      this.dialog = false
    }
  }
}
</script>

<style scoped>

</style>
