<template>
  <template v-for="unit in feature.units" :key="unit.id">
    <template
        v-if="unit.id === readDataFromMathProject?.unit">
      {{ unit.title }}
    </template>
  </template>
</template>
<script>
export default {
  name: 'UnitView',
  props: {
    child: {},
    feature: {},
    readDataFromMathProject: {}
  }
}
</script>
