<template>
  <div v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER', 'ROLE_FEATURES_READ'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
            <v-dialog
                v-model="dialog"
                width="600"
                v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_FEATURES_SAVE'])"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                    color="blue"
                    style="margin-top:6px;"
                    class="float-end"
                    v-bind="props"
                    size="x-small"
                    prepend-icon="mdi-plus"
                    @click="clearValues"
                >
                  Dodaj nową cechę
                </v-btn>
              </template>
              <v-card>
                <v-form v-model="isFormValid">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-text-field
                            density="compact"
                            label="Cecha"
                            :rules="[rules.required, rules.counter]"
                            v-model="title"
                            hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-checkbox
                            density="compact"
                            :label="unit.title"
                            v-for="unit in units.units"
                            :key="unit.id"
                            v-model="selectedUnits"
                            :value="unit"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <ActionsButton @annulled="dialog = false" @save="fetchAddFeature(itemId)"
                                 :disabledSave="!isFormValid"></ActionsButton>
                </v-form>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              density="compact"
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items-length="totalItems"
              :items="features.features"
              :loading="loading"
              :search="search"
              class="elevation-1"
              item-value="id"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.selectable.id }}</td>
                <td>{{ item.selectable.title }}</td>
                <td>
                  <span v-for="units in item.selectable.units" :key="units.id"> [ {{ units.title }} ] </span>
                </td>
                <td align="end">
                  <v-btn class="mx-2" append-icon="mdi-pencil-box" size="x-small" fab dark color="blue"
                         @click="editItem(item.selectable)"
                         v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_FEATURES_SAVE'])">
                    Edytuj
                  </v-btn>
                  <RemoveButton @confirm="fetchDeleteFeature(item.selectable.id)"
                                v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_FEATURES_REMOVE'])">

                  </RemoveButton>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-main>
    </v-layout>
  </div>
  <div v-else>
    <DenyAccessPage></DenyAccessPage>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import {useStorage} from "vue3-storage";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";
import DenyAccessPage from "@/components/DenyAccessPage.vue";
import ActionsButton from "@/components/navigation/ActionsButton.vue";

export default {
  name: 'FeaturesView',
  components: {ActionsButton, DenyAccessPage, RemoveButton, BreadcrumbsComponent},
  data: () => {
    return {
      isFormValid: false,
      rules: {
        required: value => !!value || 'Wymagane.',
        counter: value => value.length <= 20 || 'Max 20 znaków',
      },

      title: '',
      breadcrumbs: [
        {
          title: 'Produkty',
          disabled: false,
          to: 'products/ready',
        },
        {
          title: 'Kategorie',
          disabled: false,
          to: '/categories'
        },
        {
          title: 'Cechy',
          disabled: true,
        },
      ],

      dialog: false,
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Jednostki', key: 'measurementUnits', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      loading: false,
      totalItems: null,
      itemId: null,
      items: [],
      selectedUnits: [],
    };
  },
  mounted() {
    this.totalItems = this.features.features.length;
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken', "getRoles"]),
    ...mapState(["features", "units"])
  },
  methods: {
    ...mapActions(['addFeature']),
    clearValues(){
      this.title= '';
      this.selectedUnits= [];
    },
    async fetchAddFeature(idFeature) {
      //////console.log'fetchAddFeature', idFeature);
      if (idFeature) {
        await this.$store.dispatch('updateFeature', {
          item: {
            title: this.title,
            units: this.selectedUnits
          }, idFeature: idFeature
        });
      } else {
        await this.$store.dispatch('addFeature', {
          title: this.title,
          units: this.selectedUnits
        });
      }
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      if (this.$store.state.users.responseStatus === 'success') {
        this.dialog = false;
        this.itemId = null;
      }
    },
    async fetchDeleteFeature(idFeature) {
      await this.$store.dispatch('removeFeature', idFeature);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    editItem(unit) {
      this.dialog = true
      this.title = unit.title
      this.itemId = unit.id
      this.selectedUnits = unit.units;
    },
    checkRoles(roles) {
      let allRoles = this.$store.state.roles;
      if (allRoles === null) {
        allRoles = useStorage().getStorageSync("roles");
      }
      return roles.some(role => allRoles.includes(role));
    },
  }

}
</script>
