import axios from "axios";
export const statuses = {
    state: {
        statuses: [],
        responseStatus: '',
        responseMessage: ''
    },
    getters: {
        getStatuses: state => {
            return state.statuses;
        },
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        },
        setResponseStatus(state, status) {
            state.responseStatus = status;
        },
        setStatuses(state, items) {
            state.statuses = items;
        },
        addStatus(state, items) {
            state.statuses.push(items);
        },
        updateStatus(state, data) {
            const index = state.statuses.findIndex(item => item.idStatus === data.idStatus);
            state.statuses.splice(index, 1, {id: data.idStatus ,
                name: data.item.name,
                description: data.item.description,
                icon: data.item.icon,
                color: data.item.color,
            });
        },
        removeStatus(state, itemId) {
            state.statuses = state.statuses.filter(item => item.id !== itemId);
        },
    },
    actions: {
        async loadStatuses({commit, rootState }) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_LOCATION + '/api/element_statuses',
                    {headers: {Authorization: `Bearer ${rootState .token}`}});
                const items = response.data;
                commit('setStatuses', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async addStatus({commit, rootState }, data) {
            try {
                await axios.post(process.env.VUE_APP_API_LOCATION + '/api/element_statuses',
                    data,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('addStatus', items);
                        commit('setResponseMessage', 'Status został dodany');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateStatus({commit, rootState }, data) {
           ////console.log'updateStatus', data)
            try {
                await axios.put(process.env.VUE_APP_API_LOCATION + '/api/element_statuses/' + data.idStatus,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(() => {
                        commit('updateStatus', data);
                        commit('setResponseMessage', 'Status został zaktualizowany');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async removeStatus({commit, rootState }, itemId) {
            try {
                await axios.delete(process.env.VUE_APP_API_LOCATION + '/api/element_statuses/' + itemId,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(() => {
                        commit('removeStatus', itemId);
                        commit('setResponseMessage', 'Status został usunięty');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
    }
}
