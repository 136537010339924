<template>
  <notifications position="bottom right"/>
  <LoginView v-if="$route.name !== 'rejestracja' && $route.name !== 'haslo' && !isLoggedIn && countData === 0 "/>
  <NewPasswordView v-if="$route.name === 'haslo' && !isLoggedIn && countData === 0 "/>
  <ErrorPage v-if="networkError === true"></ErrorPage>

  <v-container text-center v-if="isLoggedIn && countData !== 100 && networkError === false">
    <v-row justify="center" align="center" class="h-100 bgdark">
      <v-col cols="4" sm="12" lg="4" align-self="center">
        <v-card class="bgdark">
          <v-card-title class="text-center">
            <v-img
                :width="300"
                aspect-ratio="16/9"
                class="mt-3 mx-auto"
                cover
                src="programigo.png"
            ></v-img>
          </v-card-title>

          <v-progress-linear
              class="my-5"
              v-model="countData"
              height="50"
              bg-color="deep-purple-accent-4"
              color="deep-purple-accent-4"
          >
            <strong>{{ Math.ceil(countData) }}%</strong>
          </v-progress-linear>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <v-app id="inspire" v-if="isLoggedIn && countData === 100 && networkError === false">
    <v-navigation-drawer v-model="drawer" class="border-0">
      <v-list density="compact" nav v-model:opened="open">
        <v-list-item prepend-icon="mdi-home" title="Strona głowna" value="home" to="/"></v-list-item>
        <v-list-item prepend-icon="mdi-family-tree" title="Projekty" value="projects" to="/projects"
                     v-if="checkRoles(['ROLE_PROJECTS_READ','ROLE_ADMIN'])"
        ></v-list-item>
        <v-list-item prepend-icon="mdi-list-box-outline" title="Zlecenia" value="zlecenia" to="/tickets"
                     v-if="checkRoles(['ROLE_TICKETS_READ','ROLE_ADMIN'])"></v-list-item>
        <v-divider></v-divider>
        <v-list-item prepend-icon="mdi-account-group" title="Klienci" value="customers"
                     to="/customers" v-if="checkRoles(['ROLE_ADMIN'])"></v-list-item>
        <v-list-item prepend-icon="mdi-package-variant-closed" title="Produkty handlowe" value="productsReady"
                     to="/products/ready" v-if="checkRoles(['ROLE_TRADING_PRODUCTS_READ','ROLE_ADMIN'])"></v-list-item>
        <v-list-group value="Administracja"
                      v-show="checkRoles(['ROLE_VARIABLES_READ', 'ROLE_TYPES_READ','ROLE_FEATURES_READ','ROLE_UNITS_READ','ROLE_USERS_READ','ROLE_ADMIN'])"
        >
          <template v-slot:activator="{ props }">
            <v-list-item
                v-bind="props"
                prepend-icon="mdi-cog"
                title="Ustawienia"
            ></v-list-item>
          </template>
          <v-list-item title="Rodzaje projektów" value="types" to="/rodzaje-projektow"
                       v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_TYPES_PROJECT_READ'])"
          ></v-list-item>
          <v-list-item title="Zmienne" value="variables" to="/variables"
                       v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_VARIABLES_READ'])"></v-list-item>
          <!--          <v-list-item  title="Statusy" value="statuses" to="/statuses" v-if="checkRoles(['ROLE_VARIABLES_READ','ROLE_ADMIN'])"></v-list-item>-->
          <v-list-item title="Kategorie" value="categories" to="/categories"
                       v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_CATEGORIES_READ'])"></v-list-item>
          <v-list-item title="Cechy" value="features" to="/features"
                       v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_FEATURES_READ'])"></v-list-item>
          <v-list-item title="Jednostki" value="units" to="/units"
                       v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_UNITS_READ'])"></v-list-item>
          <v-list-item title="Użytkownicy" value="users" to="/users"
                       v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_USER_READ'])">
          </v-list-item>
          <v-list-item title="Historia" value="history" to="/history"
                       v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN'])">
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>KONFIGURATOR</v-toolbar-title>
      <v-btn icon="mdi-theme-light-dark" @click="toggleTheme">
      </v-btn>
      <v-spacer></v-spacer>
      <v-menu>
        <template v-slot:activator="{ props: menu }">
          <v-tooltip location="top">
            <template v-slot:activator="{ props: tooltip }">
              <v-btn icon="mdi-account" v-bind="mergeProps(menu, tooltip)"></v-btn>
            </template>
            <span>Moje konto</span>
          </v-tooltip>
        </template>
        <v-list class="elevation-6">
          <v-list-item>
            {{ getUser }}
          </v-list-item>
          <v-list-item :to="'/user/profile/' + getIdUser">
            Mój profil
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="logout">
            Wyloguj
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main fluid>
      <v-container fluid fill-height class="page-wrapper">
        <router-view :key="$route.fullPath"/>
      </v-container>


    </v-main>
  </v-app>


  <div v-if="isUnsupportedResolution" class="unsupported-overlay">
    Ta rozdzielczość jest nieobsługiwana
  </div>

</template>
<script>
import {useTheme} from 'vuetify'
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

import LoginView from "@/views/LoginView.vue";
import ErrorPage from "@/components/ErrorPage.vue";
import {useStorage} from "vue3-storage";
import {mergeProps} from 'vue'
import NewPasswordView from "@/views/NewPasswordView.vue";

export default {

  name: "App",
  components: {NewPasswordView, ErrorPage, LoginView},
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken', "getUser", "getRoles","getIdUser"]),
    ...mapState(['networkError']),
  },
  mounted() {
    this.checkResolution();
    window.addEventListener('resize', this.checkResolution);

    if (this.isLoggedIn && this.countData !== 100) {
      this.loadData();
      this.loadNewUser();
      this.getNewUser = JSON.parse(localStorage.pro_user).value;
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkResolution);
  },
  watch: {
    isLoggedIn(newValue, oldValue) {
      ////console.log`isLogin changed from ${oldValue} to ${newValue}`);
      if (newValue === true) {
        this.loadingData === true;
        this.loadData();
        this.loadingData === false;
        this.loadNewUser();
        this.getNewUser = JSON.parse(localStorage.pro_user).value;
      }
      if (oldValue === true && newValue === false) {
        this.countData = 0;
      }
    },

  },
  data: () => ({
    loadingData: false,
    countData: 0,
    drawer: null,
    open: ['Users'],
    getNewUser: null,
    getUserId: null,
    isUnsupportedResolution: false,
  }),
  created() {
    this.loadNewUser()
  },
  setup() {

    const theme = useTheme()
    return {
      theme,
      toggleTheme: () => theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
    }

  },

  methods: {
    checkResolution() {
      this.isUnsupportedResolution = window.innerWidth < 1200;
    },
    mergeProps,
    ...mapMutations(['logout', 'checkRoles']),
    ...mapActions([
      'loadReadyProducts',
      'loadBuildProducts',
      'loadCategories',
      'loadProjects',
      'loadProjectsElements',
      'loadVariables',
      'loadStatuses',
      'loadProductProductStatuses',
      'loadUsers',
      'loadVariables',
      'loadUnits',
      'loadFeatures',
      'loadTypes',
      'loadTickets',
      'loadCustomers'
    ]),
    loadNewUser() {
      this.getNewUser = localStorage.getUser;
      this.getUserId = localStorage.getItem('pro_idUser');
    },
    async loadData() {
      if (this.isLoggedIn) {
        this.countData = 5;
        await this.loadReadyProducts();

        this.countData = 30;
        await this.loadBuildProducts();
        this.countData = 35;
        await this.loadCategories();
        this.countData = 37;
        await this.loadProjects();
        this.countData = 40;
        await this.loadProjectsElements();
        this.countData = 50;
        await this.loadVariables();
        this.countData = 80;
        await this.loadStatuses();
        this.countData = 90;
        await this.loadProductProductStatuses();
        this.countData = 92;
        await this.loadUsers();
        this.countData = 93;
        await this.loadVariables();
        this.countData = 95;
        await this.loadUnits();
        this.countData = 98;
        await this.loadFeatures();
        await this.loadTickets();
        await this.loadTypes();
        await this.loadCustomers();
        this.countData = 100;

      }
    },
    checkRoles(roles) {
      let allRoles = this.$store.state.roles;
      if (allRoles === null) {
        allRoles = useStorage().getStorageSync("roles");
      }
      return roles.some(role => allRoles.includes(role));
    },
  }
}
</script>
<style lang="scss">
$bgColor: rgba(238, 242, 246);
$bgColor2: rgb(225, 226, 227);
$bgColorDark: rgb(65, 65, 65);
$bgColorDark2: rgb(49, 49, 49);
$bgColorDark3: rgb(33,33,33);
.page-wrapper {
  min-height: calc(100vh - 100px);
  padding: 15px;
  border-radius: 12px;
}

.page-head {
  background: $bgColor2;
}

.v-theme--light .page-wrapper {
  background: $bgColor;
}

.v-theme--dark .page-wrapper {
  background: $bgColorDark;
}

.v-theme--dark .bgdark {
  background: $bgColorDark3;
}

.v-theme--dark .v-main{
  background: rgba(33,33,33);
}

.default-color {
  color: #0c8334 !important;
}

.v-list-item:hover > .v-list-item__overlay {
  opacity: calc(var(--v-hover-opacity) * var(--v-theme-overlay-multiplier));
}
.unsupported-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Lekkie przyciemnienie */
  backdrop-filter: blur(5px); /* Efekt rozmazania */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  z-index: 1000;
}
</style>
