export function generateUniqueId(existingIds) {
    // Funkcja do generowania unikalnego identyfikatora
    let newId = Math.floor(Math.random() * 1000) + 1; // Losowy numer z zakresu 1-1000
    while (existingIds.includes(newId)) {
        newId = Math.floor(Math.random() * 1000) + 1;
    }
    return newId;
}

export function addMath(arrayMath, a, b) {


    //console.log(arrayMath, a, b, arrayMath[a][b]);

    let math = '';
    arrayMath[a][b].forEach((m) => {
        math = math + '' + m;
    });
    //this.updateTree(math, a, 'math', b);
    return math;
}

export function rozdzielDzialanie(dzialanie) {

        // Usuń wszystkie spacje z działania
        dzialanie = dzialanie.replace(/\s/g, '');

        // Utwórz tablicę do przechowywania wartości i operatorów
        const wynik = [];

        // Zmienna do przechowywania aktualnie analizowanego fragmentu liczby
        let liczba = '';

        // Przejdź przez każdy znak działania
        for (let i = 0; i < dzialanie.length; i++) {
            const znak = dzialanie[i];

            // Sprawdź, czy aktualny fragment pasuje do wzorców '2_??_C' lub '19_12_W' itd.
            const regexPattern = /^([A-Za-z\d]{1,3}_){0,2}[A-Za-z\d]{1,3}_\d{1,3}\s$/;
            const matchedPattern = liczba + znak;

            if (matchedPattern.match(regexPattern)) {
                liczba = matchedPattern; // Pomijamy wartość, która pasuje do wzorców i przechodzimy do kolejnego fragmentu
            } else if (znak === '+' || znak === '*' || znak === '-' || znak === '/' || znak === '(' || znak === ')') {
                wynik.push(liczba.replace(',', '.'));
                wynik.push(znak);
                liczba = ''; // Resetuj zmienną liczba, aby analizować kolejną liczbę
            } else {
                liczba += znak;
            }
        }

        // Dodaj ostatnią liczbę do wyniku
        wynik.push(liczba.replace(',', '.'));

        return wynik;

}


// Konkretna wartość cechy wybranego produktu (radio)
export function mathSpecificValueOfaFeatureOfTheSelectedProduct(d) {
    console.log('Konkretna wartość cechy wybranego produktu (radio)',d.children);
    const row = d.select.find((s) => {
        if (Number(s.idTree) === Number(d.children) &&
            Number(s.idFeature) === Number(d.feature)
        ) {
            return s;
        }
    });

    if (row !== undefined) {
        if (d.content === "W") {
            return parseFloat(row.value.toString().replace(',', '.'));
        }
    }

}

// Konkretna wartość cechy (checkbox)
export function mathSpecificValueOfaFeature(d) {

    console.log('Konkretna wartość cechy 1', d.select, d.product, d.feature);
    const row = d.select.find((s) => {
        if (Number(s.idTree) === Number(d.product) &&
            Number(s.idFeature) === Number(d.feature)
        ) {
            return s;
        }
    });
    return row?.value;
}

// Ogólne wartości gałęzi
export function mathBranchValues(d) {


    console.log(d.originalMath);

    const row = d.originalMath.find((s) => {
        if (Number(s.idTree) === Number(d.product) &&
            s.features === d.feature
        ) {
            return s;
        }
    });

    console.log(row);
    ////////console.log'No i zwracam', mathProduct, mathFeature, row);

    if (row !== undefined) {
        if (d.content === "C") {
            return parseFloat(row.priceReady.toString().replace(',', '.'));
        }
        if (d.content === "J") {
            return parseFloat(row.priceUnit.toString().replace(',', '.'));
        }
    }

}


// W sumie pojawiłą się zmienna
export function mathVariableValue(d, variables) {
    // Powinno zwracać wartość danej stałej
    // Z_[ID]

    if (d.product === 'Z') {
        const desiredElement = variables.find(item => item.id === Number(d.feature));
        return desiredElement ? String(desiredElement.value) : '';
    } else {
        return '';
    }
}

// Suma wszystkich dzieci - główne wartości
export function mathSumOfAllChildren(d) {
    let math = '';
    if (d.childrens !== undefined) {
        d.childrens.filter((children) => {
            let row = null;
            if (d.content === "C" || d.content === "J") {
                row = d.originalMath.find((s) => {
                    if (Number(s.idTree) === Number(children)
                    ) {
                        return s;
                    }
                });
            } else {
                row = d.select.find((s) => {
                    if (Number(s.idTree) === Number(children)
                    ) {
                        return s;
                    }
                });
            }
            if (row !== null) {
                if (d.content === "C") math += String(row.priceReady);
                if (d.content === "J") math += String(row.priceUnit);
                math += '+';
            }
        });
        let lastChar = math.charAt(math.length - 1);
        if (lastChar === "+") {
            math = math.slice(0, -1);
        }
        return math ?? '';
    }
    return math ?? '';
}

// Suma wszystkich dzieci - cechy
export function mathSumOfAllChildrenFeature(d) {

    console.log(d);

    let math = '';
    if (d.childrens !== undefined) {
        d.childrens.filter((children) => {
            let row = null;

            row = d.select.find((s) => {
                if (Number(s.idTree) === Number(children) &&
                    Number(s.idFeature) === Number(d.feature)
                ) {
                    return s;
                }
            });

            if (row !== null && row !== undefined) {
                if (d.content === "W") math += String(row.value);
                math += '+';
            }
        });
        let lastChar = math.charAt(math.length - 1);
        if (lastChar === "+") {
            math = math.slice(0, -1);
        }
        return math ?? '';
    }
    return math ?? '';
}
