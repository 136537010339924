<template>
  <v-container fluid class="background-first">
    <div class="titlePage">
      <h1>Nowe hasło</h1>
    </div>
    <v-container>
      <v-row>
        <v-col cols="6" class="offset-3 ">
          <v-img
              :width="400"
              aspect-ratio="16/9"
              class="mt-3 me-auto"
              cover
              src="programigo.png"
          ></v-img>
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <v-col cols="5" class="offset-3 ">
          <v-card class="my-3">
            <v-form @submit.prevent="changePassword" ref="form">
              <v-card-item>
                <v-text-field
                    prepend-icon="mdi-email"
                    name="pass1"
                    label="Hasło"
                    type="password"
                    v-model="pass1"
                    variant="plain"
                    style="margin-left: 10px; margin-top:5px;"
                    :rules="passwordRules"
                ></v-text-field>
              </v-card-item>
              <v-divider></v-divider>
              <v-card-item>
                <v-text-field
                    prepend-icon="mdi-email"
                    name="pass2"
                    label="Powtórz hasło"
                    type="password"
                    v-model="pass2"
                    variant="plain"
                    style="margin-left: 10px; margin-top:5px;"
                    :rules="passwordRules"
                ></v-text-field>
              </v-card-item>
              <v-divider></v-divider>
              <v-card-text class="text-end">
                <SaveButton label="Zmień hasło" type="submit" @click="changePassword"></SaveButton>
              </v-card-text>
            </v-form>
          </v-card>

          <v-btn variant="text" class="text-none" to="/">Zaloguj się!</v-btn>
        </v-col>
      </v-row>

    </v-container>
  </v-container>


</template>
<script>
import SaveButton from "@/components/navigation/element/SaveButton.vue";
import router from "@/router";

export default {
  name: 'NewPasswordView',
  components: {SaveButton},
  data: () => {
    return {
      pass1: null,
      pass2: null,
      passwordRules: [
        value => {
          if (value) return true
          return 'Hasło jest wymagane.'
        },
        value => {
          if (/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).*$/.test(value)) return true

          return 'Twoje hasło musi zawierać co najmniej jedną wielką literę, jedną małą literę, jedną cyfrę i jeden znak specjalny.'
        },
      ],

    };
  },
  created() {

    if (this.$route.query.token === undefined) {
      router.push({path: '/'})
    }
  },
  methods: {
    async changePassword() {

      const pass1 = this.$refs.form.pass1.value;
      const pass2 = this.$refs.form.pass2.value;

      console.log(pass1, pass2, this.$route.query.token);

      if(pass1 === pass2){
        const dataToUpdate = {
          "password": pass1,
          "token" : this.$route.query.token
        }
        await this.$store.dispatch('newPassword', dataToUpdate);
        this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
        router.push({path: '/'})
      }


    }
  },
};
</script>
<style>
.login-form {
  max-width: 500px;
}

</style>
