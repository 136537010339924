<template>
  <div v-if="checkRoles(['ROLE_ADMIN', 'ROLE_TICKETS_READ'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
            <v-btn
                color="green"
                style="margin-top:6px;"
                class="float-end mx-2"
                size="x-small"
                prepend-icon="mdi-plus-box"
                v-if="checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
            >
              Nowe zlecenie
              <v-dialog
                  v-model="dialogNewTicket"
                  activator="parent"
                  persistent
                  width="auto"
              >
                <v-form v-model="isFormValid">
                <v-card>
                  <v-card-text>
                    <v-text-field style="width: 550px;" label="Nazwa zlecenia" variant="outlined"
                                  v-model="title" :rules="ticketNameRules"></v-text-field>
                  </v-card-text>

                  <ActionsButton @annulled="dialogNewTicket = false" @save="fetchAddTicket('itemId')"
                                 :disabledSave="!isFormValid"></ActionsButton>
                </v-card>
                </v-form>
              </v-dialog>
            </v-btn>
            <v-btn
                color="blue"
                style="margin-top:6px;"
                class="float-end mx-2"
                size="x-small"
                prepend-icon="mdi-file-tree-outline"
                to="/projects"
                v-if="checkRoles(['ROLE_PROJECTS_READ','ROLE_ADMIN'])"
            >
              Projekty
            </v-btn>
            <v-btn
                color="blue"
                style="margin-top:6px;"
                class="float-end mx-2"
                size="x-small"
                prepend-icon="mdi-file-tree-outline"
                to="/projects/element"
                v-if="checkRoles(['ROLE_PROJECTS_READ','ROLE_ADMIN'])"
            >
              Gałęzie drzewa
            </v-btn>

          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              density="compact"
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items-length="totalItems"
              :items="tickets.tickets"
              :loading="loading"
              :search="search"
              class="elevation-1"
              item-value="id"
          >

            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.selectable.id }}</td>
                <td>{{ item.selectable.title }}</td>
                <td>
                  <template v-if="item.selectable.customers">{{ item.selectable.customers.name }} {{ item.selectable.customers.lastName }}</template>
                  <template v-else>-</template>
                </td>
                <td>{{ formatDate(item.selectable.createAt) }}</td>
                <td>{{ formatDate(item.selectable.modifiedAt) ?? '-'}}</td>
                <td>
                  <v-chip
                      :color="status(item.selectable.status).color"
                  >
                    {{ status(item.selectable.status).label }}
                  </v-chip>
                </td>
                <td align="end">
                  <TicketDetail :ticketId="item.selectable.id"></TicketDetail>
                  <v-btn class="mx-2" append-icon="mdi-trash-can" size="x-small" fab dark color="blue"
                         :to="'/zlecenie/' + item.selectable.id"
                         v-if="checkRoles(['ROLE_PROJECTS_SAVE','ROLE_ADMIN'])"
                  >
                    Edytuj
                  </v-btn>
                  <RemoveButton @confirm="fetchDeleteTicket(item.selectable.id)"
                                v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_TICKETS_REMOVE'])">

                  </RemoveButton>
                </td>
              </tr>
            </template>

          </v-data-table>
        </v-card>
      </v-main>
    </v-layout>
  </div>
  <div v-else>
    <DenyAccessPage></DenyAccessPage>
  </div>
</template>
<script>

import {mapGetters, mapState} from "vuex";

import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import TicketDetail from "@/views/tickets/TicketDetail.vue";
import DenyAccessPage from "@/components/DenyAccessPage.vue";
import {useStorage} from "vue3-storage";
import ActionsButton from "@/components/navigation/ActionsButton.vue";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";


export default {
  name: 'TicketsView',
  components: {RemoveButton, ActionsButton,DenyAccessPage, TicketDetail, BreadcrumbsComponent},
  data: () => {
    return {
      rules: {
        required: value => !!value || 'Wymagane.',
        counter: value => value.length <= 20 || 'Max 20 znaków',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },

      title: '',
      dialogNewTicket: false,
      breadcrumbs: [
        {
          title: 'Zlecenia',
          disabled: true,
        },

      ],
      dialogTicket: [],
      dialog: false,
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Imię nazwisko', key: 'clientName', align: 'start'},
        {title: 'Data stworzenia', key: 'createdAt', align: 'start'},
        {title: 'Data modyfikacji', key: 'modifiedAt', align: 'start'},
        {title: 'Status', key: 'status', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      loading: false,
      totalItems: 10,
      itemId: null,
      items: [],

      features: {},
      selectedFeatures: [],
      isFormValid: false,
      ticketNameRules: [
        value => {
          if (value) return true
          return 'Wymagane.'
        },
        value => {
          if (value.length <= 255) return true
          return 'Maksymalna liczba znaków to 255.'
        },
      ],
    };
  },
  computed: {
    ...mapState(["tickets"]),
    ...mapGetters(["isLoggedIn", 'getToken', 'getRoles'])
  },
  methods: {
    status(status) {
      let color;
      let label;

      switch (status) {
        case "new":
          color = 'grey'
          label = 'Nowy'
          break;
        case "progress":
          color = 'blue'
          label = 'W toku'
          break;
        case "atCustomer":
          color = 'green'
          label = 'U klienta'
          break;
        case "annulled":
          color = 'grey'
          label = 'Anulowany'
          break;
        default:
          color = 'green'
          label = 'BRAK'
      }

      return {
        color: color,
        label: label
      }
    },

    async fetchAddTicket() {
        await this.$store.dispatch('addTicket', {
          title: this.title
        });
        this.itemId = this.$store.state.tickets.currentId;

      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      if (this.$store.state.users.responseStatus === 'success') {
        this.dialog = false;
      }

      this.dialogTicket[this.itemId] = true;
      this.dialogNewTicket = false;


    },

    async fetchDeleteTicket(idTicket) {
      await this.$store.dispatch('removeTicket', idTicket);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    taskDialogTicketClose() {
      ////console.logidTicket)
      ////console.logthis.dialogTicket[idTicket])
      this.dialogTicket = [];
    },
    checkRoles(roles) {
      let allRoles = this.$store.state.roles;
      if (allRoles === null) {
        allRoles = useStorage().getStorageSync("roles");
      }
      return roles.some(role => allRoles.includes(role));
    },
    formatDate(date) {
      // Format the date as needed
      return new Date(date).toLocaleDateString('pl-PL', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });
    },

  }
}
</script>
