<template>
  <template v-if="child.image !== undefined">
    <v-img
        :src="process.env.VUE_APP_API_LOCATION + '/' + child.image"
        class="my-3 border-1"
        contain
        height="60"

    ></v-img>
  </template>
  <template v-if="child.images  !== undefined">
    <v-img style="width:100px; float:left; " v-for="image in child.images" :key="image"
           :src="process.env.VUE_APP_API_LOCATION + '/'+image.name" height="60"></v-img>
  </template>
</template>
<script>
export default {
  name: 'ImagesView',
  props: {
    child: {}
  }
}
</script>
