import { createRouter, createWebHashHistory } from 'vue-router'

import UnitsView from "@/views/dictionary/units/UnitsView.vue";
import FeaturesView from "@/views/dictionary/features/FeaturesView.vue";
import CategoriesView from "@/views/dictionary/categories/CategoriesView.vue";
import VariablesView from "@/views/dictionary/variables/VariablesView.vue";
import TypesProjectView from "@/views/dictionary/typesProject/TypesProjectView.vue";

// #########################################################################
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import UsersView from "@/views/users/UsersView.vue";
import UserProfileView from "@/views/users/UserProfileView.vue";

import ProductsView from "@/views/products/ProductsView.vue";
import TicketsView from "@/views/tickets/TicketsView.vue";

import ProductsTreeView2 from "@/views/tree/ProductsTreeView2.vue";
import ProjectsView from "@/views/projects/ProjectsView.vue";
import ProjectsElementsView from "@/views/projects/ProjectsElementsView.vue";
import ProductsReadyView from "@/views/products/ProductsReadyView.vue";


import StatusView from "@/views/status/StatusView.vue";
import ValuationView from "@/views/tickets/ValuationView.vue";
import HistoryView from "@/views/HistoryView.vue";
import CustomersView from "@/views/customers/CustomersView.vue";
import NewPasswordView from "@/views/NewPasswordView.vue";



const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  {
    path: '/haslo',
    name: 'haslo',
    component: NewPasswordView
  },

  {
    path: '/users',
    name: 'users',
    component: UsersView
  },
  {
    path: '/rodzaje-projektow',
    name: 'types',
    component: TypesProjectView
  },
  {
    path: '/user/profile/:id*',
    name: 'userProfile',
    component: UserProfileView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  /* */

  {
    path: '/products/',
    name: 'products',
    component: ProductsView
  },

  {
    path: '/products/tree2/:id*',
    name: 'productsTree2',
    component: ProductsTreeView2
  },
  {
    path: '/products/ready',
    name: 'productsReady',
    component: ProductsReadyView
  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectsView
  },

  {
    path: '/projects/element',
    name: 'elements',
    component: ProjectsElementsView
  },
  {
    path: '/zlecenie/:id*',
    name: 'zlecenie',
    component: ValuationView
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: TicketsView
  },
  {
    path: '/categories',
    name: 'categories',
    component: CategoriesView
  },
  {
    path: '/features',
    name: 'features',
    component: FeaturesView
  },
  {
    path: '/units',
    name: 'units',
    component: UnitsView
  },
  {
    path: '/statuses',
    name: 'statuses',
    component: StatusView
  },
  {
    path: '/variables',
    name: 'variables',
    component: VariablesView
  },

  {
    path: '/history',
    name: 'history',
    component: HistoryView
  },

  {
    path: '/customers',
    name: 'customers',
    component: CustomersView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
