<template>
  <v-container fluid class="background-first">
    <div class="titlePage">
      <h1>Logowanie</h1>
    </div>
    <v-container>
      <v-row>
        <v-col cols="6" class="offset-3 ">
          <v-img
              :width="400"
              aspect-ratio="16/9"
              class="mt-3 me-auto"
              cover
              src="programigo.png"
          ></v-img>
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <v-col cols="5" class="offset-3 ">
          <h1 v-if="lostPassword === false">Zaloguj się</h1>
          <h1 v-if="lostPassword === true">Resetowanie hasła</h1>
          <v-card class="my-3">
            <v-form  v-if="lostPassword === false">

              {{ process }}

              <v-card-item>
                <v-text-field
                    prepend-icon="mdi-email"
                    name="email"
                    label="Email"
                    type="text"
                    v-model="email"
                    variant="plain"
                    :rules="emailRules"
                    style="margin-left: 10px; margin-top:5px;"
                ></v-text-field>
              </v-card-item>
              <v-divider></v-divider>
              <v-card-item>
                <v-text-field
                    id="password"
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Hasło"
                    type="password"
                    variant="plain"
                    style="margin-left: 10px; margin-top:5px;"
                    :rules="passwordRules"
                    v-model="password"
                ></v-text-field>
              </v-card-item>
               </v-form>
            <v-card-text v-if="lostPassword === true && lostPasswordNew === false">
              <v-form>
                <v-text-field
                    prepend-icon="mdi-email"
                    name="email"
                    label="Email"
                    type="text"
                    v-model="email"
                    variant="plain"
                    style="margin-left: 10px;"
                    :rules="emailRules"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <template v-if="lostPassword === true && lostPasswordNew === true">
              <v-form>
                <v-card-text>
                  <v-text-field
                      prepend-icon="mdi-email"
                      name="pass1"
                      label="Hasło"
                      type="text"
                      v-model="pass1"
                      variant="plain"
                      style="margin-left: 10px;"
                  ></v-text-field>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text>
                  <v-text-field
                      prepend-icon="mdi-email"
                      name="pass1"
                      label="Powtórz hasło"
                      type="text"
                      v-model="pass2"
                      variant="plain"
                      style="margin-left: 10px;"
                  ></v-text-field>
                </v-card-text>
              </v-form>
            </template>
            <v-divider></v-divider>
            <v-card-text v-if="errorForm !== ''">
              <v-alert :text="errorForm" type="error"></v-alert>
            </v-card-text>
            <v-card-text v-if="errorPasswordReset !== ''">
              <v-alert :text="errorPasswordReset" type="error"></v-alert>
            </v-card-text>
            <v-card-text class="text-end" v-if="lostPassword === false">
              <v-btn color="success"
                     prepend-icon="mdi-content-save-check"
                     variant="elevated" size="small" @click="login">Zaloguj
              </v-btn>
            </v-card-text>
            <v-card-text class="text-end" v-if="lostPassword === true && lostPasswordNew === false">
              <v-btn color="success"
                     prepend-icon="mdi-send"
                     variant="elevated" size="small" @click="remindPassword">Resetuj hasło
              </v-btn>
            </v-card-text>
            <v-card-text class="text-end" v-if="lostPassword === true && lostPasswordNew === true">
              <v-btn color="success"
                     prepend-icon="mdi-send"
                     variant="elevated" size="small" @click="remindPassword">Ustaw nowe hasło
              </v-btn>
            </v-card-text>
          </v-card>
          <v-btn variant="text" v-if="lostPassword === false"
                 class="text-none" @click="lostPassword = !lostPassword">Zapomniałeś hasła?</v-btn>
          <br>
          <v-btn variant="text" class="text-none" v-if="lostPassword === true" @click="lostPassword = !lostPassword">Zaloguj się!</v-btn>
        </v-col>
      </v-row>

    </v-container>
  </v-container>
<!--  <v-container text-center>
    <v-row justify="center" align="center" class="h-100">
      <v-col cols="4" sm="12" lg="4" align-self="center">
        <v-card>
          <v-card-title class="text-center">
            <v-img
                :width="300"
                aspect-ratio="16/9"
                class="mt-3 mx-auto"
                cover
                src="programigo.png"
            ></v-img>
          </v-card-title>
          <v-divider class="my-5"></v-divider>
          <v-card-text v-if="lostPassword === false">
            <v-form>
              <v-text-field
                  prepend-icon="mdi-email"
                  name="email"
                  label="Email"
                  type="text"
                  v-model="email"
                  variant="outlined"
                  :rules="emailRules"
              ></v-text-field>
              <v-text-field
                  id="password"
                  prepend-icon="mdi-lock"
                  name="password"
                  label="Password"
                  type="password"
                  variant="outlined"
                  :rules="passwordRules"
                  v-model="password"
              ></v-text-field>
              <v-row>
                <v-col>
                  <v-checkbox label="Zapamiętaj mnie" hide-details></v-checkbox>
                </v-col>
                <v-col class="text-end">
                  <v-btn variant="flat" class="mt-3" @click="lostPassword = !lostPassword">Zapomniałeś hasła ?</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-text v-if="lostPassword === true">
            <v-form>
              <v-text-field
                  prepend-icon="mdi-email"
                  name="email"
                  label="Email"
                  type="text"
                  v-model="email"
                  :rules="emailRules"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-text v-if="errorForm !== ''">
            <v-alert :text="errorForm" type="error"></v-alert>
          </v-card-text>
          <v-card-text class="text-center" v-if="lostPassword === false">
            <v-btn color="primary" block class="mx-auto" variant="elevated" size="x-large" @click="login">Zaloguj
            </v-btn>
          </v-card-text>
          <v-card-text class="text-center" v-if="lostPassword === true">
            <v-btn color="primary" block class="mx-auto" variant="elevated" size="x-large" @click="remindPassword">Przypomnij hasło
            </v-btn>
            <v-btn variant="flat" class="mt-3" @click="lostPassword = !lostPassword">Zaloguj się</v-btn>
          </v-card-text>
          <v-divider class="mt-5"></v-divider>
          <v-card-text class="text-center">
            Nie masz jeszcze konta?
            <v-btn color="secondary" @click="login" class="mx-5">Zarejestruj się</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>-->

</template>
<script>
import {mapMutations} from "vuex";
import axios from "axios";

export default {
  data: () => {
    return {
      options: {
        isLoggingIn: true,
        shouldStayLoggedIn: true,
      },
      lostPassword: false,
      errorForm: "",
      errorPasswordReset: "",
      email: "",
      emailRules: [
        value => {
          if (value) return true

          return 'E-mail jest wymagany.'
        },
        value => {
          if (/.+@.+\..+/.test(value)) return true

          return 'E-mail musi być prawidłowy'
        },
      ],
      password: "",
      lostPasswordNew: false,
      passwordRules: [
        value => {
          if (value) return true
          return 'Hasło jest wymagane.'
        },
      ],

    };
  },
  mounted() {
    console.log('API_LOCATION:', process.env.VUE_APP_API_LOCATION);
  },
  watch: {
    lostPassword(){
      this.errorForm = ""
      this.errorPasswordReset= ""
    }
  },
  methods: {
    ...mapMutations(["setToken", "setUser", "setRoles", "setIdUser", "logout"]),
    async login(e) {
      e.preventDefault();
      const response = await fetch(process.env.VUE_APP_API_LOCATION + "/auth", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email,
          password: this.password,
        }),
      });

      if (response.status === 401) {
        this.errorForm = "Nieprawidłowy adres email lub hasło";
        setTimeout(() => {
          this.errorForm = "";
        }, 3000);
      } else {
        const {token} = await response.json();
        this.setToken(token);
        this.setUser(this.email);


        await axios.get(process.env.VUE_APP_API_LOCATION + "/api/current-user",{
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
            .then(response => {
              this.setRoles(response.data.roles);
              this.setIdUser(response.data.id);
            })
            .catch(() => {
             //////console.logerror);
              this.logout;
            });


      }
    },
    remindPassword(){
      this.errorPasswordReset="";

      axios.post(process.env.VUE_APP_API_LOCATION + '/api/remind-password',{
        email: this.email
      },)
          .then(response => {

            if(response.data.error)
            {
              this.errorPasswordReset = response.data.error;
            }else {
              this.$notify({
                title: response.data,
                type: 'success',
              });
            }
          })
          .catch(error => {
            //console.error(error);
            this.$notify({
              title: error.response.data.detail,
              type: 'error',
            });
          });
    }
  },
};
</script>
<style>
.login-form {
  max-width: 500px;
}

.background-first {
  background-color: #f2f2f3;
}

.background-first:before {
  content: '';
  display: block;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 415px;
  background: url('../../public/right.svg') no-repeat;
  background-position: right bottom;
}

.titlePage{
  position:fixed;
  right: 180px;
  bottom: 283px;
  color:#fff;
  text-transform:uppercase;
}


</style>
