import {createStore} from 'vuex'
import {useStorage} from "vue3-storage";
import {projects} from "./modules/projects";
import {categories} from "./modules/categories";
import {products} from "@/store/modules/products";
import {users} from "@/store/modules/users";
import {units} from "@/store/modules/units";
import {features} from "@/store/modules/features";
import {projectsElements} from "@/store/modules/projectsElements";
import {variables} from "@/store/modules/variables";
import {tickets} from "@/store/modules/tickets";
import {statuses} from "@/store/modules/status";
import {types} from "@/store/modules/types";
import {productStatus} from "@/store/modules/productStatus";
import {histories} from "@/store/modules/history";
import {customers} from "@/store/modules/customers";

export default createStore({
    state: {
        token: null,
        user: null,
        roles: null,
        role: null,
        idUser: null,
        networkError: false,
    },
    getters: {
        isLoggedIn(state) {
            const storage = useStorage();
            const token = storage.getStorageSync("token");
            state.token = token;
            return !!state.token;
        },
        getToken(state) {
            const storage = useStorage();
            const token = storage.getStorageSync("token");
            return state.token = token;
        },
        getUser(state) {
            const storage = useStorage();
            const user = storage.getStorageSync("user");
            return state.user = user;
        },
        getIdUser(state) {
            const storage = useStorage();
            const id = storage.getStorageSync("idUser");
            return state.idUser = id;
        },
        getRoles(state) {
            const storage = useStorage();
            const user = storage.getStorageSync("roles");
            return state.roles = user;
        },
    },
    mutations: {
        setNetworkError(state, value) {
            state.networkError = value;
            const storage = useStorage();
            storage.removeStorageSync('token');
            storage.removeStorageSync('user');
            storage.removeStorageSync('roles');
            storage.removeStorageSync('idUser');
            state.token = null;
            state.user = null;
            state.roles = null;
            state.idUser = null;
            state.token = null;
        },
        logout(state) {
            ////console.log'Usuwamy')
            const storage = useStorage();
            storage.removeStorageSync('token');
            storage.removeStorageSync('user');
            storage.removeStorageSync('roles');
            storage.removeStorageSync('idUser');
            state.token = null;
            state.user = null;
            state.roles = null;
            state.idUser = null;
            state.token = null;
            return state.token;
        },
        setToken(state, token) {
            const storage = useStorage();
            storage.setStorage({
                key: "token",
                data: token
            })
            state.token = token;
        },
        setUser(state, user) {
            const storage = useStorage();
            storage.setStorage({
                key: "user",
                data: user
               // data: Math.random()
            })
            state.user = user;
        },
        setRoles(state, roles) {
            const storage = useStorage();
            storage.setStorage({
                key: "roles",
                data: roles
            })
            state.roles = roles;
        },
        setIdUser(state, id) {

            console.log('setIdUser',id);

            const storage = useStorage();
            storage.setStorage({
                key: "idUser",
                data: id
            })
            state.idUser = id;
        },
        checkRoles(roles) {
            let allRoles = this.$store.state.roles;
            if (allRoles === null) {
                allRoles = useStorage().getStorageSync("roles");
            }
            return roles.some(role => allRoles.includes(role));
        },
    },
    actions: {
    },
    modules: {
        users,
        units,
        features,
        types,
        projects,
        projectsElements,
        //buildProducts,
        readyProducts: products,
        categories,
        variables,
        histories,
        tickets,
        statuses,
        customers,
        productStatus
    }
})
