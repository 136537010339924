<template>
    <v-btn
        color="primary"
        size="x-small"
        style="float: right; margin: 5px; margin-right: -50px; transform: rotate(90deg);"
        @click="openDialog($props.branch)"
    >
      <span class="mdi mdi-table-large"></span>
      Tabela
    </v-btn>
    <v-dialog
        v-model="dialog[$props.branch.id]"
        fullscreen
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="2">
              <v-select
                  density="compact"
                  hide-details
                  label="Rodzaj"
                  class="mb-3"
                  v-model="branchType"
                  :items="typeList"
                  item-title="label"
                  item-value="value"
              ></v-select>
              <v-select v-if="branchType !== 'category'"
                        density="compact"
                        hide-details
                        label="Kategoria"
                        class="mb-3"
                        :items="categories"
                        item-title="title"
                        item-value="id"
                        v-model="branchCategory"
              ></v-select>
              <v-text-field hide-details density="compact" label="Nazwa" class="mb-3"
                            v-model="branchLabel"
                            :disabled="!branchType"
              ></v-text-field>
              <v-divider class="mb-3"></v-divider>
              <template v-if="branchCategory && branchType === 'ready'">
                <v-autocomplete
                    density="compact"
                    label="Produkt"
                    variant="outlined"
                    class="x-small"
                    :items="readyProductsFromCategory[branchCategory]"
                    item-value="id"
                    item-title="title"
                    @update:modelValue="updateProduct($event, thisBranch.id)"
                    :modelValue="product"
                ></v-autocomplete>
              </template>
              <FeaturesView
                  v-if="branchType !== 'category'"
                  :id-branch="thisBranch.id"
                  :branch="thisBranch"
                  :features="thisBranch.features"
                  :chips="chips"
                  :chips-math="chipsMath"
                  :math="math"
                  @update:chips="(featureId, value) => $emit('update:chips',value,featureId,thisBranch.id)"
                  @update:newChips="(value) => $emit('update:newChips',value, thisBranch.id)"
                  @update:newChipsMath="(value) => $emit('update:newChipsMath',value, thisBranch.id)"
                  @update:math="(value, featureId) => $emit( 'update:math' ,value, featureId, thisBranch.id)"
                  @update:product="(event) => $emit( 'update:product' ,event, thisBranch.id)"
                  @update:addchip="(sF1, sF2, title,value) => $emit( 'update:addchip' ,sF1,sF2,title, value)"
              ></FeaturesView>
            </v-col>
            <v-col cols="10">
              <template v-if="branchType !== 'ready'">
                <div class="ml-0 w-100 mb-2 text-center">
                  <p class="text-h4 pb-3">{{ branchLabel }}</p>
                  <v-divider></v-divider>
                </div>
                <v-card-actions class="justify-space-between mb-3">
                  <AnnulledButton v-if="thisBranch.parentId" label="Powrót" icon="mdi-arrow-left"
                                  @click="getBranchById($props.tree,thisBranch.parentId)"></AnnulledButton>
                  <SaveButton label="Dodaj" icon="mdi-plus" @click="newBranch"></SaveButton>
                </v-card-actions>
                <v-text-field
                    v-model="search"
                    label="Szukaj"
                    prepend-inner-icon="mdi-magnify"
                    single-line
                    variant="outlined"
                    hide-details
                    density="compact"
                ></v-text-field>
                <v-data-table
                    v-if="branchChildren !== undefined"
                    :headers="headers"
                    :items="branchChildren ?? []"
                    :search="search"
                    density="compact"
                >
                  <template v-slot:headers="{}">
                    <tr>
                      <th>Kategoria</th>
                      <th>Nazwa</th>
                      <th>Cena</th>
                      <th>Cena J</th>
                      <th align="end">
                      </th>
                    </tr>
                  </template>
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ getCategoryById(item.selectable.categories)?.title }}</td>
                      <td>{{ item.selectable.label }}</td>
                      <td>{{ getReadyProductById(item.selectable?.product)?.price }}</td>
                      <td>{{ getReadyProductById(item.selectable?.product)?.priceUnit }}</td>
                      <td align="end">
                        <SaveButton label="Przejdź" size="x-small" class="mx-1" icon="mdi-page-next-outline"
                                    @click="getBranchById($props.tree,item.selectable.id)"></SaveButton>
                        <RemoveButton @confirm="deleteNode(item.selectable,$props.tree)"></RemoveButton>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </template>
              <template v-if="branchType === 'ready' && getReadyProductById(product)">
                <v-card-title>
                  {{ getReadyProductById(product)?.title }}
                  <v-divider></v-divider>
                </v-card-title>

                <v-card-actions class="justify-space-between">
                  <AnnulledButton v-if="thisBranch.parentId" label="Powrót" icon="mdi-arrow-left"
                                  @click="getBranchById($props.tree,thisBranch.parentId)"></AnnulledButton>
                </v-card-actions>
                <v-card-subtitle>
                  Parametry:
                </v-card-subtitle>
                <v-card-item>
                  <v-table density="compact" border="1"
                  >
                    <tbody>
                    <tr>
                      <th>Cena:</th>
                      <td>{{ getReadyProductById(product).price }} <span
                          v-if="getReadyProductById(product).currency!=''"> {{ getReadyProductById(product).currency }}</span></td>
                    </tr>
                    <tr>
                      <th>Opis:</th>
                      <td>{{ getReadyProductById(product).description }}</td>
                    </tr>
                    <tr>
                      <th>Kategoria:</th>
                      <td>{{ getReadyProductById(product).category?.title }}</td>
                    </tr>
                    <tr>
                      <th>Cena:</th>
                      <td>{{ getReadyProductById(product).price }} <span
                          v-if="getReadyProductById(product).currency!=''"> {{ getReadyProductById(product).currency }}</span></td>
                    </tr>
                    <tr>
                      <th>Zdjęcia:</th>
                      <td>
                        <div v-if="getReadyProductById(product).images">
                          <v-img style="width:50px; float:left; " v-for="image in getReadyProductById(product).images" :key="image"
                                 :src="process.env.VUE_APP_API_LOCATION + '/'+image.name"></v-img>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </v-table>
                </v-card-item>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
        <ActionsButton @annulled="dialog[$props.branch.id] = false"
                       @save="dialog[$props.branch.id] = false"></ActionsButton>
      </v-card>
    </v-dialog>
</template>

<script>
import ActionsButton from "@/components/navigation/ActionsButton.vue";
import SaveButton from "@/components/navigation/element/SaveButton.vue";
import AnnulledButton from "@/components/navigation/element/AnnulledButton.vue";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";
import {mapGetters, mapState} from "vuex";
import FeaturesView from "@/views/tree/components/FeaturesView.vue";

export default {
  name: "BranchDetail",
  props: ['branch', 'tree', 'chips', 'chipsMath', 'math'],
  components: {FeaturesView, RemoveButton, AnnulledButton, SaveButton, ActionsButton},
  data() {
    return {
      //Types
      typeList: [
        {
          label: "Wirtualny", value: "category"
        },
        {
          label: "Własny", value: "build"
        },
        {
          label: "Handlowy", value: "ready"
        }
      ],

      //Table
      search: '',
      headers: [
        {key: 'name', title: 'Nazwa'},
        {key: 'type', title: 'Rodzaj'},
        {key: 'price', title: 'Cena'},
        {key: 'unitPrice', title: 'Cena J'},
        {key: 'action', title: ''},
      ],

      // Default
      dialog: [],
      categories: null,
      thisBranch: null,
      branchLabel: null,
      branchType: null,
      branchChildren: null,
      branchCategory: null,
      product: null,
      readyProductsFromCategory: []
    }
  },
  computed: {
    ...mapGetters([
      'getCategoriesOwn',
      'getCategoriesCommercial',
      'getCategoryById',
      'getReadyProductById'
    ]),
    ...mapState([
      'readyProducts'
    ])
  },
  watch: {
    branchLabel(newValue) {
      this.changeNodeProperty(this.$props.tree, this.thisBranch.id, "label", newValue);
    },
    branchType(newValue) {
      this.changeNodeProperty(this.$props.tree, this.thisBranch.id, "type", newValue);
      if (newValue === 'build') {
        this.categories = this.getCategoriesOwn;
      } else {
        this.categories = this.getCategoriesCommercial;
      }
    },
    branchChildren(newValue) {
      this.changeNodeProperty(this.$props.tree, this.thisBranch.id, "children", newValue);
    },
    branchCategory(newValue) {
      const category = this.getCategoryById(newValue);
      if (category && category.features) {
        this.thisBranch.features = category.features;
        this.getProductsFromCategory(category.id);
      }

      this.changeNodeProperty(this.$props.tree, this.thisBranch.id, "features", this.thisBranch.features);
      this.changeNodeProperty(this.$props.tree, this.thisBranch.id, "categories", newValue);
    },

  },
  mounted() {
    //console.log(this.$props.branch);
    this.changeBranch(this.$props.branch);
    if (this.$props.branch.type === 'build') {
      this.categories = this.getCategoriesOwn;
    } else {
      this.categories = this.getCategoriesCommercial;
    }
    this.product = this.$props.branch.product;
  },
  beforeUnmount() {
    this.thisBranch = null;
    this.branchLabel = null;
    this.branchType = null;
    this.branchChildren = null;
    this.branchCategory = null;
  },
  methods: {

    updateProduct(event, branchId){

      this.product = event;
      console.log(event,branchId);

      this.$emit('update:product',event, branchId);
    },

    branchFeatures() {

    },
    openDialog(branch) {
      this.dialog[branch.id] = true
      this.changeBranch(branch)
    },
    getBranchById(tree, idBranch) {
      if (Number(tree.id) === Number(idBranch)) {

        return tree;
      } else if (tree.children != null) {
        var i;
        var result = null;
        for (i = 0; result == null && i < tree.children.length; i++) {
          result = this.getBranchById(tree.children[i], idBranch);
        }
        this.changeBranch(result);
      }
      return null;
    },
    changeBranch(branch) {
      if (branch !== null) {
        this.thisBranch = branch;
        this.branchLabel = branch.label ?? '';
        this.branchType = branch.type;

        if (branch.type === 'build') {
          this.categories = this.getCategoriesOwn;
        } else {
          this.categories = this.getCategoriesCommercial;
        }

        this.getProductsFromCategory(branch.categories)
        this.product = branch.product;

        this.branchChildren = branch.children ?? [];
        this.branchCategory = branch.categories;
      }
    },

    changeNodeProperty(tree, targetId, propertyToChange, newValue, key = null, event = null) {

      function traverse(node) {
        if (node.id === targetId) {
          if (propertyToChange === 'features') {

            if (newValue) {
              let indexFeature = node[propertyToChange].findIndex((i) => i.id === newValue.id)
              //console.log(event, key, indexFeature)
              if (key === 'eye') {
                if (event === null) {
                  event = false;
                }
                node[propertyToChange][indexFeature][key] = !event;
              }
              let vv;
              if (key === 'input') {
                if (event.target.value === 'true') {
                  vv = true;
                }
                node[propertyToChange][indexFeature][key] = !vv;
              }
            }

          } else {
            node[propertyToChange] = newValue;
          }
        }
        if (node.children) {
          // Przejdź przez wszystkie dzieci węzła
          node.children.forEach(traverse);
        }
      }

      traverse(tree);
      return tree;
    },


    newBranch() {
      this.findHighestID();
      let id = this.highestID + 1;
      this.branchChildren.push({
        id: id,
        parentId: this.thisBranch.id,
        expand: true
      })
    },
    recursiveSearch(element) {
      if (element.id > this.highestID) {
        this.highestID = element.id;
      }

      if (Array.isArray(element.children)) {
        element.children.forEach((child) => {
          this.recursiveSearch(child);
        });
      }
    },
    findHighestID() {
      this.highestID = 0;
      this.recursiveSearch(this.$props.tree);
    },
    deleteNode(node, tree) {
      let parent = tree.children ? tree.children.find(p => p.id === node.id) : null;
      if (parent) {
        tree.children.splice(tree.children.indexOf(node), 1)
      } else if (tree.children) {
        tree.children.forEach(ch => this.deleteNode(node, ch))
      }
    },
    getUnitLabel() {

    },
    getProductsFromCategory(idCategory) {
      this.readyProductsFromCategory[idCategory] = [];
      this.readyProducts.readyProducts.filter((product) => {
        if (idCategory === product.category.id) {
          this.readyProductsFromCategory[idCategory].push({
            'id': product.id,
            'title': product.title,
          });
        }
      });
    },
  },

}
</script>

<style scoped>

</style>
