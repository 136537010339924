<template>
  <div v-if="$props.features && $props.features.length > 0">
    <div class="v-sheet v-sheet--border v-sheet--rounded overflow-hidden pa-2">
      <template v-for="(item) in $props.features"
                :key="item.id">
        <template v-if="item.title !== undefined">
          <v-row>
            <v-col cols="6" class="pb-0 text-body-2">{{ item.title }} [{{item.id}}]</v-col>
            <v-col class="pb-0">
              <template v-for="feature in $props.features" :key="feature.id">
                <template v-if="feature.id === item.id">{{ feature.value }} {{ getUnitById(valMath(item.id)?.unit) }}</template>
                <template v-if="$props.chipsMath">
                  <template
                      v-if="$props.chipsMath[$props.idBranch] !== undefined && $props.chipsMath[$props.idBranch] !== null">
                    <v-tooltip
                        v-if="$props.chipsMath[$props.idBranch][item.id] !== undefined && $props.chipsMath[$props.idBranch] !== null"
                        activator="parent"
                        location="top"
                    >{{ $props.chipsMath[$props.idBranch][item.id] ?? '' }}
                    </v-tooltip>
                  </template>
                </template>
              </template>
            </v-col>
          </v-row>
          <v-row class="featureClass">
            <v-col cols="auto" class="pb-0 pe-0">
              <v-btn :icon="valMath(item.id).eye === true ? 'mdi-eye' : 'mdi-eye-off'"
                     color="secondary" size="x-small"
                     class="d-inline"
                     @click="valMath(item.id).eye = !valMath(item.id).eye"
              ></v-btn>
            </v-col>
            <v-col cols="auto" class="pb-0 px-0">
              <v-checkbox hide-details density="compact"
                          @click="valMath(item.id).input = !valMath(item.id).input"
                          :modelValue="valMath(item.id).input"
                          class="d-inline"
              >
                <v-tooltip activator="parent" location="start">
                  Sprzedawca może wprowadzać tą wartość
                </v-tooltip>
              </v-checkbox>
            </v-col>
            <v-col cols="auto" class="pb-0 px-0">
              <v-select
                  variant="outlined"
                  density="compact"
                  hide-details
                  :items="item.units"
                  item-value="id"
                  @update:modelValue="valMath(item.id).unit = $event"
                  :modelValue="valMath(item.id).unit"
                  class="x-small d-inline-block rounded-0"
                  style="height: 100%;"
              ></v-select>
            </v-col>
            <v-col class="pb-0 ps-0">
              <v-text-field
                  class="x-small rounded-s-0"
                  density="compact"
                  hide-details
                  variant="outlined"
                  @change="emitChips(item.id)"
                  v-model="newChips[item.id]"
                  :modelValue="item.chip"
                  @contextmenu="show($event, $props.idBranch, item.id)">
              </v-text-field>

              {{$props.idBranch }} {{ item.id}}

              <v-menu
                  @change="showMenu($props.idBranch, item.id)"
                  :modelValue="getMenu($props.idBranch, item.id)"
                  :style="'left:' + x + 'px; top:' + y + 'px;'"
                  abolute
                  offset-y
                  :close-on-content-click="false"

              >
                <v-list class="elevation-2">
                  <v-list-group value="Produkty">
                    <template v-slot:activator="{ props }">
                      <v-list-item
                          v-bind="props"
                          title="Produkt"
                      ></v-list-item>
                    </template>
                    <v-list-group value="Ten element">
                      <template v-slot:activator="{ props }">
                        <v-list-item
                            v-bind="props"
                            title="Ten element"
                        ></v-list-item>
                      </template>
                      <template v-for="feature in $props.features" :key="feature.id">
                        <v-list-item>
                          <v-list-item-title
                              @click="addChip(sF1,sF2,feature.title,currentBranch.id +'_'+ feature.id +'_W')">
                            {{ feature.title }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-list-item>
                        <v-list-item-title
                            @click="addChip(sF1,sF2,'Cena',currentBranch.id +'_??_C')">
                          Cena
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title
                            @click="addChip(sF1,sF2,'Cena jednostki', currentBranch.id+'_??_J')">
                          Cena jednostki
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-group>

                    <template v-for="(item, index) in currentBranch.children" :key="index">
                      <v-list-group :value="item.label" no-action>
                        <template v-slot:activator="{ props }">
                          <v-list-item
                              v-bind="props"
                              :title="item.label"
                          ></v-list-item>
                        </template>
                        <v-list-item v-for="feature in item.features" :key="feature.id">
                          <v-list-item-title
                              @click="addChip(sF1,sF2,item.label + ' - ' + feature.title,item.id +'_'+ feature.id +'_W')">
                            {{ feature.title }} [{{item.id}} , {{feature.id}}]
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title
                              @click="addChip(sF1,sF2,item.label + ' - Cena',item.id +'_??_C')">
                            Cena
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title
                              @click="addChip(sF1,sF2,item.label + ' - Cena jednostki', item.id +'_??_J')">
                            Cena jednostki
                          </v-list-item-title>
                        </v-list-item>

                        <template v-for="(item, index) in item.children" :key="index">
                          <v-list-group :value="item.label" no-action>
                            <template v-slot:activator="{ props }">
                              <v-list-item
                                  v-bind="props"
                                  :title="item.label"
                              ></v-list-item>
                            </template>
                            <v-list-item v-for="feature in item.features"
                                         :key="feature.id">
                              <v-list-item-title
                                  @click="addChip(sF1,sF2,item.label + ' - ' + feature.label,item.id +'_'+ feature.id +'_W')">
                                {{
                                  item.label
                                }} - {{ feature.label }}
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title
                                  @click="addChip(sF1,sF2,item.label + ' - Cena',item.id +'_??_C')">
                                {{ item.label }} Cena
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title
                                  @click="addChip(sF1,sF2,item.label + ' - Cena jednostki', item.id +'_??_J')">
                                {{ item.label }} Cena
                                jednostki
                              </v-list-item-title>
                            </v-list-item>
                            <template v-for="(item, index) in item.children"
                                      :key="index">
                              <v-list-group :value="item.label" no-action>
                                <template v-slot:activator="{ props }">
                                  <v-list-item
                                      v-bind="props"
                                      :title="item.label"
                                  ></v-list-item>
                                </template>
                                <v-list-item v-for="feature in item.features"
                                             :key="feature.id">
                                  <v-list-item-title
                                      @click="addChip(sF1,sF2,item.label + ' - ' + feature.label,item.id +'_'+ feature.id +'_W')">
                                    {{
                                      item.label
                                    }} - {{ feature.label }}
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                  <v-list-item-title
                                      @click="addChip(sF1,sF2,item.label + ' - Cena',item.id +'_??_C')">
                                    Cena
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                  <v-list-item-title
                                      @click="addChip(sF1,sF2,item.label + ' - Cena jednostki', item.id +'_??_J')">
                                    Cena jednostki
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list-group>
                            </template>
                          </v-list-group>
                        </template>
                      </v-list-group>
                    </template>
                  </v-list-group>
                  <v-list-group value="Zmienne">
                    <template v-slot:activator="{ props }">
                      <v-list-item
                          v-bind="props"
                          title="Zmienne"
                      ></v-list-item>
                    </template>
                    <template v-for="variable in variables.variables"
                              :key="variable.id">
                      <v-list-item>
                        <v-list-item-title
                            @click="addChip(sF1,sF2,variable.title,'Z_' + variable.id)">
                          {{ variable.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list-group>
                  <v-list-group value="Grupowe" v-if="currentBranch.children">
                    <template v-slot:activator="{ props }">
                      <v-list-item
                          v-bind="props"
                          title="Grupowe"
                      ></v-list-item>
                    </template>

                    <v-list-group value="Suma" v-if="currentBranch.children">
                      <template v-slot:activator="{ props }">
                        <v-list-item
                            v-bind="props"
                            title="Suma"
                        ></v-list-item>
                      </template>

                      <template v-for="feature in currentBranch.features" :key="feature.id">
                        <v-list-item>
                          <v-list-item-title
                              @click="addChip(sF1,sF2,'Suma: ' + feature.title,'00_'+ feature.id +'_W')">
                            {{ feature.title }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>

                      <v-list-item>
                        <v-list-item-title
                            @click="addChip( sF1,sF2,'Suma - Cena jednostki', '00_00_J')">
                          Cena Jednostki
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title
                            @click="addChip(sF1,sF2,'Suma - Cena', '00_00_C')">
                          Cena
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-group>

                    <v-list-group value="Wybrana gałąź" v-if="currentBranch.children">
                      <template v-slot:activator="{ props }">
                        <v-list-item
                            v-bind="props"
                            title="Wybrana gałąź"
                        ></v-list-item>
                      </template>

                      <template v-for="feature in currentBranch.features" :key="feature.id">
                        <v-list-item>
                          <v-list-item-title
                              @click="addChip(sF1,sF2,'Wybrany: ' + feature.title,'??_'+ feature.id +'_W')">
                            {{ feature.title }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>

                      <v-list-item v-if="currentBranch.oneChildren">
                        <v-list-item-title
                            @click="addChip(sF1,sF2,'Wybrany: Cena','??_??_C')">
                          Cena
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="currentBranch.oneChildren">
                        <v-list-item-title
                            @click="addChip(sF1,sF2,'Wybrany: Cena jednostki', '??_??_J')">
                          Cena jednostki
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-group>
                  </v-list-group>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>

          <v-row v-if="$props.chipsMath && $props.chipsMath[this.idBranch]">
            <v-col cols="12" class="pt-0" v-if="$props.chipsMath[this.idBranch][item.id]">
              <template v-if="$props.chips[this.idBranch][item.id]">

                <!--
                              {{$props.chipsMath[$props.idBranch][item.id]}}
                              {{$props.chips[$props.idBranch][item.id]}}

                -->

                <v-layout column>
                <v-flex v-for="(chip, index) in $props.chips[$props.idBranch][item.id]" :key="index">
                  <v-chip size="x-small" class="drag-element" draggable="true" closable
                          @click:close="remove(item.id, index)"
                          @dragstart="dragStart(index)"
                          @dragover="dragOver(index)"
                          @dragend="drop(index, $props.idBranch, item.id)"

                  >
                    {{ chip }}
                  </v-chip>
                </v-flex>
                </v-layout>

              </template>
            </v-col>
          </v-row>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import {addMath, rozdzielDzialanie} from "@/views/tree/js/main";
import {mapGetters, mapState} from "vuex";

export default {
  name: "FeaturesView",
  props: ['idBranch', 'features', 'chips', 'chipsMath', 'branch','math'],
  data: () => {
    return {
      newChips: [],
      menu: [],
      sF1: null,
      sF2: null,
      currentBranch: null,

      dragIndex: null,
      dropIndex: null,

      feature: []
    }
  },
  computed: {
    ...mapState(['variables']),
    ...mapGetters(['getUnitById'])
  },
  mounted() {
    this.currentBranch = this.$props.branch;
  },
  watch:{
    feature(n,o){
      console.log(n,o);
    }
  },
  methods: {
    valMath(idFeature){
      let math = this.$props.math.find((m) => Number(m.idTree) === Number(this.$props.branch.id) && Number(m.feature) === Number(idFeature))
      if(math) {
        if (math.eye === undefined) math.eye = true;
        if (math.unit === undefined) math.unit = null;
        if (math.input === undefined) math.input = false;
      }else{
        const newobject = {
          feature: Number(idFeature),
          idTree: Number(this.$props.branch.id),
          math: '',
          type: 'build',
          value: 0,
          eye: true,
          unit: null,
          input: false,
        }
        this.$props.math.push(newobject);
      }


      return math;
    },
    dragStart(index){
      this.dragIndex = index;
      console.log('dragStart',this.dragIndex,this.dropIndex)
    },
    dragOver(index){
      this.dropIndex = index;
    },
    drop(index,idProduct,idFeature){

      let array = this.$props.chips[idProduct][idFeature];
      const temp = array[this.dragIndex];
      console.log('temp',temp, this.dragIndex, this.dropIndex)
      array.splice(this.dragIndex, 1);  // Usuń liczbę 4 ze starej pozycji
      array.splice(this.dropIndex, 0, temp);

      let array2 = this.$props.chipsMath[idProduct][idFeature];
      const temp2 = array2[this.dragIndex];
      console.log('temp2',temp2, this.dragIndex, this.dropIndex)
      array2.splice(this.dragIndex, 1);  // Usuń liczbę 4 ze starej pozycji
      array2.splice(this.dropIndex, 0, temp2);

    },
   /* overDrop(index, idProduct, idFeature){
      let array2 = this.$props.chips[idProduct][idFeature];
      const temp2 = array2[this.dragIndex];
      array2.splice(this.dragIndex, 1);  // Usuń liczbę 4 ze starej pozycji
      array2.splice(this.dropIndex, 0, temp2);
    },

    onDragStart(index) {
      event.dataTransfer.setData('text/plain', index);
    },*/
    change(e, idProduct, idFeature) {
      let array = this.$props.chipsMath[idProduct][idFeature];
      const temp = array[e.moved.oldIndex];
      array.splice(e.moved.oldIndex, 1);  // Usuń liczbę 4 ze starej pozycji
      array.splice(e.moved.newIndex, 0, temp);

      let array2 = this.$props.chips[idProduct][idFeature];
      const temp2 = array2[this.dragIndex];
      array2.splice(this.dragIndex, 1);  // Usuń liczbę 4 ze starej pozycji
      array2.splice(this.dropIndex, 0, temp2);

      if (this.canBeMathCounting(array) === true) {
        this.$props.chipsMath[this.idBranch][idFeature] = array;
        addMath.call(this.$props.chipsMath, this.$props.chipsMath, this.idBranch, idFeature);
      }
    },
    canBeMathCounting(array) {
      if (typeof array == "string") {
        array = rozdzielDzialanie(array);
      }
      //console.log("działanie", array);
      ////console.log"array", array);
      //funkcja sprawdza, czy zadanie matematyczne może być obliczane
      let mathMarks = ["+", "-", "*", "/"];
      //sprawdzanie czy element to liczba
      // poniższy warunek musi być wyłączony bo nie możemy oczekiwać liczby w pierwszym i elemencie ponieważ
      // jeśli pierwszy element działania to np. kwota z dziecka wówczas w tablicy nie mamy liczby, tylko oznaczenie systemowe

      ///////////////////////////////////////////////////////////////////////////

      //usuwamy z tablicy wszystkie puste elementy
      for (let i = 1; i < array.length; ++i) {
        if (array[i] === "" || array[i] === " " || array[i] === undefined) {
          array.splice(i, 1);
        }
      }
      //console.log("działanie poczyszczeniu", array);
      //weryfikacja poprawności działania

      if (!mathMarks.includes(array[0]) && !mathMarks.includes(array[array.length - 1])) {
        //zarówno pierwszy jak i ostatni element działanie to liczba
        let isPreviusNumber = true;
        for (let i = 1; i < array.length; ++i) {

          if (isPreviusNumber == true) {
            //poprzedni element był liczbą, zatem aby dalej wykonywać działanie obecny element nie może być liczbą, tylko znakiem matematycznym
            if (!mathMarks.includes(array[i])) {
              // aktualny element to nie jest znak matematyczny
              // dalsze wykonywanie działania nie ma sensu, bo nie może być liczba obok liczby
              this.$notify({type: 'error', title: "Błędny format działania matematycznego, proszę poprawić błąd1."});
              return false;
            } else {
              // aktualny element jest znakiem matematycznym
              isPreviusNumber = false;
            }
          } else {
            //poprzedni element był znakiem matematycznym więc obecny musi być liczbą
            if (!mathMarks.includes(array[i])) {
              // aktualny element to liczba
              isPreviusNumber = true;

            } else {
              // aktualny element nie jest lecz znakiem działania dalsze wykonywanie działania nie ma sensu
              this.$notify({type: 'error', title: "Błędny format działania matematycznego, proszę poprawić błąd3."});
              return false;
            }
          }

        }
      } else {
        return false;
      }


      //////////////////////////////////////////////////////////////////

      //if (array[0] != "" && array[0] != " " && array[array.length - 1] != "" && array[array.length - 1] != " " && array[0] == parseFloat(array[0]) && array[array.length - 1] == parseFloat(array[array.length - 1])) {
      //zarówno pierwszy jak i ostatni element działanie to liczba
      // let isPreviusNumber = true;
      /*   for (let i = 1; i < array.length; ++i) {
           if (array[i] != "" && array[i] != " ") {
             if (isPreviusNumber == true) {
               //poprzedni element był liczbą, zatem aby dalej wykonywać działanie obecny element nie może być liczbą, tylko znakiem matematycznym
               if (array[i] == parseFloat(array[i])) {
                 // aktualny element to liczba

                 // dalsze wykonywanie działania nie ma sensu, bo nie może być liczba obok liczby
                 this.$notify({type: 'error', title: "Błędny format działania matematycznego, proszę poprawić błąd1."});
                 return false;
               } else {
                 // aktualny element nie jest liczbą więc sprawdzam, czy jest to znak matematyczny
                 if (mathMarks.includes(array[i])) {
                   // jest to znak matematyczny
                   isPreviusNumber = false;
                 } else {
                   // nie jest to znak matematyczny więc dalsze wykonywanie działania nie ma sensu
                   this.$notify({type: 'error', title: "Błędny format działania matematycznego, proszę poprawić błąd2."});
                   return false;
                 }
               }
             } else {
               //poprzedni element był znakiem matematycznym więc obecny musi być liczbą
               if (array[i] == parseFloat(array[i])) {
                 // aktualny element to liczba
                 isPreviusNumber = true;

               } else {
                 // aktualny element nie jest liczbą więc dalsze wykonywanie działania nie ma sensu
                 this.$notify({type: 'error', title: "Błędny format działania matematycznego, proszę poprawić błąd3."});
                 return false;
               }
             }
           }
         }*/
      // } else {
      // pierwszy lub ostatni element nie jest liczbą

      // poniższy warunek musi być wyłączony bo nie możemy oczekiwać liczby w pierwszym i elemencie ponieważ
      // jeśli pierwszy element działania to np. kwota z dziecka wówczas w tablicy nie mamy liczby, tylko oznaczenie systemowe
      /*  if((array[0] == "" || array[0] == " " || array[0] != parseFloat(array[0]))&&array.length>0)
        {
          // dalsze wykonywanie działania nie ma sensu, bo działanie musi rozpoczynać się od liczby i kończyć liczbą
          //console.log("bład 4", array);
          this.$notify({type: 'error', title: "Błędny format działania matematycznego, proszę poprawić błąd."});
          return false;
        }*/
      // }

      return true;
    },

    async remove(id2, chip) {

      let chipsCopy = JSON.parse(JSON.stringify(this.$props.chips));
      let chipsMathCopy = JSON.parse(JSON.stringify(this.$props.chipsMath));

      console.log('remove', id2, chip, chipsCopy, chipsMathCopy);

      chipsCopy[this.idBranch][id2].splice(chip, 1);
      //this.$props.chips = chipsCopy;

      chipsMathCopy[this.idBranch][id2].splice(chip, 1);
      //this.$props.chipsMath = chipsMathCopy;

      console.log(this.math);

      console.log(chipsCopy[this.idBranch], chipsMathCopy[this.idBranch]);

      this.$emit('update:newChips', chipsCopy[this.idBranch]);
      this.$emit('update:newChipsMath', chipsMathCopy[this.idBranch]);

      //console.log(chipsCopy, chipsMathCopy)
      //console.log(this.$props.chips, this.$props.chipsMath)

      let arrayMath = this.$props.chipsMath;
      let math = '';
      arrayMath[this.idBranch][id2].forEach((m) => {
        math = math + m;
      });

      if (this.canBeMathCounting(math) === true) {
        this.$emit('update:math', math, id2)
      }
    },
    emitChips(featureId) {
      this.$emit('update:chips',featureId, this.newChips[featureId]);
      this.newChips[featureId] = null;
    },
    addChip(sf1, sf2, title, value) {
      //console.log(sf1, sf2, title, value);
      this.$emit('update:addchip', sf1, sf2, title, value);
    },
    showMenu(a, b) {
      ////////////////console.logthis.menu[a][b]);
      if (this.menu[a] === undefined) {
        this.menu[a] = [];
        this.menu[a][b] = true;
      }
      //if(this.math[a][b] === false){
      this.menu[a][b] = true;
      //}

    },
    getMenu(a, b) {
      if (this.menu[a] === undefined) {
        this.menu[a] = [];
        this.menu[a][b] = false;
      }
      return this.menu[a][b];
    },
    show(e, id1, id2) {

      console.log('showMenu', e, id1, id2);


      e.preventDefault()
      this.x = e.clientX
      this.y = e.clientY
      this.sF1 = id1;
      this.sF2 = id2;

      if (this.menu[id1][id2] === true) {
        this.menu[id1][id2] = false;
      }

      this.$nextTick(() => {
        if (this.menu[id1] === undefined) {
          this.menu[id1] = [];
        }
        this.menu[id1][id2] = true;
      })

    },

  }
}
</script>

<style scoped>
.drag-element {
  margin-bottom: 10px;
  cursor: move;
}
</style>
