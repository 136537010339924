import { createApp } from 'vue';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import Vue3Storage, {StorageType} from "vue3-storage";
import VueAxios from 'vue-axios'

// Vuetify
import 'vuetify/styles'
import { createVuetify, } from 'vuetify'
import * as comp from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as labs from 'vuetify/labs/components'
import { pl } from 'vuetify/locale'

//import Moveable from '@gabojkz/vue_moveable_v3';

import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import "@fortawesome/fontawesome-free/css/all.css"; // Ensure your project is capable of handling css files

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import DatetimePicker from 'vuetify-datetime-picker'
import Vue3Material from 'vue3-material';
import ganttastic from '@infectoone/vue-ganttastic'

import Notifications from '@kyvg/vue3-notification'



import { loadFonts } from './plugins/webfontloader'
import store from './store'
loadFonts();



const vuetify = createVuetify({
    directives,
    components:{
        ...labs,
        ...comp,
    },
    locale: {
        locale: 'pl',
        messages: { pl },
    },
    defaults: {
        global: {
            rounded: 'lg',
            elevation: 0,
        },
        VTextField: {
            variant: 'outlined',
            rounded: 80,
            height: 28,
            density: "compact"
        },
        VSelect:{
            variant: 'outlined',
            height: 48,
            density: "compact"
        },
        VList:{
            density: "compact"
        },
        VListItem:{
            density: "compact"
        },
        VMenu:{
            VList:{
                elevation: 10
            }
        },
        VBtn:{
            elevation: 2
        },
        VDataTable:{
            VBtn:{
                elevation:0
            }
        },
    },

    theme: {
        defaultTheme: 'light',
        themes: {
            light: {
                primary: "#1e88e5",
                secondary: "#5e35b1",
                success: "#00c853",
                error: "#f44336",
                warning: "#ffc107",

                accent: "#03a9f4",
                info: "#009688",
                secondaryDarken: '#e5e5e5',
                bgLight: '#EEF2F6FF'
            },
            dark: {
                primary: "#3f51b5",
                secondary: "#2196f3",
                accent: "#03a9f4",
                error: "#f44336",
                warning: "#ffc107",
                info: "#009688",
                success: "#4caf50",
                secondaryDarken: '#525252',
            },
        }
    }
})


createApp(App)
    .use(router)
    .use(store)
    .use(vuetify)
    .use(Vue3Storage, { namespace: "pro_", storage: StorageType.Local })
    .use(ganttastic)
    .use(Vue3Material)
    .use(Notifications)

    .use(VueSweetalert2)
    .use(DatetimePicker)
    .use(VueAxios, axios)
    .mount('#app');
