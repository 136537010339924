<template>
  <v-app>
    <v-main>
      <div class="full-screen-background"></div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "ErrorPage"
}
</script>

<style>
.full-screen-background {
  /* Ustaw tło na całą szerokość i wysokość ekranu */
  width: 100%;
  height: 100%;
  /* Ustaw obraz jako tło i użyj "cover" jako wartość właściwości "background-size" */
  background-image: url("../../public/error.jpg");
  background-size: cover;
  /* Opcjonalnie: centruj obraz */
  background-position: center;
  /* Opcjonalnie: ustaw kolor tła w przypadku, gdy obraz nie jest załadowany */
  background-color: black;
  /* Opcjonalnie: ustaw inne style tła */
  /* background-repeat: no-repeat; */
  /* background-attachment: fixed; */
}

/* Reszta stylów dla Twojej strony */
</style>
