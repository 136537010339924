<template>
  <div v-if="checkRoles(['ROLE_ADMIN', 'ROLE_PROJECTS_READ'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
            <v-btn
                color="green"
                style="margin-top:6px;"
                class="float-end mx-2"
                size="x-small"
                prepend-icon="mdi-plus-box"
                v-if="checkRoles(['ROLE_PROJECTS_READ','ROLE_ADMIN'])"
            >
              Nowy projekt

              <v-dialog
                  v-model="dialog"
                  activator="parent"
                  width="auto"
              >
                <v-form v-model="isFormValid">
                <v-card>

                  <v-card-text>
                    <v-text-field style="width: 550px;"
                                  label="Nazwa"
                                  variant="outlined"
                                  v-model="titleProject"
                                  :rules="projectNameRules">
                    </v-text-field>
                    <v-select :items="['Gałąź','Projekt']"
                              v-model="typeProject"
                              :rules="projectItemRules"
                    ></v-select>
                    <v-select :items="types.types"
                              v-model="type"
                              item-value="id"
                              item-title="name"
                              :rules="projectTypeRules"
                    ></v-select>
                  </v-card-text>

                  <ActionsButton @annulled="dialog = false" @save="saveProject('project')"
                                 :disabledSave="!isFormValid"
                  ></ActionsButton>

                </v-card>
                </v-form>
              </v-dialog>

            </v-btn>
            <v-btn
                color="blue"
                style="margin-top:6px;"
                class="float-end mx-2"
                v-bind="props"
                size="x-small"
                prepend-icon="mdi-file-tree-outline"
                to="/projects/element"
                v-if="checkRoles(['ROLE_PROJECTS_READ','ROLE_ADMIN'])"
            >
              Gałęzie drzewa
            </v-btn>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              v-if="projects"
              density="compact"
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items-length="totalItems"
              :items="projects.projects"
              :search="search"
              class="elevation-1"
              item-value="id"
          >

            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.selectable.id }}</td>
                <td>{{ item.selectable.title }}</td>
                <td>{{ item.selectable.typeProjectName }}</td>
                <td>{{ formatDate(item.selectable.createdAt) }}</td>
                <td>{{ formatDate(item.selectable.modifiedAt) ?? '-' }}</td>
                <td>
                  <v-chip
                      :color="status(item.selectable.status).color"
                  >
                    {{ status(item.selectable.status).label }}
                  </v-chip>
                </td>
                <td align="end">
                  <ProjectDetail :idProject="item.selectable.id"></ProjectDetail>
                  <v-btn class="mx-2" append-icon="mdi-pencil-box" size="x-small" fab dark color="blue"
                         :to="'/products/tree2/' +  item.selectable.id ">
                    Drzewo
                  </v-btn>
                  <RemoveButton @confirm="removeItem(item.selectable.id)"
                                v-if="checkRoles(['ROLE_PROJECTS_DELETE','ROLE_ADMIN'])">

                  </RemoveButton>
                </td>
              </tr>
            </template>

          </v-data-table>
        </v-card>
      </v-main>
    </v-layout>


  </div>
  <div v-else>
    <DenyAccessPage></DenyAccessPage>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import DenyAccessPage from "@/components/DenyAccessPage.vue";
import {useStorage} from "vue3-storage";
import ProjectDetail from "@/components/ProjectDetail.vue";
import ActionsButton from "@/components/navigation/ActionsButton.vue";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";

export default {
  name: 'ProjectsView',
  components: {RemoveButton, ActionsButton, ProjectDetail, DenyAccessPage, BreadcrumbsComponent},
  data: () => {
    return {
      breadcrumbs: [
        {
          title: 'Projekty',
          disabled: true,
        },
      ],
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Typ projektu', key: 'projectTypeName', align: 'start'},
        {title: 'Data stworzenia', key: 'createdAt', align: 'start'},
        {title: 'Data modyfikacji', key: 'modifiedAt', align: 'start'},
        {title: 'Status', key: 'status', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      totalItems: 10,
      itemId: null,


      // Project Save
      dialog: false,
      titleProject: null,
      typeProject: 'Projekt',
      type: null,
      isFormValid: false,
      projectNameRules: [
        value => {
          if (value) return true
          return 'Wymagane.'
        },
        value => {
          if (value.length <= 255) return true
          return 'Maksymalna liczba znaków to 255.'
        },
      ],
      projectItemRules:[
        value => {
          if (value) return true
          return 'Wymagane.'
        },
      ],
      projectTypeRules:[
        value => {
          if (value) return true
          return 'Wymagane.'
        },
      ]
    };
  },
  computed: {
    ...mapState(["projects", 'types']),
    ...mapGetters(["isLoggedIn", 'getToken', "getRoles","getDefaultType"])
  },
  created() {
    this.setDefaultType()
  },
  methods: {
    ...mapActions(['removeProject']),
    setDefaultType(){
      this.type = this.getDefaultType.id;
    },
    async saveProject() {
      const dataToUpdate = {
        title: this.titleProject,
        type: this.typeProject,
        typeProject: this.type,
      }
      if (this.typeProject === "Projekt") {
        await this.$store.dispatch('addProject', dataToUpdate);
        this.$router.push('/products/tree2/' + this.$store.state.projects.currentIdProject);
      }else{
        await this.$store.dispatch('addProjectElement', dataToUpdate);
        this.$router.push('/products/tree2/' + this.$store.state.projectsElements.currentIdProjectElement);
      }
    },
    async removeItem(itemId) {
      await this.removeProject(itemId);
    },
    checkRoles(roles) {
      let allRoles = this.$store.state.roles;
      if (allRoles === null) {
        allRoles = useStorage().getStorageSync("roles");
      }
      return roles.some(role => allRoles.includes(role));
    },
    formatDate(date) {
      // Format the date as needed
      return new Date(date).toLocaleDateString('pl-PL', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });
    },
    status(status) {
      let color;
      let label;

      switch (status) {
        case "new":
          color = 'grey'
          label = 'Nowy'
          break;
        case "draft":
          color = 'grey'
          label = 'Szkic'
          break;
        case "ready":
          color = 'blue'
          label = 'Gotowy'
          break;
        case "annulled":
          color = 'grey'
          label = 'Anulowany'
          break;
        default:
          color = 'green'
          label = 'BRAK'
      }

      return {
        color: color,
        label: label
      }
    }
  },
}
</script>
