<template>
  <div v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_PROJECTS_READ'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
            <v-btn
                color="blue"
                style="margin-top:6px;"
                class="float-end mx-2"
                size="x-small"
                prepend-icon="mdi-file-tree-outline"
                to="/projects"
                v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_PROJECTS_READ'])">
              Projekty
            </v-btn>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              v-if="projectsElements"
              density="compact"
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items-length="totalItems"
              :items="projectsElements.projectsElements"
              :search="search"
              class="elevation-1"
              item-value="id"
          >

            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.selectable.id }}</td>
                <td>{{ item.selectable.title }}</td>
                <td align="end">
                  <v-btn class="mx-2" append-icon="mdi-pencil-box" size="x-small" fab dark color="blue"
                         :to="'/products/tree2/' +  item.selectable.id ">
                    Drzewo
                  </v-btn>
<!--                  <v-btn class="mx-2" append-icon="mdi-pencil-box" size="x-small" fab dark color="warning"
                         @click="duplicate(item.selectable.id)">
                    Duplikuj
                  </v-btn>-->
                  <RemoveButton @confirm="removeItem(item.selectable.id)"
                                v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_PROJECTS_REMOVE'])">

                  </RemoveButton>
                </td>
              </tr>
            </template>

          </v-data-table>
        </v-card>
      </v-main>
    </v-layout>
  </div>
  <div v-else>
    <DenyAccessPage></DenyAccessPage>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import DenyAccessPage from "@/components/DenyAccessPage.vue";
import {useStorage} from "vue3-storage";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";

export default {
  name: 'ProjectsView',
  components: {RemoveButton, DenyAccessPage, BreadcrumbsComponent},
  data: () => {
    return {
      breadcrumbs: [
        {
          title: 'Gałęzie drzewa',
          disabled: true,
        },
      ],
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      totalItems: 10,
      itemId: null
    };
  },
  computed: {
    ...mapState(["projectsElements"]),
    ...mapGetters(["isLoggedIn", 'getToken', 'getProjectElementsById'])
  },
  methods: {
    ...mapActions(['removeProjectElements','addProjectElement']),
    async removeItem(itemId) {
      await this.removeProjectElements(itemId);
    },
    async duplicate(itemId) {
      let product = await this.getProjectElementsById(itemId);
      const cloneProduct = { ...product };
      delete cloneProduct.id;
      cloneProduct.type = 'element';
      await this.$store.dispatch('addProjectElement', cloneProduct);
    },
    checkRoles(roles) {
      let allRoles = this.$store.state.roles;
      if (allRoles === null) {
        allRoles = useStorage().getStorageSync("roles");
      }
      return roles.some(role => allRoles.includes(role));
    },
  },

}
</script>
