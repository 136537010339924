<template>
  <v-btn color="success"
         :prepend-icon="icon ?? 'mdi-content-save-check'"
         variant="elevated" size="small" @click="$emit('action')"
          :disabled="disabledSave"
  >{{ $props.label }}
  </v-btn>
</template>
<script>
export default {
  name: "SaveButton",
  props: ['label','disabledSave','icon'],
}
</script>
