<template>
  <div v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_UNITS_READ'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
            <v-dialog
                v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_UNITS_SAVE'])"
                v-model="dialog"
                width="600"
                height="500"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                    color="blue"
                    style="margin-top:6px;"
                    class="float-end"
                    v-bind="props"
                    size="x-small"
                    prepend-icon="mdi-plus"
                    @click="clearValues"
                >
                  Dodaj nową jednostkę
                </v-btn>

              </template>
              <v-card>
                <v-card-text>
                  <v-form v-model="isFormValid">
                    <v-text-field
                        label="Jednostka miary"
                        :rules="[rules.required, rules.counter]"
                        density="compact"
                        v-model="title"
                        hide-details="auto"
                        class="mb-5"
                        counter
                        maxlength="20"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <ActionsButton @annulled="dialog = false" @save="fetchAddUnit(itemId)" :disabledSave="!isFormValid"></ActionsButton>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-card-item>
            <v-data-table
                density="compact"
                v-model:items-per-page="itemsPerPage"
                :headers="headers"
                :items-length="totalItems"
                :items="units.units"
                :loading="loading"
                :search="search"
                class="elevation-1"
                item-value="id"
                @update:options="getList"
            >

              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.selectable.id }}</td>
                  <td>{{ item.selectable.title }}</td>
                  <td align="end">
                    <v-btn class="mx-2" append-icon="mdi-pencil-box" size="x-small" fab dark color="blue"
                           @click="editItem(item.selectable)"
                           v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_UNITS_SAVE'])">
                      Edytuj
                    </v-btn>
                    <RemoveButton @confirm="fetchDeleteUnit(item.selectable.id)"
                                  v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_UNITS_REMOVE'])">

                    </RemoveButton>
                  </td>
                </tr>
              </template>

            </v-data-table>
          </v-card-item>
        </v-card>
      </v-main>
    </v-layout>
  </div>
  <div v-else>
    <DenyAccessPage></DenyAccessPage>
  </div>
</template>


<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import DenyAccessPage from "@/components/DenyAccessPage.vue";
import {useStorage} from "vue3-storage";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";
import ActionsButton from "@/components/navigation/ActionsButton.vue";

export default {
  name: 'UnitsView',
  components: {ActionsButton, RemoveButton, DenyAccessPage, BreadcrumbsComponent},
  data: () => {
    return {
      isFormValid: false,
      rules: {
        required: value => !!value || 'Wymagane.',
        counter: value => value.length <= 20 || 'Max 20 znaków',
      },
      title: '',
      breadcrumbs: [
        {
          title: 'Produkty',
          disabled: false,
          to: 'products/ready',
        },
        {
          title: 'Kategorie',
          disabled: false,
          to: 'categories',
        },
        {
          title: 'Cechy',
          disabled: false,
          to: 'features',
        },
        {
          title: 'Jednostki miary',
          disabled: true,
        },
      ],
      dialog: false,
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      loading: false,
      totalItems: null,
      itemId: null,
      items: [],
    };
  },
  mounted(){
    this.totalItems = this.units.units.length;
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken', "getRoles"]),
    ...mapState(["units"])
  },

  methods: {
    ...mapActions(['addUnit', 'checkRoles', 'loadUnits']),
    clearValues(){
      this.title= '';
    },
    async fetchAddUnit(idUnit) {
      //////console.log'fetchAddUnit',idUnit);
      if (idUnit) {
        await this.$store.dispatch('updateUnit', {item: {title: this.title}, idUnit: idUnit});
      } else {
        await this.$store.dispatch('addUnit', {title: this.title});
      }
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      if (this.$store.state.users.responseStatus === 'success') {
        //await this.loadUnits();
        this.dialog = false;
        this.itemId = null;
      }
    },
    async fetchDeleteUnit(idUnit) {

      await this.$store.dispatch('removeUnit', idUnit);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    editItem(unit) {
      this.dialog = true
      this.title = unit.title
      this.itemId = unit.id
    },
    checkRoles(roles) {
      let allRoles = this.$store.state.roles;
      if (allRoles === null) {
        allRoles = useStorage().getStorageSync("roles");
      }
      return roles.some(role => allRoles.includes(role));
    },
  }
}
</script>
