<template>
  <v-card>
    <v-card-title>Podsumowanie</v-card-title>
    <v-card-item>
      <v-row>
        <v-col>
          <v-card-subtitle>Projekt: <strong>{{ projectName }}</strong></v-card-subtitle>
          <v-card-subtitle>Typ projektu: <strong>{{ projectType }}</strong></v-card-subtitle>
        </v-col>
        <v-col cols="auto" v-if="changeTab">
          <v-btn @click="changeTab('project')" color="primary" size="x-small">Zmień projekt</v-btn>
        </v-col>
      </v-row>
    </v-card-item>
    <!-- DIAGNOSTIC   <template v-for="item in selectedProducts" :key="item">
          <v-code>{{item.idTree}}_{{item.idFeature}} | {{item.math}} = {{item.value}}</v-code>
        </template>-->
    <v-card-item>
      <template v-if="itemsWithIsScreenTrue">
        <template v-for="screen in itemsWithIsScreenTrue" :key="screen.id">
          <v-card
              v-if="getItemFromScreen(screen.id).length > 0"
              class="mb-3"
              variant="outlined" style="border-color: #ccc;">
            <template v-slot:title>
              <v-row>
                <v-col>{{ screen.label }}</v-col>
                <v-col>{{ getValueFromScreen(screen.id).value }}</v-col>
                <v-col cols="auto">
                  <v-btn v-if="changeTab"
                         @click="changeTab(screen.id)"
                         prepend-icon="mdi-arrange-send-to-back"
                         color="primary"
                         size="x-small"
                  >Ustawienia
                    <v-tooltip activator="parent" location="top">
                      Przejdź do ustawień
                    </v-tooltip>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <v-card-item>
              <v-table density="compact">
                <thead>
                <tr>
                  <th class="text-left ps-0" v-for="item in tableHeaders"
                      :key="item.name">
                    {{ item.title }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <template v-for="item in getItemFromScreen(screen.id)"
                          :key="item.idTree">
                  <tr> <!-- v-show="item.eye" -->
                    <td class="ps-0 " style="width:80%;">{{ item.parent ? item.parent + ' • ' : '' }} {{ item.name }}
                    </td>
                    <td style="width:20%;">
                      <!--                      [{{item.idTree}}_{{item.idFeature}}]-->
                      {{ !isNaN(item.value) ? item.value : 0 }} <span v-if="item.unit">{{ item.unit.title }}</span>
                      <v-tooltip v-if="item.math" activator="parent" location="top">
                        {{ item.math }}
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
                <template v-if="screen.children">
                  <template v-for="screenCh in screen.children" :key="screenCh">
                    <template v-for="item in getItemFromScreen(screenCh.id)"
                              :key="item.idTree">
                      <template v-if="item.isScreen !== true">
                        <tr class="row"> <!-- v-show="item.eye" -->
                          <td class="ps-2 col-8">{{ item.parent ? item.parent + ' • ' : '' }} {{ item.name }}</td>
                          <td class="col-4">
                            <template v-if="item.priceReady">
                              Cena: {{ item.priceReady }}
                              Jednostka: {{ item.priceUnit }}
                            </template>
                            <template v-if="!item.priceReady">
                              <!--                              [{{item.idTree}}_{{item.idFeature}}]-->
                              {{ !isNaN(item.value) ? item.value : 0 }}
                              <v-tooltip v-if="item.math" activator="parent" location="top">
                                {{ item.math }}
                              </v-tooltip>
                            </template>
                          </td>
                        </tr>
                        <template v-for="item2 in getItemsFromChildren(item.children)"
                                  :key="item2.idTree">
                          <template v-if="item2.isScreen !== true">
                            <tr class="row" v-show="item2.eye">
                              {{ item2.isScreen }}
                              <td class="ps-4 col-8">{{ item2.parent ? item2.parent + ' • ' : '' }} {{
                                  item2.name
                                }}
                              </td>
                              <td class="col-4">
                                <!--                                [{{item2.idTree}}_{{item2.idFeature}}]-->

                                {{ !isNaN(item2.value) ? item2.value : 0 }}
                                <v-tooltip v-if="item2.math" activator="parent" location="top">
                                  {{ item2.math }}
                                </v-tooltip>
                              </td>
                            </tr>
                            <template v-for="item3 in getItemsFromChildren(item2.children)"
                                      :key="item3.idTree">
                              <template v-if="item3.isScreen !== true">
                                <tr class="row" v-show="item.eye">
                                  <td class="ps-5 col-8">{{ item3.parent ? item3.parent + ' • ' : '' }} {{
                                      item3.name
                                    }}
                                  </td>
                                  <td class="col-4">{{ item3.value }}
                                    <v-tooltip v-if="item3.math" activator="parent" location="top">
                                      {{ item3.math }}
                                    </v-tooltip>
                                  </td>
                                </tr>
                              </template>
                            </template>
                          </template>
                        </template>
                      </template>
                    </template>
                  </template>
                </template>
                </tbody>
              </v-table>
            </v-card-item>
          </v-card>
        </template>
      </template>
    </v-card-item>
  </v-card>
</template>

<script>
export default {
  name: "TableProducts",
  props: [
    'projectName',
    'changeTab',
    'itemsWithIsScreenTrue',
    'getValueFromScreen',
    'selectedProducts',
    'projectType'
  ],

  methods: {
    getItemFromScreen(idScreen) {

      const items = this.selectedProducts.filter((s) => Number(s.idTree) === Number(idScreen));
      //console.log('getItemFromScreen',idScreen, this.selectedProducts, items)
      return items;

    },
    getItemsFromChildren(children) {
      let _child = [];
      if (children) {
        children.filter((child) => {
          let result = this.selectedProducts.find((el) => el.idTree === Number(child));
          if (result !== undefined) {
            _child.push(result);
          }
        });
      }
      return _child;
    },
    extractItemsWithIsScreenTrue(items) {
      const result = [];
      for (const item of items) {
        if (item.isScreen === true) {
          result.push(item);
        }
        if (item.children && Array.isArray(item.children)) {
          result.push(...this.extractItemsWithIsScreenTrue(item.children));
        }
      }
      return result;
    },
  }
}
</script>

<style scoped>

</style>
