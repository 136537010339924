<template>
  <v-card-actions class="justify-space-between">
    <AnnulledButton label="Anuluj" @action="$emit('annulled')"></AnnulledButton>
    <SaveButton :label="actionLabel ?? 'Zapisz'" :color="actionColor ?? 'success'" @action="$emit('save')"
                :disabledSave="disabledSave"
                :icon="actionIcon"
    ></SaveButton>
  </v-card-actions>
</template>
<script>
import AnnulledButton from "@/components/navigation/element/AnnulledButton.vue";
import SaveButton from "@/components/navigation/element/SaveButton.vue";

export default {
  name: "ActionsButton",
  props: ['disabledSave','actionLabel','annulledLabel', 'actionIcon', 'annulledIcon', 'actionColor'],
  components: {SaveButton, AnnulledButton}
}
</script>
