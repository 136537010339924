<template>
  <!-- @change="change($event.target.value)" -->
  <v-row>
<!--    <v-col>
      <v-radio-group
          :modelValue="type"
          @change="$emit('update:type', $event.target.value)"
      >
        <template v-slot:label>
          <div>Wybierz rodzaj <strong>tego elementu</strong></div>
        </template>
        <v-radio v-for="item in types" :value="item.value" :key="item.id" :label="item.label" />
      </v-radio-group>
    </v-col>-->
    <v-col>
      <v-switch
          inset
          density="compact"
          v-model="switchPublic_"
          @change="switchPublic()"
          label="Widoczny"
          hide-details
          color="success"></v-switch>
      <v-switch
          inset
          density="compact"
          v-model="switchChildren_"
          @change="switchChildren()"
          label="Jeden wybór"
          hide-details
          color="success"></v-switch>
      <v-switch
          inset
          density="compact"
          v-model="switchScreen_"
          @change="switchScreen()"
          label="Ekran"
          hide-details
          color="success"></v-switch>
      <v-switch
          inset
          density="compact"
          v-model="switchRequired_"
          @change="switchRequired()"
          label="Wymagane"
          hide-details
          color="success"></v-switch>
    </v-col>
  </v-row>

</template>
<script>
export default {
  name: 'ProductType',
  data() {
    return {
      switchPublic_: false,
      switchChildren_: false,
      switchScreen_: false,
      switchRequired_: false,
    }
  },
  props: {
    idBranch: Number,
    isPublic: Boolean,
    oneChildren: Boolean,
    isScreen: Boolean,
    isRequired: Boolean,
  },
  emits: [
    'update:type',
    'update:published',
    'update:children',
  ],
  mounted() {
    this.getSwitchPublic();
    this.getSwitchChildren();
    this.getSwitchScreen();
    this.getSwitchRequired();
  },
  methods: {
    switchPublic() {
      this.$emit('update:isPublic', this.switchPublic_)
    },
    switchChildren(){
      this.$emit('update:oneChildren', this.switchChildren_)
    },
    switchScreen() {
      this.$emit('update:isScreen', this.switchScreen_)
    },
    switchRequired(){
      this.$emit('update:isRequired', this.switchRequired_)
    },

    getSwitchPublic(){
      this.switchPublic_ = this.isPublic
    },
    getSwitchChildren(){
      this.switchChildren_ = this.oneChildren
    },
    getSwitchScreen(){
      this.switchScreen_ = this.isScreen
    },
    getSwitchRequired(){
      this.switchRequired_ = this.isRequired
    }
  },

}
</script>
<style>
.v-input--switch__track {
  border-radius: 15px;
  width: 48px;
  height: 19px;
  top: -2px;
}
.v-switch--inset .v-switch__track{
  height: 18px;
}
.v-switch__thumb{
  width:10px;
  height:10px;
}
.v-input--switch__thumb {
  left: 6px;
}

.custom-switch .v-input__slot .v-label {
  left: 6px !important
}

.v-input--selection-controls__ripple {
  height: 0;
  width: 0
}
</style>
