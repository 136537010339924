<template>
  <div v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_CATEGORIES_READ'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
            <v-dialog
                v-model="dialog"
                width="1000"
                v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_CATEGORIES_SAVE'])"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                    color="blue"
                    style="margin-top:6px;"
                    class="float-end"
                    v-bind="props"
                    size="x-small"
                    prepend-icon="mdi-plus"
                    @click="clearValues"
                >
                  Dodaj kategorię
                </v-btn>
              </template>
              <v-card>
                <v-form v-model="isFormValid">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-text-field
                          density="compact"
                          label="Kategoria"
                          :rules="[rules.required, rules.counter]"
                          v-model="title"
                          hide-details="auto"
                      ></v-text-field>
                      <v-select
                          class="mt-3"
                          density="compact"
                                :items="options"
                                item-title="label"
                                item-value="value"
                                v-model="selectedOption"
                          :rules="[rules.required]">

                      </v-select>
                    </v-col>
                    <v-col>
                      <v-checkbox
                          density="compact"
                          :label="feature.title"
                          v-for="feature in features.features"
                          :key="feature.id"
                          v-model="selectedFeatures"
                          :value="feature.id"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
                <ActionsButton @annulled="dialog = false" @save="fetchAddCategories(itemId)" :disabledSave="!isFormValid"></ActionsButton>
                </v-form>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              density="compact"
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items-length="totalItems"
              :items="categories.categories"
              :loading="loading"
              :search="search"
              class="elevation-1"
              item-value="id"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.selectable.id }}</td>
                <td>{{ item.selectable.type === 'own' ? 'Własne' : 'Handlowe' }}</td>
                <td>{{ item.selectable.title }}</td>
                <td>
                  <span v-for="features in item.selectable.features" :key="features.id"> [ {{ features.title }} ] </span>
                </td>
                <td align="end">
                  <v-btn class="mx-2" append-icon="mdi-pencil-box" size="x-small" fab dark color="blue"
                         @click="editItem(item.selectable)"
                         v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_CATEGORIES_SAVE'])">
                    Edytuj
                  </v-btn>
                  <RemoveButton @confirm="fetchDeleteCategory(item.selectable.id)"
                                v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_CATEGORIES_REMOVE'])">
                  </RemoveButton>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-main>
    </v-layout>
  </div>
  <div v-else>
    <DenyAccessPage></DenyAccessPage>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import DenyAccessPage from "@/components/DenyAccessPage.vue";
import {useStorage} from "vue3-storage";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";
import ActionsButton from "@/components/navigation/ActionsButton.vue";

export default {
  name: 'CategoriesView',
  components: {ActionsButton, RemoveButton, DenyAccessPage, BreadcrumbsComponent},
  data: () => {
    return {
      isFormValid: false,
      rules: {
        required: value => !!value || 'Wymagane.',
        counter: value => value.length <= 20 || 'Max 20 znaków',
      },
      selectedOption: null,
      options: [
        { label: 'Własne', value: 'own' },
        { label: 'Handlowe', value: 'commercial' },
      ],
      title: '',
      breadcrumbs: [
        {
          title: 'Produkty',
          disabled: false,
          to: 'products/ready',
        },
        {
          title: 'Kategorie',
          disabled: true,
        },
      ],

      dialog: false,
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Typ', key: 'type', align: 'start'},
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Cechy', key: 'features', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      loading: false,
      totalItems: null,
      itemId: null,
      items: [],
      selectedFeatures: [],
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken', "getRoles"]),
    ...mapState(["categories","features"])
  },
  mounted(){
    this.totalItems = this.categories.categories.length;
  },
  methods: {
    ...mapActions(['addCategory']),
    clearValues(){
      this.title= '';
      this.selectedOption= null;
      this.selectedFeatures= [];
    },
    async fetchAddCategories(idCategory) {
     //////console.log'fetchAddCategories', idCategory);
      if (idCategory) {
        await this.$store.dispatch('updateCategory', {
          item: {
            title: this.title,
            type: this.selectedOption,
            features: this.selectedFeatures
          }, idCategory: idCategory
        });
      } else {
        await this.$store.dispatch('addCategory', {
          title: this.title,
          type: this.selectedOption,
          features: this.selectedFeatures
        });
      }
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      if (this.$store.state.users.responseStatus === 'success') {
        this.dialog = false;
        this.itemId = null;
      }
    },
    async fetchDeleteCategory(idCategory) {
      await this.$store.dispatch('removeCategory', idCategory);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    editItem(category) {

      console.log(category);

      this.dialog = true
      this.title = category.title
      this.type = category.type
      this.itemId = category.id

      if(category.features && category.features.length > 0){
        let featuresIds = [];
        category.features.filter((f) => {
          featuresIds.push(f.id);
        })
        this.selectedFeatures = featuresIds;
      }


      if(category.type === undefined){
        this.selectedOption = "commercial";
      }else{
        this.selectedOption = category.type;
      }
    },
    checkRoles(roles) {
      let allRoles = this.$store.state.roles;
      if (allRoles === null) {
        allRoles = useStorage().getStorageSync("roles");
      }
      return roles.some(role => allRoles.includes(role));
    },

  }
}
</script>
