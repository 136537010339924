import axios from "axios";
export const projectsElements = {
    state: {
        projectsElements: [],
        currentIdProjectElement: null,
    },
    getters: {
        getProjectsElements: state => {
            return state.projectsElements;
        },
        getProjectElementsById: (state) => (id) => {
            return state.projectsElements.find(item => item.id === Number(id));
        }
    },
    mutations: {
        setProjectsElements(state, items) {
            state.projectsElements = items;
        },
        removeProjectElements(state, itemId) {
            state.projectsElements = state.projectsElements.filter(item => item.id !== itemId);
        },

        addProjectElement(state, items) {
            state.projectsElements.push(items);
        },
        updateProjectElement(state, data) {
            const index = state.projectsElements.findIndex(item => Number(item.id) === Number(data.idProject.toString()));
            data.item.id = Number(data.idProject.toString());
            state.projectsElements.splice(index, 1, data.item);
        },

        currentIdProjectElement(state, item) {
            state.currentIdProjectElement = item;
        },

    },
    actions: {
        async loadProjectsElements({commit, rootState }) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_LOCATION + '/api/projects/elements',
                    {headers: {Authorization: `Bearer ${rootState .token}`}});
                const items = response.data;
                commit('setProjectsElements', items);
            } catch (error) {
                //console.error('Error loading items:', error);
            }
        },
        async removeProjectElements({commit, rootState }, itemId) {
            try {
                // Perform the asynchronous task to remove an item
                await axios.delete(process.env.VUE_APP_API_LOCATION + `/api/projects/${itemId}`,
                    {headers: {Authorization: `Bearer ${rootState .token}`}});

                // Commit the mutation to update the state
                commit('removeProjectElements', itemId);
            } catch (error) {
                commit('setNetworkError', true);
            }
        },
        async addProjectElement({commit, rootState }, data) {

            try {
                await axios.post(process.env.VUE_APP_API_LOCATION + '/api/projects',
                    data,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(response => {
                        const items = response.data;

                        console.log('addProjectElement',items.id);

                        commit('addProjectElement', items);
                        commit('currentIdProjectElement', items.id);
                        commit('setResponseMessage', 'Element został dodany');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        ////console.log'noi error 1', error);
                        commit('setResponseMessage', error);
                        commit('setResponseStatus', 'error');
                    });

                ////console.log'noi zapis');

            } catch (error) {

                ////console.log'noi error ', error);
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateProjectElement({commit, rootState }, data) {
            try {
                await axios.put(process.env.VUE_APP_API_LOCATION + '/api/projects/' + data.idProject,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(() => {

                        ////console.logdata);

                        commit('updateProjectElement', data);
                        commit('setResponseMessage', 'Element został zaktualizowany');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        ////console.logerror);
                        commit('setResponseMessage', '0:' + error);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', '1:' + error);
                commit('setResponseStatus', 'error');
            }
        },
    }
}
