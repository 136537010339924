<template>
  <div v-if="checkRoles(['ROLE_ADMIN', 'ROLE_TRADING_PRODUCTS_READ'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col cols="auto">
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
            <v-btn
                color="red"
                style="margin-top:6px;"
                class="float-end ms-1"
                size="x-small"
                prepend-icon="mdi-format-list-bulleted-square"
                to="/products"
            >Wszystkie produkty
            </v-btn>

            <v-btn
                color="blue"
                class="float-end ms-1"
                style="margin-top:6px;"
                size="x-small"
                @click="newItem"
                prepend-icon="mdi-plus">
              Dodaj nowy produkt
              <v-tooltip
                  activator="parent"
                  location="end"
              >Dodaj nowy produkt
              </v-tooltip>
            </v-btn>

            <v-dialog v-model="productDialog[0]" persistent max-width="900px">
              <ProductsSingleDialogView :productId="0"
                                        @closeDialog="productDialog[0] = false">
              </ProductsSingleDialogView>
            </v-dialog>

            <!--      <v-btn
                      color="blue"
                      style="margin-top:6px;"
                      class="float-end ms-1"
                      v-bind="props"
                      size="x-small"
                      prepend-icon="mdi-plus"
                      @click="newData()"
                      to="/product"
                  >Dodaj nowy produkt
                  </v-btn>-->
            <v-dialog
                v-model="dialog"
                width="600"
                height="500"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                    color="green"
                    style="margin-top:6px;"
                    class="float-end ms-1"
                    v-bind="props"
                    size="x-small"
                    prepend-icon="mdi-import"
                    :loading="loadingExcel"
                >
                  Import produktów
                </v-btn>
              </template>
              <v-card>
                <form @submit="uploadFile">
                  <v-card-text>
                    <input type="file" ref="fileInput"/>
                  </v-card-text>
                  <v-card-item>
                    <v-row align="center" justify="center">
                      <v-col cols="auto">
                        <v-btn color="blue" type="submit">Wgraj</v-btn>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn color="secondary" @click="dialog = false">Zamknij</v-btn>
                      </v-col>
                    </v-row>
                  </v-card-item>
                </form>
              </v-card>
            </v-dialog>
            <v-btn
                color="green"
                style="margin-top:6px;"
                class="float-end ms-1"
                size="x-small"
                prepend-icon="mdi-export"
                :href="process.env.VUE_APP_API_LOCATION + '/api/products-export'"
                target="_blank"
            >
              Export produktów EXCEL
            </v-btn>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-item>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                variant="outlined"
                single-line
                density="compact"
                hide-details
                class="mb-4"
            ></v-text-field>

            <v-data-table
                :group-by="groupBy"
                :headers="headers"
                :items="readyProducts.readyProducts"
                :items-length="totalProducts"
                :loading="loading"
                :search="search"
                loading-text="Loading... Please wait"
                density="compact"
                v-model:items-per-page="itemsPerPage"
            >

              <template v-slot:group-header="{ item, toggleGroup, isGroupOpen }">
                <tr>
                  <td :colspan="12">
                    <v-btn
                        size="small"
                        variant="text"
                        :icon="isGroupOpen(item) ? '$expand' : '$next'"
                        @click="toggleGroup(item)"
                    ></v-btn>
                    {{ item.value }}
                  </td>
                </tr>
              </template>

              <template v-slot:[`item.actions`]="item">

                <v-dialog v-model="productDialog[item.item.selectable.id]" persistent max-width="900px">
                  <ProductsSingleDialogView :productId="item.item.selectable.id"
                  @closeDialog="productDialog[item.item.selectable.id] = false">
                  </ProductsSingleDialogView>
                </v-dialog>

                <v-btn
                    color="blue"
                    class="float-end ms-1"
                    size="x-small"
                    prepend-icon="mdi-pencil"
                    @click="productDialog[item.item.selectable.id] = true"
                >
                  <v-tooltip
                      activator="parent"
                      location="end"
                  >Edytuj
                  </v-tooltip>
                </v-btn>
                <v-btn class="float-end ms-1" prepend-icon="mdi-content-copy" size="x-small" fab dark color="orange"
                       @click="copyById(item.item.selectable.id)"
                       v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_PRODUCTS_COPY'])"
                >
                  <v-tooltip
                      activator="parent"
                      location="end"
                  >Kopiuj
                  </v-tooltip>
                </v-btn>

                <RemoveButton @confirm="removeItem(item.item.selectable.id)"
                              v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_PRODUCTS_REMOVE'])">
                </RemoveButton>



              </template>


              <template v-slot:[`item.price`]="price">
                <v-row v-if="cell[price.item.selectable.id] === true">
                  <v-col>
                    <v-text-field
                        class="w-100 p-0 mt-2"
                        light
                        density="compact"
                        required
                        variant="outlined"
                        v-model="prices"
                        :value="prices"
                        hide-details="auto"
                        @keyup.enter="savePrice(price.item.selectable.id)"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-btn class="mx-2 my-3" icon="mdi-content-save" size="x-small" fab dark color="blue"
                           @click="savePrice(price.item.selectable.id)">
                    </v-btn>
                  </v-col>
                </v-row>
                <span @click="editCell(price.item.selectable.id)"
                      v-if="!cell[price.item.selectable.id] || cell[price.item.selectable.id] === false">
              <v-row>
                <v-col>{{ price.item.selectable.currency }} </v-col>
                <v-col>{{ price.item.selectable.price }}</v-col>
              </v-row>
              </span>
              </template>

              <template v-slot:[`item.priceUnit`]="price">
                <v-row v-if="cell[price.item.selectable.id] === true">
                  <v-col>
                    <v-text-field
                        class="w-100 p-0 mt-2"
                        light
                        density="compact"
                        required
                        variant="outlined"
                        v-model="pricesUnit"
                        :value="pricesUnit"
                        hide-details="auto"
                        @keyup.enter="savePrice(price.item.selectable.id)"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-btn class="mx-2 my-3" icon="mdi-content-save" size="x-small" fab dark color="blue"
                           @click="savePrice(price.item.selectable.id)">
                    </v-btn>
                  </v-col>
                </v-row>
                <span @click="editCell(price.item.selectable.id)"
                      v-if="!cell[price.item.selectable.id] || cell[price.item.selectable.id] === false">{{
                    price.item.selectable.priceUnit
                  }}</span>
              </template>

            </v-data-table>
          </v-card-item>
        </v-card>
      </v-main>
    </v-layout>
  </div>
  <div v-else>
    <DenyAccessPage></DenyAccessPage>
  </div>
</template>
<script>
import axios from "axios";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";

import {mapActions, mapGetters, mapState} from "vuex";
import ProductsSingleDialogView from "@/views/products/ProductsSingleDialogView.vue";
import DenyAccessPage from "@/components/DenyAccessPage.vue";
import {useStorage} from "vue3-storage";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";

export default {
  name: "ProductsReadyView",
  components: {DenyAccessPage,RemoveButton, ProductsSingleDialogView, BreadcrumbsComponent},
  data() {
    return {
      breadcrumbs: [
        {
          title: 'Produkty handlowe',
          disabled: true,
        },
      ],

      groupBy: [{key: 'category.title', order: 'asc'}],
      loading: false,


      productDialog: [],
      dialogProduct: [],
      dialogProductNew: false,

      text: '',
      items: [],

      loadingExcel: false,
      prices: [],
      pricesUnit: [],

      expanded: [],
      singleExpand: false,

      selectedEmployees: [],
      isSelectionModeDisabled: true,
      isRecursiveDisabled: true,
      selectNodesRecursiveValue: false,
      selectByClickValue: true,
      selectionModeValue: 'multiple',

      meanUnitData: [],
      feature: [],
      categories: [],
      category: null,
      selectCategory: null,
      media: {
        saved: [],
        added: [],
        removed: []
      },
      products: {},
      product: null,

      searchMode: 'contains',
      searchValue: '',
      searchValue2: '',

      meanUnit: [
        value => !!value || 'Wymagane.',
        value => (value && value.length >= 1) || 'Min 1 znak',
      ],
      features: {},
      dialog: false,
      selectedFeatures: [],
      search: '',
      itemsPerPage: 100,
      headers: [
        {title: 'Zn.', key: 'tags', align: 'start', width: 30},
        {title: 'Nazwa', key: 'title', align: 'start', width: 200},
        {title: 'Cena', key: 'price', align: 'start'},
        {title: 'Cena JM', key: 'priceUnit', align: 'start'},
        {title: 'JM', key: 'unit.title', align: 'start'},
        {title: 'Akcja', key: 'actions', align: 'end', sortable: false},
      ],
      totalItems: 1000,
      itemId: null,
      title: '',
      id: null,
      description: '',
      server: process.env.VUE_APP_API_LOCATION + '/api/c/products/addImages/',
      cell: [],
      file: null,
    }
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken', "totalProducts", 'getRoles']),
    ...mapState(["readyProducts"]),
  },
  methods: {
    ...mapActions(['copyProduct']),
    checkRoles(roles) {
      let allRoles = this.$store.state.roles;
      if (allRoles === null) {
        allRoles = useStorage().getStorageSync("roles");
      }
      return roles.some(role => allRoles.includes(role));
    },
    loadDetails({item}) {
      item.details = 'nono';
    },

    savePrice(e) {
      let item = this.readyProducts.readyProducts.find((el) => el.id === e);
      let price = this.prices.toString();
      let priceUnit = this.pricesUnit.toString();
      item.price = parseFloat(price.replace(/,/g, '.'));
      item.priceUnit = parseFloat(priceUnit.replace(/,/g, '.'));



      axios.put(process.env.VUE_APP_API_LOCATION + '/api/products/price/' + item.id, {
        price: item.price,
        priceUnit: item.priceUnit
      }, {
        headers: {
          Authorization: `Bearer ${this.getToken}`
        }
      }).then(() => {
        // Obsłuż odpowiedź API
        //////console.logresponse.data);
        this.$notify({
          title: "Cena została zaktualizowana",
          type: 'success',
        });


      })

      this.cell[e] = false;
    },
    editCell(e) {
      let item = this.readyProducts.readyProducts.find((el) => el.id === e);
      this.prices = item.price
      this.pricesUnit = item.priceUnit
      this.cell = [];
      this.cell[e] = true;
    },

    newData() {
      this.id = null
      this.title = ''
      this.description = ''
      this.selectCategory = ''
      this.meanUnitData = []
      this.features = {}
      this.category = {}
      this.selectedFeatures = []
      this.feature = []
    },

    newItem() {
      this.$store.state.readyProducts.currentProduct = null;
      this.newData();
      this.productDialog[0] = true
    },
    getById(e) {
      this.$router.push({name: 'product', params: {id: e}})
    },
    async copyById(e) {
      await this.$store.dispatch('cloneProduct', e);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    async removeItem(e)
    {
      await this.$store.dispatch('removeReadyProduct', e);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    }
    ,
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);

      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    }
    ,
    uploadFile(event) {
      this.loadingExcel = true;
      event.preventDefault();

      const file = this.$refs.fileInput.files[0];
      const formData = new FormData();
      formData.append('file', file);

      this.dialog = false;

      this.$notify({
        title: "Plik został przesłany. Trwa aktualizacja",
        type: 'success',
      });


      // Wykonaj żądanie API przy użyciu preferowanej metody (np. Axios)
      axios.post(process.env.VUE_APP_API_LOCATION + '/api/products/import', formData)
          .then(() => {
            // Obsłuż odpowiedź API
            //////console.logresponse.data);
            this.$notify({
              title: "Aktualizacja przebiegła pomyślnie",
              type: 'success',
            });
            this.loadingExcel = false;
          })
          .catch(() => {
            // Obsłuż ewentualne błędy
            //console.error(error);
            this.$notify({
              title: "Wystąpił problem podczas aktualizacji produktów",
              type: 'error',
            });
            this.loadingExcel = false;
          });
    },
  },
}
</script>

<style>
table td {
  font-size: 10px;
}

table .v-field__input {
  padding: 5px;
  font-size: 10px;
  min-height: auto;
}

table .v-btn--icon.v-btn--density-default {
  width: 20px;
  height: 20px;
}

table .mdi:before {
  font-size: 11px !important;
}

.v-table__wrapper {
  border-radius: inherit;
  overflow: hidden;
  padding-bottom: 5px;
}

</style>
