<template>
  <div v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER', 'ROLE_TYPES_PROJECT_READ'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
            <ItemView :idItem="null" buttonTitle="Dodaj" class="float-end"
                      style="margin-top:6px; float:end;"></ItemView>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              density="compact"
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items-length="totalItems"
              :items="types.types"
              :loading="loading"
              :search="search"
              class="elevation-1"
              item-value="id"
          >

            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.selectable.id }}</td>
                <td>{{ item.selectable.name }}</td>
                <td align="end">
                  <RemoveButton @confirm="fetchDeleteType(item.selectable.id)"
                                v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_TYPES_PROJECT_REMOVE'])">
                  </RemoveButton>
                  <v-btn
                      color="blue"
                      class="mx-2 float-end"
                      size="x-small"
                      prepend-icon="mdi-plus"
                      @click="dialog[item.selectable.id] = true"
                  >Edytuj
                  </v-btn>
                  <ItemView :idItem="item.selectable.id" @dialogClose="dialog[item.selectable.id] = false"
                            :dialogItem="dialog[item.selectable.id]" buttonTitle="Edytuj"
                            v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_TYPES_PROJECT_SAVE'])"></ItemView>

                </td>
              </tr>
            </template>

          </v-data-table>
        </v-card>
      </v-main>
    </v-layout>


  </div>
  <div v-else>
    <DenyAccessPage></DenyAccessPage>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import DenyAccessPage from "@/components/DenyAccessPage.vue";
import {useStorage} from "vue3-storage";
import ItemView from "@/views/dictionary/typesProject/ItemView.vue";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";

export default {
  name: 'TypesProjectView',
  components: {RemoveButton, ItemView, DenyAccessPage, BreadcrumbsComponent},
  data: () => {
    return {
      name: '',
      description: '',
      logo: null,
      breadcrumbs: [
        {
          title: 'Typy projektów',
          disabled: true,
        },

      ],
      dialog: [],
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'name', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      loading: false,
      totalItems: null,
      itemId: null,
      items: [],
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken', "getRoles"]),
    ...mapState(["types"])
  },
  mounted() {
    this.totalItems = this.types.types.length;
  },
  methods: {
    ...mapActions(['addType', 'loadTypes']),
    async fetchAddType(idType) {

      let data = {
        name: this.name,
        description: this.description,
        logo: this.logo
      }

      if (idType) {
        await this.$store.dispatch('updateType', {item: data, idType: idType});
      } else {
        await this.$store.dispatch('addType', data);
      }
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      if (this.$store.state.users.responseStatus === 'success') {
        //await this.loadUnits();
        this.dialog = false;
        this.itemId = null;
      }
    },
    async fetchDeleteType(idType) {
      await this.$store.dispatch('removeType', idType);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    editItem(item) {
      this.dialog = true
      this.name = item.name
      this.itemId = item.id
    },
    checkRoles(roles) {
      let allRoles = this.$store.state.roles;
      if (allRoles === null) {
        allRoles = useStorage().getStorageSync("roles");
      }
      return roles.some(role => allRoles.includes(role));
    },
  }
}
</script>
