<template>
  <div v-if="checkRoles(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items-length="totalItems"
              :items="histories.histories"
              :loading="loading"
              :search="search"
              class="elevation-0"
              item-value="id"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.selectable.id }}</td>
                <td>{{ item.selectable.user }}</td>
                <td>{{ item.selectable.endpoint }}</td>
                <td>{{ item.selectable.type }}</td>
                <td>{{ item.selectable.value }}</td>
                <td>{{ item.selectable.args }}</td>
                <td>{{ formatDate(item.selectable.createdAt) }}</td>

              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-main>
    </v-layout>
  </div>
  <div v-else>
    Przykro nam, ale nie posiadasz uprawnień do przeglądania tej strony
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import {useStorage} from "vue3-storage";
export default {
  name: 'HistoryView',
  components: {BreadcrumbsComponent},
  data: () => {
    return {
      title: '',
      breadcrumbs: [
        {
          title: 'Administracja',
          disabled: true,
        },
        {
          title: 'Historia',
          disabled: true,
        },
      ],
      dialog: false,
      search: '',
      itemsPerPage: 50,
      headers: [
        {title: 'ID', align: 'start', key: 'id',},
        {title: 'Użytkownik', key: 'user', align: 'start'},
        {title: 'Endpoint', key: 'endpoint', align: 'start'},
        {title: 'Typ', key: 'type', align: 'start'},
        {title: 'Wartosć', key: 'value', align: 'start'},
        {title: 'Args', key: 'args', align: 'start'},
        {title: 'Data', key: 'createdAt', align: 'start'},
      ],
      //loaders
      loadingSaveItem: false,
      loading: false,
      totalItems: 10,
      itemId: null,
      items: [],
      selectedFeatures: [],
      examples: null
    };
  },
  computed: {
    ...mapState(["histories"])
  },
  created(){
    this.loadHistories();
  },
  methods: {
    ...mapActions([
      'loadHistories'
    ]),
    checkRoles(roles) {
      let allRoles = this.$store.state.roles = useStorage().getStorageSync("roles");
      return roles.some(role => allRoles.includes(role));
    },
    formatDate(date) {
      // Format the date as needed
      return new Date(date).toLocaleDateString('pl-PL', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });
    },
  }
}
</script>
