<template>
  <v-layout ref="app">
    <v-app-bar density="compact" class="px-2">
      <v-row>
        <v-col>
          <BreadcrumbsComponent :items="breadcrumbs"/>
        </v-col>
        <v-col>
          <v-btn
              v-if="checkRoles(['ROLE_USERS_READ','ROLE_ADMIN'])"
              color="red"
              style="margin-top:6px;"
              class="float-end mx-2"
              size="x-small"
              prepend-icon="mdi-format-list-bulleted-square"
              to="/users"
          >
            Lista użytkowników
          </v-btn>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-main class="mt-3">
      <v-card v-if="loading === false">
        <v-tabs v-model="tab">
          <v-tab value="one">
            <v-icon left> mdi-account-cog</v-icon>
            Edytuj profil
          </v-tab>
          <v-tab value="two" v-if="checkRoles(['ROLE_ADMIN'])">
            <v-icon left> mdi-lock</v-icon>
            Uprawnienia
          </v-tab>
          <v-tab value="three">
            <v-icon left> mdi-lock</v-icon>
            Zmiana hasła
          </v-tab>
        </v-tabs>
        <v-card-text>
          <v-window v-model="tab">
            <v-window-item value="one">
              <v-row>
                <v-col>
                  <v-text-field
                      prepend-icon="mdi-email"
                      name="email"
                      label="Email"
                      density="compact"
                      type="text"
                      v-model="email"
                      style="margin-top:6px;"
                      :rules="emailRules"
                  ></v-text-field>
                  <v-row>
                    <v-col>
                      <v-text-field
                          prepend-icon="mdi-account"
                          name="name"
                          label="Imię"
                          density="compact"
                          type="text"
                          v-model="firstName"
                          :rules="firstNameRules"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                          prepend-icon="mdi-account"
                          name="lastName"
                          label="Nazwisko"
                          density="compact"
                          type="text"
                          v-model="lastName"
                          :rules="lastNameRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <SaveButton @click="saveUser" label="Zapisz" style="margin-bottom:10px;"></SaveButton>
                </v-col>
                <v-col class="text-center">
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item value="two">
              <v-table density="compact">
                <thead>
                <tr>
                  <th colspan="2">Główne uprawnienia
                    <div>
                      <p>Aktualna rola użytkownika: {{ userRole }}</p>
                      <button @click="logUserRole">Wypisz rolę użytkownika w konsoli</button>
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="role_super_admin"
                             :checked="roles.indexOf('ROLE_SUPER_ADMIN')> -1"
                             @change="updateRole('ROLE_SUPER_ADMIN')">
                      <label class="form-check-label" for="role_super_admin">
                        Super Administrator
                      </label>
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="role_admin"
                             :checked="roles.indexOf('ROLE_ADMIN')> -1"
                             @change="updateRole('ROLE_ADMIN')">
                      <label class="form-check-label" for="role_admin">
                        Administrator
                      </label>
                    </div>
                  </td>
                </tr>
                </tbody>

              </v-table>

              <v-table density="compact">
                <thead>
                <tr>
                  <th colspan="2">Ustawienia dodatkowe</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="role_super_admin"
                             :checked="roles.indexOf('ROLE_BUILD_TREE')> -1"
                             @change="updateRole('ROLE_BUILD_TREE')">
                      <label class="form-check-label" for="role_super_admin">
                        Tworzenie drzewa produktów
                      </label>
                    </div>
                  </td>
                  <td>
                  </td>
                </tr>
                </tbody>

              </v-table>


              <v-table density="compact">
                <thead>
                <tr>
                  <th class="text-left">
                    Sekcja
                  </th>
                  <th class="text-left">
                    Odczyt
                  </th>
                  <th class="text-left">
                    Zapis
                  </th>
                  <th class="text-left">
                    Usuwanie
                  </th>
                  <th class="text-left">
                    Wszystkie
                  </th>
                </tr>
                </thead>

                <tbody>
                <tr>
                  <th colspan="5">
                    Słowniki
                  </th>
                </tr>
                <tr>
                  <td>Jednostki</td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_UNITS_READ')> -1"
                             @change="updateRole('ROLE_UNITS_READ')">
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_UNITS_POST')> -1"
                             @change="updateRole('ROLE_UNITS_POST')">
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_UNITS_REMOVE')> -1"
                             @change="updateRole('ROLE_UNITS_REMOVE')">
                    </div>
                  </td>
                  <td></td>
                </tr>

                <tr>
                  <td>Cechy</td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_FEATURES_READ')> -1"
                             @change="updateRole('ROLE_FEATURES_READ')">
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_FEATURES_SAVE')> -1"
                             @change="updateRole('ROLE_FEATURES_SAVE')">
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_FEATURES_REMOVE')> -1"
                             @change="updateRole('ROLE_FEATURES_REMOVE')">
                    </div>
                  </td>
                  <td></td>
                </tr>

                <tr>
                  <td>Kategorie</td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_CATEGORIES_READ')> -1"
                             @change="updateRole('ROLE_CATEGORIES_READ')">
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_CATEGORIES_SAVE')> -1"
                             @change="updateRole('ROLE_CATEGORIES_SAVE')">
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_CATEGORIES_REMOVE')> -1"
                             @change="updateRole('ROLE_CATEGORIES_REMOVE')">
                    </div>
                  </td>
                  <td></td>
                </tr>

                <tr>
                  <td>Zmienne</td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_VARIABLES_READ')> -1"
                             @change="updateRole('ROLE_VARIABLES_READ')">
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_VARIABLES_SAVE')> -1"
                             @change="updateRole('ROLE_VARIABLES_SAVE')">
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_VARIABLES_REMOVE')> -1"
                             @change="updateRole('ROLE_VARIABLES_REMOVE')">
                    </div>
                  </td>
                  <td></td>
                </tr>

                <tr>
                  <td>Kategorie</td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_CATEGORIES_READ')> -1"
                             @change="updateRole('ROLE_CATEGORIES_READ')">
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_CATEGORIES_SAVE')> -1"
                             @change="updateRole('ROLE_CATEGORIES_SAVE')">
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_CATEGORIES_REMOVE')> -1"
                             @change="updateRole('ROLE_CATEGORIES_REMOVE')">
                    </div>
                  </td>
                  <td></td>
                </tr>

                <tr>
                  <th colspan="5">
                    Funkcje główne
                  </th>
                </tr>

                <tr>
                  <td>Zlecenia</td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_TICKETS_READ')> -1"
                             @change="updateRole('ROLE_TICKETS_READ')">
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_TICKETS_SAVE')> -1"
                             @change="updateRole('ROLE_TICKETS_SAVE')">
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_TICKETS_REMOVE')> -1"
                             @change="updateRole('ROLE_TICKETS_REMOVE')">
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_TICKETS_READ_ALL')> -1"
                             @change="updateRole('ROLE_TICKETS_READ_ALL')">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Projekty</td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_PROJECTS_READ')> -1"
                             @change="updateRole('ROLE_PROJECTS_READ')">
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_PROJECTS_SAVE')> -1"
                             @change="updateRole('ROLE_PROJECTS_SAVE')">
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_PROJECTS_REMOVE')> -1"
                             @change="updateRole('ROLE_PROJECTS_REMOVE')">
                    </div>
                  </td>
                  <td>
                  </td>
                </tr>
                <tr>
                  <td>Produkty handlowe</td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_PRODUCTS_READ')> -1"
                             @change="updateRole('ROLE_PRODUCTS_READ')">
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_PRODUCTS_SAVE')> -1"
                             @change="updateRole('ROLE_PRODUCTS_SAVE')">
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_PRODUCTS_REMOVE')> -1"
                             @change="updateRole('ROLE_PRODUCTS_REMOVE')">
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>Użytkownicy</td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_USERS_READ')> -1"
                             @change="updateRole('ROLE_USERS_READ')">
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_USERS_SAVE')> -1"
                             @change="updateRole('ROLE_USERS_SAVE')">
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_USERS_DELETE')> -1"
                             @change="updateRole('ROLE_USERS_DELETE')">
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_USERS_READ_ALL')> -1"
                             @change="updateRole('ROLE_USERS_READ_ALL')">
                    </div>
                  </td>
                </tr>

                <tr>
                  <th colspan="5">Typy projektów</th>
                </tr>
                <tr v-for="type in types.types" :key="type">
                  <td>{{ type.name }}</td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             :checked="roles.indexOf('ROLE_TYPE_PROJECT_' + type.id)> -1"
                             @change="updateRole('ROLE_TYPE_PROJECT_' + type.id)">
                    </div>
                  </td>
                </tr>


                </tbody>

              </v-table>
              <SaveButton @click="saveUser" label="Zapisz" style="margin-bottom:10px;"></SaveButton>

            </v-window-item>

            <v-window-item value="three">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                      id="oldPassword"
                      prepend-icon="mdi-lock"
                      label="Stare hasło"
                      type="password"
                      density="compact"
                      v-model="oldPassword"
                      style="margin-top:6px;"
                  ></v-text-field>
                  <v-text-field
                      id="newPassword"
                      prepend-icon="mdi-lock"
                      label="Nowe hasło"
                      type="password"
                      density="compact"
                      :rules="newPasswordRules"
                      v-model="newPassword"
                      style="margin-top:6px;"
                  ></v-text-field>
                  <v-text-field
                      id="repeatPassword"
                      prepend-icon="mdi-lock"
                      label="Powtórz nowe hasło"
                      type="password"
                      density="compact"
                      :rules="repeatPasswordRules"
                      v-model="repeatPassword"
                  ></v-text-field>
                  <v-alert v-show="!isPasswordMatch" text="Podane hasła są różne" class="my-4" type="error"></v-alert>
                </v-col>
              </v-row>
              <SaveButton @click="resetPassword" label="Zapisz" style="margin-bottom:10px;"></SaveButton>
            </v-window-item>
          </v-window>
        </v-card-text>
      </v-card>
      <div v-else-if="loading === true">
        <div class="text-center">
          <v-progress-circular
              indeterminate
              color="red"
              :size="70"
              :width="7"
          ></v-progress-circular>
        </div>
      </div>
    </v-main>
  </v-layout>
</template>
<script>
import axios from 'axios';
import {mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import {useStorage} from "vue3-storage";
import SaveButton from "@/components/navigation/element/SaveButton.vue";

export default {
  name: 'UserProfileView',
  components: {SaveButton, BreadcrumbsComponent},
  data: () => {
    return {
      roles: null,
      user: null,
      breadcrumbs: [
        {
          title: 'Użytkownicy',
          disabled: false,
          to: '/users',
        },
        {
          title: 'Profil użytkownika',
          disabled: true,
        },
      ],
      tab: null,
      loading: true,
      userData: null,
      description: null,
      isPasswordMatch: true,

      email: "",
      emailRules: [
        value => {
          if (value) return true

          return 'E-mail jest wymagany.'
        },
        value => {
          if (/.+@.+\..+/.test(value)) return true

          return 'E-mail musi być prawidłowy'
        },
      ],
      firstName: "",
      firstNameRules: [
        value => {
          if (value) return true
          return 'Wymagane'
        },
      ],
      lastName: "",
      lastNameRules: [
        value => {
          if (value) return true
          return 'Wymagane'
        },
      ],
      oldPassword: "",
      newPassword: "",
        newPasswordRules: [
        value => {
          if (value) return true
          return 'Hasło jest wymagane.'
        },
        value => {
          if (/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).*$/.test(value)) return true

          return 'Twoje hasło musi zawierać co najmniej jedną wielką literę, jedną małą literę, jedną cyfrę i jeden znak specjalny.'
        },
      ],
      repeatPassword: "",
      repeatPasswordRules: [
        value => {
          if (value) return true
          return 'Hasło jest wymagane.'
        },
        value => {
          if (/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).*$/.test(value)) return true

          return 'Twoje hasło musi zawierać co najmniej jedną wielką literę, jedną małą literę, jedną cyfrę i jeden znak specjalny.'
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['getToken', 'getRoles']),
    ...mapState(["types"])
  },
  watch: {
    repeatPassword() {
      if (this.newPassword !== this.repeatPassword) {
        this.isPasswordMatch = false
      } else {
        this.isPasswordMatch = true
      }
    }
  },
  mounted() {
    this.getUserData();
  },
  methods: {

    checkRoles(roles) {
      let allRoles = this.$store.state.roles;
      if (allRoles === null) {
        allRoles = useStorage().getStorageSync("roles");
      }
      return roles.some(role => allRoles.includes(role));
    },
    logUserRole() {
      //const userRole = this.getRoles;
      //console.log('Rola użytkowndika:', userRole);
    },
    async getUserData() {
      if (this.$route.params.id !== "") {

        axios.get(process.env.VUE_APP_API_LOCATION + "/api/users/" + this.$route.params.id, {
          headers: {
            Authorization: `Bearer ${this.getToken}`
          }
        })
            .then(response => {
              this.user = response.data;
              this.email = response.data.email;
              this.firstName = response.data.firstName;
              this.lastName = response.data.lastName;
              this.description = response.data.description;
              this.user = response.data;
              this.roles = this.user.roles;
            })
            .catch(error => {
              this.$notify({
                title: error,
                type: 'error',
              });
              this.$router.go(-1)
            });

        this.loading = false;
      } else {
        this.$router.go(-1)
      }

    },
    resetPassword() {
      if (this.newPassword !== this.repeatPassword) {
        this.isPasswordMatch = false;
        return;
      }

      this.isPasswordMatch = true;

      axios.put(process.env.VUE_APP_API_LOCATION + '/api/reset-password', {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword
      }, {
        headers: {
          Authorization: `Bearer ${this.getToken}`
        }
      })
          .then(response => {
            this.items = response.data;
            this.$notify({
              title: 'Hasło zostało zmienione',
              type: 'success',
            });
          })
          .catch(error => {
            ////console.logerror.response.data.detail);
            this.$notify({
              title: error.response,
              type: 'error',
            });
          });

      // Call your API endpoint to reset the password
      // Pass the newPassword value to the API

      // Reset the input fields
      this.newPassword = '';
      this.repeatPassword = '';

    },
    updateRole(controller) {
      let find = this.roles.find((el) => el === controller)
      if (find) {
        let index = this.roles.indexOf(find);
        if (index > -1) {
          this.roles.splice(index, 1);
        }
      } else {
        this.roles.push(controller)
      }
    },
    saveUser() {
      axios.put(process.env.VUE_APP_API_LOCATION + '/api/user/update/' + this.$route.params.id, {
        roles: this.roles,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        description: this.description
      }, {
        headers: {
          Authorization: `Bearer ${this.getToken}`
        }
      })
          .then(response => {
            this.items = response.data;
            this.$notify({
              title: 'Zmiany zostały zapisane',
              type: 'success',
            });
          })
          .catch(error => {
            console.error(error);
            this.$notify({
              title: error,
              type: 'error',
            });
          });
    }
  }
}
</script>
