<template>
  <div v-if="checkRoles(['ROLE_ADMIN'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
            <v-dialog
                v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_CUSTOMER_SAVE'])"
                v-model="dialog"
                width="600"
                height="600"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                    color="blue"
                    style="margin-top:6px;"
                    class="float-end"
                    v-bind="props"
                    size="x-small"
                    prepend-icon="mdi-plus"
                >
                  Dodaj nowego klienta
                </v-btn>
              </template>
              <CustomerForm @annulled="dialog = false"></CustomerForm>
            </v-dialog>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              density="compact"
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items-length="totalItems"
              :items="customers.customers"
              :loading="loading"
              :search="search"
              class="elevation-0"
              item-value="id"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.selectable.id }}</td>
                <td>{{ item.selectable.firstName }}</td>
                <td>{{ item.selectable.lastName }}</td>
                <td align="end">
<!--                  <v-btn class="mx-2" append-icon="mdi-pencil-box" size="x-small" fab dark color="blue"
                         @click="editItem(item.selectable)">
                    Edytuj
                  </v-btn>-->
                  <CustomerDetail :customer-id="item.selectable.id"></CustomerDetail>
                  <RemoveButton @confirm="fetchDelete(item.selectable.id)"
                                v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_CUSTOMERS_REMOVE'])">
                  </RemoveButton>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-main>
    </v-layout>
  </div>
  <div v-else>
    Przykro nam, ale nie posiadasz uprawnień do przeglądania tej strony
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import {useStorage} from "vue3-storage";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";
import CustomerDetail from "@/views/customers/CustomerDetail.vue";
import CustomerForm from "@/components/CustomerForm.vue";

export default {
  name: 'CategoriesView',
  components: {CustomerForm, CustomerDetail, RemoveButton, BreadcrumbsComponent},
  data: () => {
    return {
      rules: {
        required: value => !!value || 'Wymagane.',
        counter: value => value.length <= 20 || 'Max 20 znaków',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      title: '',
      breadcrumbs: [
        {
          title: 'Klienci',
          disabled: true,
        },
      ],
      dialog: false,
      search: '',
      itemsPerPage: 50,
      isFormValid: false,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Imię', key: 'firstName', align: 'start'},
        {title: 'Nazwisko', key: 'lastName', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      //loaders
      loadingSaveItem: false,
      loading: false,
      totalItems: 10,
      itemId: null,
      items: [],
      selectedFeatures: [],
      examples: null,


      //Client
      emailCustomer: "",
      name: "",
      lastName: "",
      street: "",
      homeNumber: "",
      postcode: "",
      city: "",
      phone: "",

      onlyLettersRules: [
        value => {
          if (!/\d/.test(value)) return true
          return 'Pole nie może zawierać liczb.'
        },
      ],
      phoneRules: [
        value => {
          if (/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/.test(value) || !value) return true
          return 'Numer telefonu może zawierać wyłącznie cyfry (minimum 9) i dodatkowo znak "+".'
        },
      ],
      emailRules: [
        value => {
          if (/.+@.+\..+/.test(value) || !value) return true
          return 'E-mail musi być prawidłowy'
        },
      ],

    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken', "getRoles"]),
    ...mapState(["customers"])
  },
  methods: {
    ...mapActions(['addCategory']),
    async fetchAdd(id) {
      this.loadingSaveItem = true;
      if (id) {
        await this.$store.dispatch('updateExample', {item: {title: this.title}, id: id});
      } else {
        await this.$store.dispatch('addExample', {title: this.title,});
      }
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      if (this.$store.state.users.responseStatus === 'success') {
        this.dialog = false;
        this.itemId = null;
      }
      this.loadingSaveItem = false;
    },
    async fetchDelete(id) {
      await this.$store.dispatch('removeCustomer', id);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    editItem(item) {
      this.dialog = true
      this.title = item.title
      this.itemId = item.id
    },
    checkRoles(roles) {
      let allRoles = this.$store.state.roles = useStorage().getStorageSync("roles");
      return roles.some(role => allRoles.includes(role));
    },

    save(){
      //await this.$store.dispatch('addCustomer', data);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    }

  }
}
</script>
