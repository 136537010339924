<template>
  <v-dialog
      v-model="$props.dialogItem"
      width="600"
      height="500"
  >
    <v-card>
      <v-card-text>
        <v-form v-model="isFormValid">

        <v-text-field
              label="Rodzaj projektu"
              density="compact"
              v-model="name"
              hide-details="auto"
              class="mb-5"
              counter
              maxlength="20"
              :rules="nameRule"
          ></v-text-field>
          <v-row>
            <v-col>
              <v-textarea label="Opis" v-model="description" :rules="descriptionRule"></v-textarea>
            </v-col>
            <v-col v-if="idItem">
              <v-img v-if="logo" :src="process.env.VUE_APP_API_LOCATION + '/types/' + logo"></v-img>
              <v-file-input
                  accept="image/*"
                  label="Wgraj logo"
                  @change="loadImage"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <ActionsButton @annulled="$emit('dialogClose')" @save="save" :disabledSave="!isFormValid"></ActionsButton>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import ActionsButton from "@/components/navigation/ActionsButton.vue";

export default {
  name: "ItemView",
  components: {ActionsButton},
  props: ['idItem','buttonTitle', 'style', 'dialogItem'],
  data: () => {
    return {
      dialog:false,

      //Data
      name: null,
      description:null,
      logo:null,
      isFormValid: false,
      nameRule: [
        value => {
          if (value) return true
          return 'Wymagane.'
        },
        value => {
          if (value.length <= 20) return true
          return 'Maksymalna liczba znaków to 20.'
        },
      ],
      descriptionRule: [
        value => {
          if (!value || value.length <= 255) return true
          return 'Maksymalna liczba znaków to 255.'
        },
      ],
    }
  },
  computed:{
    ...mapGetters(['getTypeById'])
  },
  created(){
    this.load();
  },
  methods:{
    async save(){
      let data = {
        name: this.name,
        description: this.description,
      }
      if(this.$props.idItem){
        await this.$store.dispatch('updateType', {item: data, idType: this.$props.idItem});
      }else{
        await this.$store.dispatch('addType', data);
      }
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      this.$emit('dialogClose');
    },
    async load(){
      if(this.$props.idItem){
        const item = await this.getTypeById(this.$props.idItem);

        console.log('ladujemy', item);

        this.name = item.name;
        this.description = item.description;
        this.logo = item.logo ?? '';
      }
    },
    async loadImage(event){
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
      await this.$store.dispatch('addLogo', {data: formData, id: this.$props.idItem});
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    }

  }

}
</script>

<style scoped>

</style>
