import axios from "axios";

export const histories = {
    state: {
        histories: [],
        responseStatus: '',
        responseMessage: ''
    },
    getters: {
        getHistories: state => {
            return state.histories;
        },
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        }
        ,
        setResponseStatus(state, status) {
            state.responseStatus = status;
        }
        ,
        setHistories(state, items) {
            state.histories = items;
            //console.log(state.histories);
        },
    },
    actions: {
        async loadHistories({commit, rootState }) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_LOCATION + '/api/histories',
                    {headers: {Authorization: `Bearer ${rootState .token}`}});
                const items = response.data;
                commit('setHistories', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },


    }
}
