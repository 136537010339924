import axios from "axios";
export const projects = {
    state: {
        projects: [],
        currentIdProject: null,
    },
    getters: {
        getProjects: state => {
            return state.projects;
        },
        getProjectById: (state) => (id) => {

            //console.log('Przed zaczytaniem', state.projects.find(item => item.id === Number(id)));

            return state.projects.find(item => item.id === Number(id));
        },
    },
    mutations: {
        setProjects(state, items) {
            state.projects = items;
        },
        removeProject(state, itemId) {
            state.projects = state.projects.filter(item => item.id !== itemId);
        },
        addProject(state, items) {
            state.projects.push(items);
        },
        currentIdProject(state, item) {
            state.currentIdProject = item;
        },
        updateProject(state, data) {
            const index = state.projects.findIndex(item => Number(item.id) === Number(data.id));
            state.projects.splice(index, 1, data);
        },
    },
    actions: {
        async loadProjects({commit, rootState }) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_LOCATION + '/api/projects',
                    {headers: {Authorization: `Bearer ${rootState .token}`}});
                const items = response.data;
                commit('setProjects', items);
            } catch (error) {
                //console.error('Error loading items:', error);
            }
        },
        async removeProject({commit, rootState }, itemId) {
            try {
                // Perform the asynchronous task to remove an item
                await axios.delete(process.env.VUE_APP_API_LOCATION + `/api/projects/${itemId}`,
                    {headers: {Authorization: `Bearer ${rootState .token}`}});

                // Commit the mutation to update the state
                commit('removeProject', itemId);
            } catch (error) {
                commit('setNetworkError', true);
            }
        },
        async addProject({commit, rootState }, data) {

            //////console.log'addProject', data)

            try {
                await axios.post(process.env.VUE_APP_API_LOCATION + '/api/projects',
                    data,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(response => {
                        const items = response.data;

                        //////console.logitems);

                            commit('addProject', items);
                            commit('currentIdProject', items.id);
                            commit('setResponseMessage', 'Projekt został dodany');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateProject({commit, rootState }, data) {
            try {
                await axios.put(process.env.VUE_APP_API_LOCATION + '/api/projects/' + data.idProject,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then((response) => {
                            commit('updateProject', response.data);
                            commit('setResponseMessage', 'Projekt został zaktualizowany');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        //////console.logerror);
                        commit('setResponseMessage', '0:' + error);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', '1:' + error);
                commit('setResponseStatus', 'error');
            }
        },
    }
}
