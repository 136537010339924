import axios from "axios";
export const categories = {
    state: {
        categories: [],
        responseStatus: '',
        responseMessage: ''
    },
    getters: {
        getCategories: state => {
            return state.categories;
        },
        getCategoriesOwn: state => {
            return state.categories.filter((c) => c.type === 'own');
        },
        getCategoriesCommercial: state => {
            return state.categories.filter((c) => c.type !== 'own');
        },
        getCategoryById: (state) => (id) => {
            return state.categories.find(item => item.id === Number(id));
        }
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        },
        setResponseStatus(state, status) {
            state.responseStatus = status;
        },
        setCategories(state, items) {
            state.categories = items;
        },
        addCategory(state, items) {
           //////console.log'addCategory',items);
            state.categories.push(items);
        },
        updateCategory(state, data) {
           console.log('updateCategory',data, state.categories);
            const index = state.categories.findIndex(item => Number(item.id) === Number(data.id));
            state.categories.splice(index, 1, data);
        },
        removeCategory(state, itemId) {
            state.categories = state.categories.filter(item => item.id !== itemId);
        },
    },
    actions: {
        async loadCategories({commit, rootState }) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_LOCATION + '/api/categories',
                    {headers: {Authorization: `Bearer ${rootState .token}`, Accept: 'application/json'}});
                const items = response.data;
                commit('setCategories', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async addCategory({commit, rootState }, data) {
            try {
                await axios.post(process.env.VUE_APP_API_LOCATION + '/api/categories',
                    data,
                    {headers: {Authorization: `Bearer ${rootState .token}`, Accept: 'application/json'}})
                    .then(response => {
                        const items = response.data;
                        commit('addCategory', items);
                        commit('setResponseMessage', 'Kategoria została dodana');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateCategory({commit, rootState }, data) {
            try {

                console.log('udpate', data);

                await axios.put(process.env.VUE_APP_API_LOCATION + '/api/categories/' + data.idCategory,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState .token}`, Accept: 'application/json'}})
                    .then(response => {
                        const items = response.data;
                        commit('updateCategory', items);
                        commit('setResponseMessage', 'Kategoria została zaktualizowana');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async removeCategory({commit, rootState }, itemId) {
            try {
                await axios.delete(process.env.VUE_APP_API_LOCATION + '/api/categories/' + itemId,
                    {headers: {Authorization: `Bearer ${rootState .token}`, Accept: 'application/json'}})
                    .then(() => {
                        commit('removeCategory', itemId);
                        commit('setResponseMessage', 'Kategoria została usunięta');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
    }
}
