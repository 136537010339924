<template>
  <v-row>
    <v-col>
      <v-card
          class="mx-auto elevation-0 rounded-lg"
          max-width="344"
          v-if="checkRoles(['ROLE_PROJECTS_READ','ROLE_ADMIN'])"
          >
        <v-list-item three-line >
          <v-list-item-content>
            <div class="text-overline mb-4">
              Projekty
            </div>
            <v-list-item-title class="text-h5 mb-1">
              Projekty
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-avatar
              tile
              size="80"
              color="grey"
          ></v-list-item-avatar>
        </v-list-item>

        <v-card-actions>
          <v-btn
              outlined
              rounded
              text
              to="/projects/"
          >
            Zobacz
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col>
      <v-card
          class="mx-auto elevation-0 rounded-lg"
          max-width="344"
          v-if="checkRoles(['ROLE_PROJECTS_READ','ROLE_ADMIN'])"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">
              Gałęzie
            </div>
            <v-list-item-title class="text-h5 mb-1">
              Gałęzie
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-avatar
              tile
              size="80"
              color="grey"
          ></v-list-item-avatar>
        </v-list-item>

        <v-card-actions>
          <v-btn
              outlined
              rounded
              text
              to="/projects/element"
          >
            Zobacz
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col>
      <v-card
          class="mx-auto elevation-0 rounded-lg"
          max-width="344"
          v-if="checkRoles(['ROLE_TICKETS_READ','ROLE_ADMIN'])"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">
              Zlecenia
            </div>
            <v-list-item-title class="text-h5 mb-1">
              Zlecenia
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-avatar
              tile
              size="80"
              color="grey"
          ></v-list-item-avatar>
        </v-list-item>

        <v-card-actions>
          <v-btn
              outlined
              rounded
              text
              to="/tickets/"
          >
            Zobacz
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col>
      <v-card
          class="mx-auto elevation-0 rounded-lg"
          max-width="344"
          v-if="checkRoles(['ROLE_PRODUCTS_READ','ROLE_ADMIN'])"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">
              Produkty handlowe
            </div>
            <v-list-item-title class="text-h5 mb-1">
              Produkty handlowe
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-avatar
              tile
              size="80"
              color="grey"
          ></v-list-item-avatar>
        </v-list-item>

        <v-card-actions>
          <v-btn
              outlined
              rounded
              text
              to="/products/ready"
          >
            Zobacz
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>


<script>
// @ is an alias to /src

import { mapGetters } from "vuex";
import {useStorage} from "vue3-storage";
export default {
  name: 'HomeView',
  data: () => {
    return {
      users: [],
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn",'getToken']),
  },
  methods:{
    checkRoles(roles) {
      let allRoles = this.$store.state.roles;
      if (allRoles === null) {
        allRoles = useStorage().getStorageSync("roles");
      }
      return roles.some(role => allRoles.includes(role));
    },
  }
}
</script>
