import axios from "axios";
export const tickets = {
    state: {
        tickets: [],
        currentId: null,
        currentIdCustomers: null,
        currentIdCustomersAddress: null,
    },
    getters: {
        getTickets: state => {
            return state.tickets;
        },
        getTicketById: (state) => (id) => {

            ////console.logstate.tickets);

            return state.tickets.find(item => Number(item.id) === Number(id));
        },
    },
    mutations: {

        setCurrentId(state, currentId) {
            ////console.log'setCurrentId',currentId);
            state.currentId = currentId;
        },

        setCurrentIdCustomers(state, currentId) {
            ////console.log'setCurrentIdCustomers',currentId);
            state.currentIdCustomers = currentId;
        },

        setCurrentIdCustomersAddress(state, currentId) {
            ////console.log'setCurrentIdCustomers',currentId);
            state.currentIdCustomersAddress = currentId;
        },
        setTickets(state, items) {
            state.tickets = items;
        },
        removeTicket(state, itemId) {
            state.tickets = state.tickets.filter(item => item.id !== itemId);
        },
        addTicket(state, items) {
            state.tickets.push(items);
        },
        updateTicket(state, data) {
            const index = state.tickets.findIndex(item => item.id === data.id);
            let dataItem = data.item;

            ////console.logdataItem);

            dataItem.id = data.id;
            state.tickets.splice(index, 1, dataItem);
            return data;
        },
    },
    actions: {
        async loadTickets({commit, rootState }) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_LOCATION + '/api/tickets',
                    {headers: {Authorization: `Bearer ${rootState .token}`}});
                const items = response.data;
                commit('setTickets', items);
            } catch (error) {
                //console.error('Error loading items:', error);
            }
        },
        async removeTicket({commit, rootState }, itemId) {
            try {
                // Perform the asynchronous task to remove an item
                await axios.delete(process.env.VUE_APP_API_LOCATION + `/api/tickets/${itemId}`,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(() => {
                        // Commit the mutation to update the state
                        commit('removeTicket', itemId);
                        commit('setResponseMessage', 'Zlecenie zostało usunięte');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });


            } catch (error) {
                commit('setNetworkError', true);
            }
        },
        async addTicket({commit, rootState }, data) {
            try {
                await axios.post(process.env.VUE_APP_API_LOCATION + '/api/tickets',
                    data,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('addTicket', items);
                        commit('setResponseMessage', 'Zlecenie zostało dodane');
                        commit('setResponseStatus', 'success');
                        commit('setCurrentId', items.id);

                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateTicket({commit, rootState }, data) {
            ////console.log'updateTicket in', data.item)
            //try {
                await axios.put(process.env.VUE_APP_API_LOCATION + '/api/tickets/' + data.idTicket,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then((response) => {

                        //console.log(response);

                        let newData = [];
                        newData.item = response.data;
                        newData.id = data.idTicket;
                        data.id = data.idTicket;
                        data.modifiedAt = response.data.modifiedAt;

                        commit('setCurrentIdCustomers', response.data.customers.id);
                        if(response.data.customers.address !== null){
                            commit('setCurrentIdCustomersAddress', response.data.customers.address.id);
                        }

                        console.log(response.data);

                        commit('addCustomer', response.data.customers);

                        commit('updateTicket', newData);
                        commit('setResponseMessage', 'Zlecenie zostało zaktualizowane');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {

                        //console.log(error);

                        commit('setResponseMessage', error.response);
                        commit('setResponseStatus', 'error');
                    });
            /*} catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }*/
        },
    }
}
