<template>
  <v-layout ref="app">
    <v-app-bar density="compact" class="px-2">
      <v-row>
        <v-col cols="auto">
          <BreadcrumbsComponent :items="breadcrumbs"/>
        </v-col>
        <v-col>
          <v-btn color="green"
                 style="margin-top:6px; "
                 class="float-start mx-1"
                 size="x-small"
                 prepend-icon="mdi-cart">
            Zlecenie {{ itemId }}
            <v-dialog
                v-model="dialogTicket[itemId]"
                activator="parent"
                persistent
                scrollable
            >
              <v-card>
                <v-card-title>
                  ID: {{ itemId }}
                  <v-btn icon @click="taskDialogTicketClose(itemId)" class="float-end"
                         variant="text"
                         density="compact">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-item>


                  <TicketDetail v-if="dialogTicket[itemId] === true"
                                :ticketId="itemId"></TicketDetail>
                </v-card-item>
              </v-card>
            </v-dialog>
          </v-btn>
          <v-btn
              color="green"
              style="margin-top:6px; "
              class="float-start mx-1"
              size="x-small"
              prepend-icon="mdi-calculator-variant"
              @click="getCalculations"
          >Przelicz
          </v-btn>
          <v-btn
              color="green"
              style="margin-top:6px; "
              class="float-start mx-1"
              size="x-small"
              prepend-icon="mdi-content-save"
              @click="fetchAddTicket(itemId)"
          >
            Zapisz
          </v-btn>


          <v-btn
              color="green"
              style="margin-top:6px;"
              class="float-end mx-2"
              v-bind="props"
              size="x-small"
              prepend-icon="mdi-plus-box"
              to="/products/tree2"
              v-if="checkRoles(['ROLE_PROJECTS_READ','ROLE_ADMIN'])"
          >
            Nowy projekt/element
          </v-btn>
          <v-btn
              color="blue"
              style="margin-top:6px;"
              class="float-end mx-2"
              v-bind="props"
              size="x-small"
              prepend-icon="mdi-file-tree-outline"
              to="/projects"
              v-if="checkRoles(['ROLE_TICKETS_READ','ROLE_ADMIN'])"
          >
            Projekty
          </v-btn>
          <v-btn
              color="blue"
              style="margin-top:6px;"
              class="float-end mx-2"
              v-bind="props"
              size="x-small"
              prepend-icon="mdi-file-tree-outline"
              to="/projects/element"
              v-if="checkRoles(['ROLE_TICKETS_READ','ROLE_ADMIN'])"
          >
            Gałęzie drzewa
          </v-btn>

        </v-col>
      </v-row>
    </v-app-bar>
    <v-main class="mt-3">
      <v-row>
        <v-col cols="5">
          <TableProducts
              v-if="projectName"
              :projectName="projectName"
              :changeTab="changeTab"
              :itemsWithIsScreenTrue="itemsWithIsScreenTrue"
              :getValueFromScreen="getValueFromScreen"
              :selectedProducts="selectedProducts"
              :projectType="projectType"
          >
          </TableProducts>
        </v-col>
        <v-col cold="7">
          <v-card>
            <v-card-item>
              <v-window v-model="tab" transition="fade-transition">
                <v-window-item transition="fade-transition" value="project">
                  <template v-if="projects.projects">
                    <v-card
                        color="#1F7087"
                        theme="dark"
                        style="width: fit-content !important;"
                        v-for="(project, index) in projects.projects" :key="index"
                        class="float-left me-2"
                        @click="selectProject(project)"
                    >
                      <v-card-item>
                        <div class="d-flex flex-no-wrap justify-space-between">
                          <div>
                            <v-card-title class="text-h6">
                              {{ project.title }}
                            </v-card-title>
                            <v-card-actions>
                              <label>
                                <input type="radio" name="selectedProject" :value="project.id"> Wybierz
                              </label>
                            </v-card-actions>
                          </div>
                        </div>
                      </v-card-item>
                    </v-card>
                  </template>
                </v-window-item>
                <v-window-item transition="fade-transition" :value="screen.id" v-for="screen in itemsWithIsScreenTrue"
                               :key="screen.id" @load="handleSelected(screen.id)">
                  <v-row style="height: 10000px;">
                    <v-col cols="auto" v-for="child in screen.children" :key="child.id">

                      <Branch
                          :child="child"
                          :handleSelected="handleSelected"
                          :checkSelected="checkSelected"
                          :getValueFromScreen="getValueFromScreen"
                          :readDataFromMathProject="readDataFromMathProject"
                          :updateInput="updateInput"
                          :searchProductById="searchProductById"
                      />

                    </v-col>
                  </v-row>

                </v-window-item>
              </v-window>

            </v-card-item>
          </v-card>

        </v-col>
      </v-row>
    </v-main>
  </v-layout>


</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
//import BlockTree from "@/views/tickets/BlockTree.vue";
import {
  mathBranchValues,
  mathSpecificValueOfaFeature,
  mathSpecificValueOfaFeatureOfTheSelectedProduct,
  mathSumOfAllChildren, mathSumOfAllChildrenFeature, mathVariableValue,
  rozdzielDzialanie,
} from "@/views/tree/js/main";
import TableProducts from "@/components/TableProducts.vue";
import {useStorage} from "vue3-storage";
import TicketDetail from "@/views/tickets/TicketDetail.vue";
import Branch from "@/views/tickets/Branch.vue";

export default {
  components: {
    Branch,
    TicketDetail,
    TableProducts,
    //BlockTree,
    BreadcrumbsComponent,
  },
  data() {
    return {
      breadcrumbs: [
        {
          title: 'Zlecenie',
          disabled: true,
        },
      ],
      dialogTicket: [],
      itemsWithIsScreenTrue: [],
      tab: null,
      tab2: [],
      tree: [],


      dialog: false,
      title: '',
      itemId: null,
      projectName: 'brak wybranego projektu',
      projectType: "brak typu projektu",
      projectMath: null,

      selectedProducts: [],
      selectedProductsTemp: [],
      math: [],
      selectedProject: null,

      itemsPerPage: 20,
      tableHeaders: [
        {title: 'Nazwa', align: 'start', key: 'name'},
        {title: 'Wartość', align: 'start', key: 'value'},

      ],
      tableData: [],
      loading: false,
      totalCalc: 0,
      lastValueInCalc: 0,

      originalMath: []
    };
  },
  async mounted() {
    await this.loadingData();

  },
  computed: {
    ...mapState(["projects"]),
    ...mapGetters(["isLoggedIn", 'getToken', "getUser", "getRoles", 'getTicketById', 'getProjectById', 'getVariableById','getReadyProductById','getVariables']),


  },

  methods: {
    ...mapActions(['addTicket']),

    readDataFromMathProject(idBranch, idFeature) {
      let data = '';
      if (this.projectMath) {
        if (idBranch && idFeature) {
          data = this.projectMath.find((pm) => Number(pm.idTree) === Number(idBranch) && Number(pm.feature) === Number(idFeature));
        } else {
          data = this.projectMath.find((pm) => Number(pm.idTree) === Number(idBranch) && pm.features === '??');
        }
      }
      //////console.log('readDataFromMathProject', idBranch, idFeature, data)
      return data;
    },


    loadingData() {
      //////////console.log'start')

      this.itemId = this.$route.params.id.toString();

      if (this.itemId) {

        //////////console.log'is id', this.itemId)
        let data = this.getTicketById(this.itemId);

        if (data) {
          //////console.log('data load', data, data.math, JSON.parse(data.math))
          this.title = data.title;
          this.math = JSON.parse(data.math);


          this.selectedProducts = JSON.parse(data.selectedProducts);
          this.selectedProject = JSON.parse(data.selectedProject);
          this.tree = JSON.parse(data.tree);
          if (this.selectedProject) {
            this.selectProject(this.selectedProject);
          }

          //////console.log('this.selectedProject',this.selectedProject);

          const project = this.getProjectById(this.selectedProject);
          if (project) {
            this.projectName = project.title;
            this.projectType = project.typeProjectName;
            this.projectMath = JSON.parse(project.math);

            //////console.log(this.projectMath);
          }
          this.loading = true;
        }
      }
    },

    checkRoles(roles) {
      let allRoles = this.$store.state.roles;
      if (allRoles === null) {
        allRoles = useStorage().getStorageSync("roles");
      }
      return roles.some(role => allRoles.includes(role));
    },
    changeTab(tabId) {
      this.tab = tabId;
    },

    async selectProject(projectData) {
      if (typeof projectData !== 'number') {
        this.selectedProject = projectData.id;
        this.projectName = projectData.title;
      }
      if (projectData.typeProjectName != "") {
        this.projectType = projectData.typeProjectName;
      }

      const project = await this.getProjectById(this.selectedProject);
      if (project) {
        ////console.log('Wybrany projekt', project);
        const tree = JSON.parse(project.tree);
        this.chipsMath = JSON.parse(project.chipsMath);
        this.tree = tree;
        this.projectMath = JSON.parse(project.math)
        this.itemsWithIsScreenTrue = this.extractItemsWithIsScreenTrue(this.tree.children);
        this.itemsWithIsRequiredTrue = this.extractItemsWithIsRequiredTrue(this.tree.children);
        this.itemsWithIsScreenTrue.push(tree);
        this.changeTab(0)
      }
    },

    findIndexes(selectedProducts, idTree, idFeature) {
      const indexes = [];
      for (let i = 0; i < selectedProducts.length; i++) {
        const el = selectedProducts[i];
        if (idFeature == null) {
          if (el.idTree === Number(idTree)) {
            indexes.push(i);
          }
        } else {
          if (el.idTree === Number(idTree) && el.idFeature === Number(idFeature)) {
            indexes.push(i);
          }
        }
      }

      return indexes;
    },
    getItemFromScreen(idScreen) {
      return this.selectedProducts.filter((s) => s.idTree === idScreen);
    },

    getValueFromScreen(idScreen) {
      let result = false;
      if (this.selectedProducts.find((s) => s.idTree === idScreen)) {
        result = true;
      }
      return result;
    },

    getItemsFromChildren(children) {
      let _child = [];

      if (children) {
        children.filter((child) => {
          let result = this.selectedProducts.find((el) => el.idTree === Number(child));
          if (result !== undefined) {
            _child.push(result);
          }
        });
      }

      //////////////console.log'getItemsFromChildren', _child);

      return _child;
    },

    async fetchAddTicket(idTicket) {
      ////////////////console.log'fetchAddUnit',idUnit);
      if (idTicket) {
        await this.$store.dispatch('updateTicket', {
          item: {
            title: this.title,
            math: JSON.stringify(this.math),
            selectedProducts: JSON.stringify(this.selectedProducts),
            selectedProject: JSON.stringify(this.selectedProject),
            tree: JSON.stringify(this.tree)
          }, idTicket: idTicket
        });
      } else {
        await this.$store.dispatch('addTicket', {
          title: this.title,
          math: JSON.stringify(this.math),
          selectedProducts: JSON.stringify(this.selectedProducts),
          selectedProject: JSON.stringify(this.selectedProject),
          tree: JSON.stringify(this.tree)
        });
        this.itemId = this.$store.state.tickets.currentId;
        //////////////console.logthis.itemId);
      }
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      if (this.$store.state.users.responseStatus === 'success') {
        this.dialog = false;
      }
    },

    extractItemsWithIsScreenTrue(items) {
      //if(items && items.length > 0){
      const result = [];
      for (const item of items) {
        if (item.isScreen === true) {
          result.push(item);
        }

        if (item.children && Array.isArray(item.children)) {
          result.push(...this.extractItemsWithIsScreenTrue(item.children));
        }
      }
      return result;
      //}
    },

    extractItemsWithIsRequiredTrue(items) {
      const result = [];

      for (const item of items) {
        if (item.isRequired === true) {
          result.push(item.id);
        }

        if (item.children && Array.isArray(item.children)) {
          result.push(...this.extractItemsWithIsRequiredTrue(item.children));
        }
      }

      return result;
    },



    findParentId(tree, childId) {
      return this.findParentIdRecursive(tree, childId, null);
    },

    findParentIdRecursive(node, childId, parentId) {
      if (node.id === childId) {
        return parentId; // Znaleziono rodzica, zwracamy jego id
      }

      if (node.children && node.children.length > 0) {
        for (const child of node.children) {
          const result = this.findParentIdRecursive(child, childId, node.id);
          if (result !== null) {
            return result; // Znaleziono rodzica w jednym z dzieci
          }
        }
      }

      return null; // Nie znaleziono rodzica dla danego dziecka
    },


    async handleSelected(idTree, idFeature = null) {


      const idParent = this.findParentId(this.tree, Number(idTree));
      const parent = await this.searchProductById(Number(idParent))
      const currentItem = await this.searchProductById(Number(idTree))

      if (currentItem) {

        // Usuwanie zaznaczenia z pól gdzie jest tylko jeden wybór
        if (parent && parent.oneChildren === true && parent.children) {
          parent.children.filter(child => {
            this.selectedProducts.forEach((el, index) => {
              if (el.idTree === Number(child.id)) {
                this.selectedProducts.splice(index, 1);
              }
            });
          });
        }

        // Dodawanie wartości do tablicy unit
        let data = {idTree: Number(idTree)};


        data.name = currentItem.label ?? currentItem.title;
        data.isScreen = currentItem.isScreen;

/*        if(currentItem.product){
          const product = this.getReadyProductById(Number(currentItem.product));
          data.priceReady = product.price;
          data.priceUnit = product.priceUnit;
        }*/



        if (currentItem.children) {
          data.children = [];
          currentItem.children.forEach((element) =>
              data.children.push(element.id)
          );
        }


        if (idFeature !== null) {

          //const row = this.selectedProducts.findIndex((el) => el.idTree === Number(idTree) && el.idFeature === Number(idFeature));
          // const row = this.selectedProducts.findIndex((el) => el.idTree === Number(idTree));
          let indexes = this.findIndexes(this.selectedProducts, idTree, idFeature);

          if (indexes.length > 0) {
            for (let i = 0; i < this.selectedProducts.length; ++i) {

              if (this.selectedProducts[i]['idTree'] === idTree) {
                if (this.selectedProducts[i]['children'] !== undefined && this.selectedProducts[i]['children'].length > 0) {
                  for (let j = 0; j < this.selectedProducts[i]['children'].length; ++j) {
                    const row = this.selectedProducts.findIndex((el) => el.idTree === Number(this.selectedProducts[i]['children']));
                    this.selectedProducts.splice(row, 1);
                  }
                }
                // Usunięcie wiersza, który spełnia warunek
                this.selectedProducts.splice(i, 1);

                // Zmniejszenie licznika pętli, ponieważ indeksy wierszy zmieniły się po usunięciu
                i--;
              }

            }

          } else {


            //////////console.log'currentItem', data)

            data.idFeature = Number(idFeature);
            this.selectedProducts.push(data);
          }
        } else {


          // const row = this.selectedProducts.findIndex((el) => el.idTree === Number(idTree));
          let indexes = this.findIndexes(this.selectedProducts, idTree, idFeature);


          if (indexes.length > 0) {
            //////////console.log'dodajemyyyyy 1', data);
            for (let i = 0; i < this.selectedProducts.length; ++i) {

              if (this.selectedProducts[i]['idTree'] == idTree) {

                try {
                  let childrenLength = this.selectedProducts[i]['children'].length


                  if (childrenLength > 0) {
                    for (let j = 0; j < childrenLength; ++j) {

                      const row = this.selectedProducts.findIndex((el) => el.idTree === Number(this.selectedProducts[i]['children'][j]));

                      if (row != -1) {
                        this.selectedProducts.splice(row, 1);
                      }

                    }
                  }
                } catch (e) {
                  //////////console.loge)
                }


                // Usunięcie wiersza, który spełnia warunek
                this.selectedProducts.splice(i, 1);

                // Zmniejszenie licznika pętli, ponieważ indeksy wierszy zmieniły się po usunięciu
                i--;
              }

            }
          } else {
            ////////////console.log'dodajemyyyyy 2', data);
            if (currentItem.features !== undefined) {
              //////////console.log'dodajemyyyyy 3', data);
              currentItem.features.filter((feature) => {

                let data = {idTree: Number(idTree)};
                data.parent = currentItem.label ?? currentItem.title;
                data.parentId = parent.id;
                data.name = feature.title ?? feature.label;
                if (currentItem.children) {
                  data.children = [];
                  currentItem.children.forEach((element) =>
                      data.children.push(element.id)
                  );
                }
                data.idFeature = Number(feature.id);
                data.eye = feature.eye;
                //data.value = Number(feature.value);
                data.value = feature.value;
                data.unit = feature.units[0];
                data.isScreen = currentItem.isScreen;
                data.math = feature.math;

                //////////console.log'ff', feature.id, feature, data)

                this.selectedProducts.push(data);
              })
            } else {
              //////////console.log'dodajemyyyyy 4', data);
              this.selectedProducts.push(data);
            }
          }

        }
        this.selectedProducts = this.customSort(this.selectedProducts);
        this.getCalculations();
        this.getCalculations();
        this.getCalculations();
        this.getCalculations();
        this.getCalculations();

      }
    },

    customSort(arr) {
      const idToElementMap = new Map();
      const result = [];

      arr.forEach(item => {
        idToElementMap.set(item.idTree, item);
      });

      arr.forEach(item => {
        if (!item.children) {
          if (!result.includes(item)) {
            result.push(item);
          }
        } else {
          if (!result.includes(item)) {
            result.push(item);
          }
          item.children.forEach(childId => {
            const childElement = idToElementMap.get(childId);
            if (childElement && !result.includes(childElement)) {
              const parentIndex = result.findIndex(parent => parent.idTree === item.idTree);
              result.splice(parentIndex + 1, 0, childElement);
            }
          });
        }
      });

      return result;
    }
    ,

    checkSelected(idTree, idFeature = null) {
      if (idFeature !== null) {
        const row = this.selectedProducts.find((el) => el.idTree === Number(idTree) && el.idFeature === Number(idFeature));
        return (row !== -1) ? row : false;
      } else {
        const row = this.selectedProducts.find((el) => el.idTree === Number(idTree));
        return (row !== -1) ? row : false;
      }
    }
    ,

    updateMyProp($event, index) {


      const row = this.math.findIndex((el) => el.idTree === Number(index[0]) && el.feature === Number(index[1]));
      if (row !== -1) {
        this.math[row] = {
          idTree: Number(index[0]),
          feature: Number(index[1]),
          value: Number($event)
        }
      } else {
        this.math.push(
            {
              idTree: Number(index[0]),
              feature: Number(index[1]),
              value: Number($event)
            }
        );
      }
    }
    ,

    searchProductById(element_id, element = null) {

      if(element === null){
        element = this.tree;
      }
      //////////////console.log'searchProductById', Number(element.id) , Number(element_id));

      if (Number(element.id) === Number(element_id)) {
        return element;
      } else if (element.children != null) {
        var i;
        var result = null;
        for (i = 0; result == null && i < element.children.length; i++) {
          result = this.searchProductById(element_id, element.children[i]);
        }
        return result;
      }
      return null;
    }
    ,

    updateInput(idTree, idFeature = null, value = null) {

      //////////////console.log'updateInput', idTree, idFeature, value);

      let data = {
        idTree: Number(idTree),
        idFeature: Number(idFeature),
        value: value,
        math: value
      }
      const row = this.selectedProducts.findIndex((el) => el.idTree === Number(idTree) && el.idFeature === Number(idFeature));

      //////////////console.log'updateInputrow', row)

      if (row !== -1) {
        this.selectedProducts[row].value = value
        this.selectedProducts[row].math = value
      } else {
        this.selectedProducts.push(data);
      }
      this.getCalculations();
      this.getCalculations();
      this.getCalculations();
      this.getCalculations();
    },


    cleanMathExpression(expression) {
      // Usunięcie wszystkich znaków, które nie są cyframi, kropką lub znakami matematycznymi (+, -, *, /, (, ))
      const cleanedExpression = expression.replace(/[^0-9.+\-*/()]/g, '');

      // Usunięcie zbędnych znaków operatorów na początku oraz przy występowaniu obok siebie

      //////////console.logcleanedExpression);

      let fixedExpression = cleanedExpression.replaceAll(/^([+\-*/]+)|([+\-*/]+)$/g, '');
      fixedExpression = fixedExpression.replaceAll('++', '+');
      fixedExpression = fixedExpression.replaceAll('--', '-');
      fixedExpression = fixedExpression.replaceAll('**', '*');
      fixedExpression = fixedExpression.replaceAll('//', '/');

      fixedExpression = fixedExpression.replaceAll('+++', '+');
      fixedExpression = fixedExpression.replaceAll('---', '-');
      fixedExpression = fixedExpression.replaceAll('***', '*');
      fixedExpression = fixedExpression.replaceAll('///', '/');
      //////////console.logfixedExpression);
      return fixedExpression;
    },
    taskDialogTicketClose() {
      ////////console.log(idTicket)
      //////////console.logthis.dialogTicket[idTicket])
      this.dialogTicket = [];
    },
    getCalculations() {

      console.log('selectedProducts', this.selectedProducts);
      console.log(this);

      // this.totalCalc=0;
      this.selectedProducts.filter((item) => {
            // tu jak jest drzewo bo używa math element !
            // math to jest array pól cech z informacją do czego należy te pole i jaka operacja w nim jest ustawiona
            // if (item.math !== undefined) {
            //   let mathElements;
            //   let _math = '';
            //   mathElements = rozdzielDzialanie(item.math);
            //
            //   //////////console.log'mathElements',mathElements);
            //
            //   mathElements.filter((mathElement) => {
            //         if (mathElement) {
            //           let pattern = /^[0-9]+([.,][0-9]+)?$/;
            //
            //           if (mathElement === "*" || mathElement === "/" || mathElement === "+" || mathElement === "-" || mathElement === "(" || mathElement === ")"
            //               || pattern.test(mathElement)) {
            //             _math += mathElement;
            //           } else {
            //
            //             // Rozbijamy działanie matematyczne na części
            //             // Wyciągamy identyfikatory
            //             const mathId = mathElement.split('_');
            //
            //             let mathProduct = mathId[0];
            //             let mathFeature = mathId[1];
            //             let mathContent = mathId[2];
            //
            //             if (mathProduct && mathFeature && !mathContent) {
            //               let variables = this.getVariableById(mathFeature);
            //               _math += String(variables.value.replace(',', '.'));
            //             }
            //
            //             if (mathProduct && mathFeature && mathContent) {
            //               let _selectChildren = null;
            //
            //               if (item.children !== undefined) {
            //                 item.children.filter((childrenProduct) => {
            //
            //                   //////////console.logitem.children, this.selectedProducts, childrenProduct);
            //
            //                   // Zaznaczony produkt RADIO
            //                   if (this.selectedProducts) {
            //                     let selectChildren = this.selectedProducts.filter(element => Number(element.idTree) === Number(childrenProduct));
            //                     if (selectChildren.length > 0) {
            //                       _selectChildren = selectChildren[0];
            //                     }
            //                   }
            //                 })
            //               }
            //
            //
            //               //////////console.log'Szukamy', mathProduct,  mathFeature, _selectChildren)
            //
            //               if (mathProduct === "??" && mathFeature === "??") {
            //
            //                 //alert('??-??');
            //
            //                 const row = this.selectedProducts.find((el) => String(el.idTree) === String(_selectChildren));
            //                 if (row !== undefined) mathProduct = row;
            //                 if (mathContent === "C" && mathProduct !== '??') _math += String(mathProduct.priceReady);
            //               } else if (mathProduct === "??" && mathFeature !== "??" && mathFeature !== "00") {
            //
            //                 //alert('??-__', _selectChildren);
            //
            //                 let row = this.selectedProducts.find((el) => String(el.idTree) === String(_selectChildren)
            //                     && String(el.idFeature) === String(mathFeature));
            //                 if (!row) {
            //                   row = _selectChildren;
            //                 }
            //
            //
            //                 if (row !== undefined && row !== null) mathProduct = row;
            //
            //                 if (row !== undefined && row !== null && mathContent === "C" && mathProduct !== '??') {
            //                   _math += String(row.price);
            //                 }
            //                 if (row !== undefined && row !== null && mathContent === "W" && mathProduct !== '??') {
            //                   _math += String(row.value);
            //                 }
            //
            //               } else if (mathProduct !== "??" && mathProduct !== "00" && mathFeature !== "??" && mathFeature !== "00") {
            //
            //                 //alert('__-__');
            //
            //                 //////////console.log'Wszystkie znane', mathProduct, mathFeature, this.selectedProducts)
            //
            //                 const row = this.selectedProducts.find((el2) => {
            //                   if (Number(el2.idTree) === Number(mathProduct) &&
            //                       Number(el2.idFeature) === Number(mathFeature)
            //                   ) {
            //
            //
            //                     //////////console.log'Coś znalazłem', mathProduct, mathFeature, el2);
            //
            //
            //                     if (item.children !== undefined) {
            //                       if (item.children.find((el3) => el3 === el2.idTree)) {
            //                         return el2
            //                       }
            //                     }
            //
            //                     if (String(el2.idTree) === String(mathProduct)) {
            //                       return el2
            //                     }
            //                   }
            //                 });
            //
            //                 //////////console.log'No i zwracam', mathProduct, mathFeature, row);
            //
            //                 if (row !== undefined && mathContent === "W" && mathProduct !== '??') {
            //                   if (row.value !== null) {
            //                     let price = 0;
            //                     price = row.value;
            //                     price = isNaN(price) ? 0 : price;
            //                     _math += parseFloat(price.toString().replace(',', '.'));
            //                   }
            //                 }
            //
            //               } else if (mathProduct !== "??" && mathProduct !== "00" && mathFeature === "??") {
            //
            //
            //                 //alert('__-??');
            //
            //                 let row = this.selectedProducts.find((el) => String(el.idTree) === String(mathProduct));
            //
            //                 if (row.type !== undefined && row.type === 'ready' && !row.priceReady) {
            //
            //                   let index = this.selectedProducts.findIndex(obj => obj.idTree === row.idTree);
            //
            //                   if (index !== -1) {
            //                     this.selectedProducts.splice(index, 1);
            //                   }
            //
            //                   row = this.selectedProducts.find((el) => String(el.idTree) === String(mathProduct));
            //                 }
            //
            //                 if (row !== undefined) mathProduct = row;
            //
            //                 if (mathContent === "C" && mathProduct !== '??') _math += String(mathProduct.priceReady);
            //                 if (mathContent === "J" && mathProduct !== '??') _math += String(mathProduct.priceUnit);
            //
            //               } else if (mathProduct === "00" && mathFeature === "00") {
            //
            //                 //alert('00-00');
            //
            //                 if (item.children !== undefined) {
            //                   item.children.filter((childrenProduct) => {
            //                     const row = this.selectedProducts.find((el) => String(el.idTree) === String(childrenProduct));
            //
            //                     ////////////console.log'row', row)
            //
            //                     if (row !== undefined) {
            //                       if (mathContent === "C") _math += String(row.priceReady);
            //                       if (mathContent === "J") _math += String(row.priceUnit);
            //                       _math += '+';
            //                     }
            //                   });
            //
            //                   var lastChar = _math.charAt(_math.length - 1);
            //                   if (lastChar === "+") {
            //                     _math = _math.slice(0, -1);
            //                   }
            //                 }
            //               }
            //             }
            //           }
            //         }
            //       }
            //   )
            //
            //   if (_math === "") {
            //     _math = '---';
            //   }
            //
            //
            //   try {
            //
            //     let resultMath = eval(this.cleanMathExpression(_math));
            //     //////////console.log'cl_success', mathElements, _math, this.cleanMathExpression(_math), resultMath);
            //     //resultMath = parseFloat(resultMath.toFixed(2));
            //     // this.totalCalc+=resultMath;
            //     if (resultMath !== undefined) {
            //       item.value = resultMath;
            //     }
            //
            //   } catch (error) {
            //     //////console.log('cl_error', mathElements, _math, this.cleanMathExpression(_math), error);
            //   }
            //
            // }
            // else {
              let _math = '';
              const operation = this.projectMath.find((pm) => Number(pm.idTree) === Number(item.idTree) && Number(pm.feature) === Number(item.idFeature))?.math;
              const operationItems = rozdzielDzialanie(operation);

              //console.log(operation,operationItems);

              operationItems.filter((mathElement) => {
                if (mathElement) {
                  let pattern = /^[0-9]+([.,][0-9]+)?$/;

                  console.log(mathElement);

                  if (mathElement === "*" || mathElement === "/" || mathElement === "+" || mathElement === "-" || mathElement === "(" || mathElement === ")"
                      || pattern.test(mathElement)) {
                    _math += mathElement;
                  } else {

                    // Rozbijamy działanie matematyczne na części
                    // Wyciągamy identyfikatory
                    const mathId = mathElement.split('_');

                    let mathProduct = mathId[0];
                    let mathFeature = mathId[1];
                    let mathContent = mathId[2];

                    let _selectChildren = null;

                    if (item.children !== undefined) {
                      item.children.filter((childrenProduct) => {
                        console.log(item.children, this.selectedProducts, childrenProduct);
                        // Zaznaczony produkt RADIO
                        if (this.selectedProducts) {
                          let selectChildren = this.selectedProducts.filter(element => Number(element.idTree) === Number(childrenProduct));
                          if (selectChildren.length > 0) {
                            _selectChildren = selectChildren[0].idTree;
                          }
                        }
                      })
                    }

                    let dataToFunction = {
                      product: mathProduct,
                      feature: mathFeature,
                      content: mathContent,
                      children: _selectChildren,
                      childrens: item.children,
                      select: this.selectedProducts,
                      originalMath: this.projectMath
                    }

                    //console.log('dataToFunction',dataToFunction);


                    // Konkretna wartość cechy (checkbox)
                    if (mathProduct !== "??" && mathProduct !== "00" &&
                        mathFeature !== "??" && mathFeature !== "00") {
                      _math += mathSpecificValueOfaFeature(dataToFunction)
                    }


                    // Konkretna wartość cechy wybranego produktu (radio)
                    if (mathProduct === "??" &&
                        mathFeature !== "??" && mathFeature !== "00") {
                      ////console.log('Konkretna wartość cechy wybranego produktu (radio)')
                      _math += mathSpecificValueOfaFeatureOfTheSelectedProduct(dataToFunction)
                    }


                    // Ogólne wartości gałęzi
                    if (mathProduct !== "??" && mathProduct !== "00" &&
                        mathFeature === "??") {
                      ////console.log('Ogólne wartości gałęzi')
                      _math += mathBranchValues(dataToFunction)
                    }

                    //Suma wszystkich  dzieci - główne wartości
                    if (mathProduct === "00" &&
                        mathFeature === "00") {
                      ////console.log('Suma wszystkich  dzieci - główne wartości')
                      _math += mathSumOfAllChildren(dataToFunction)
                    }

                    //Suma wszystkich  dzieci - cechy
                    if (mathProduct === "00" &&
                        Number(mathFeature)) {
                      ////console.log('Suma wszystkich  dzieci - główne wartości')
                      _math += mathSumOfAllChildrenFeature(dataToFunction)
                    }

                    // W sumie pojawiła się zmienna
                    if (mathProduct === "Z" &&
                        Number(mathFeature)) {
                      _math += mathVariableValue(dataToFunction, this.getVariables)
                    }

                  }
                }
              });

              //console.log(_math, this.cleanMathExpression(_math));

              try {

                let resultMath = eval(this.cleanMathExpression(_math));
                //////////console.log'cl_success', mathElements, _math, this.cleanMathExpression(_math), resultMath);
                //resultMath = parseFloat(resultMath.toFixed(2));
                // this.totalCalc+=resultMath;
                if (resultMath !== undefined) {
                  item.value = resultMath;
                }

              } catch (error) {
                //////console.log('cl_error', mathElements, _math, this.cleanMathExpression(_math), error);
              }

              //////console.log('this.projectMath calc',item.idTree
              //  ,item.idFeature,  this.projectMath.find((pm) => Number(pm.idTree) === Number(item.idTree) && Number(pm.feature) === Number(item.idFeature)));
            }

          // }
      )
      ;
    },
  },
};
</script>
