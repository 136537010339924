import axios from "axios";
export const types = {
    state: {
        types: [],
        responseStatus: '',
        responseMessage: '',
        default: null,
    },
    getters: {
        getTypes: state => {
            return state.types;
        },
        getTypeById: (state) => (id) => {
            return state.types.find(item => item.id === Number(id));
        },
        getDefaultType: state => {
            return state.types[0];
        }
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        },
        setResponseStatus(state, status) {
            state.responseStatus = status;
        },
        setTypes(state, items) {
            state.types = items;
        },
        addType(state, items) {
            state.types.push(items);
        },
        updateType(state, data) {
            const index = state.types.findIndex(item => item.id === data.id);
            state.types.splice(index, 1, data);
        },
        removeType(state, itemId) {
            state.types = state.types.filter(item => item.id !== itemId);
        },
    },
    actions: {
        async loadTypes({commit, rootState }) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_LOCATION + '/api/types',
                    {headers: {Authorization: `Bearer ${rootState .token}`}});
                const items = response.data;
                commit('setTypes', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async addType({commit, rootState }, data) {
            try {
                await axios.post(process.env.VUE_APP_API_LOCATION + '/api/types',
                    data,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('addType', items);
                        commit('setResponseMessage', 'Rodzaj został dodany');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async addLogo({commit, rootState }, data) {

            try {
                await axios.post(process.env.VUE_APP_API_LOCATION + '/api/types/logo/' + data.id,
                    data.data,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('updateType', items);
                        commit('setResponseMessage', 'Logo zostało wgrane');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateType({commit, rootState }, data) {
           //////console.log'updateUnit', data)
            try {
                await axios.put(process.env.VUE_APP_API_LOCATION + '/api/types/' + data.idType,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then((response) => {
                        commit('updateType', response.data);
                        commit('setResponseMessage', 'Rodzaj został zaktualizowany');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async removeType({commit, rootState }, itemId) {
            try {
                await axios.delete(process.env.VUE_APP_API_LOCATION + '/api/types/' + itemId,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(() => {
                        commit('removeType', itemId);
                        commit('setResponseMessage', 'Rodzaj został usunięty');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
    }
}
