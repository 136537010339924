<template>
  <v-form v-model="isFormValid">
    <v-card>
      <v-card-item v-if="$props.search">
        <v-autocomplete
            :disabled="!isEditing"
            :items="customers.customers"
            :custom-filter="customFilter"
            item-title="title"
            item-value="id"
            variant="outlined"
            density="compact"
            label="Wyszukaj klienta"
            v-model="existCustomer"
        ></v-autocomplete>
      </v-card-item>
      <v-card-item>
        <v-row>
          <v-col>
            <v-text-field
                density="compact"
                style="margin-top:6px;"
                label="Imię"
                v-model="name"
                hide-details="auto"
                :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                :rules="onlyLettersRules"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                density="compact"
                label="Nazwisko"
                style="margin-top:6px;"
                v-model="lastName"
                hide-details="auto"
                :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                :rules="onlyLettersRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
                density="compact"
                label="Ulica"
                v-model="street"
                hide-details="auto"
                :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                density="compact"
                label="Nr domu"
                v-model="homeNumber"
                hide-details="auto"
                :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
                density="compact"
                label="Kod"
                v-model="postcode"
                hide-details="auto"
                :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                density="compact"
                label="Miasto"
                v-model="city"
                hide-details="auto"
                :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
                density="compact"
                label="Telefon"
                v-model="phone"
                hide-details="auto"
                :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                :rules="phoneRules"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                density="compact"
                label="Email"
                v-model="emailCustomer"
                hide-details="auto"
                :disabled="!checkRoles(['ROLE_TICKETS_SAVE','ROLE_ADMIN'])"
                :rules="emailRules"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-item>
      <ActionsButton @save="save()" @annulled="$emit('annulled')"
                     :disabledSave="!isFormValid"></ActionsButton>
    </v-card>
  </v-form>
</template>

<script>

import {useStorage} from "vue3-storage";
import {mapGetters, mapState} from "vuex";
import ActionsButton from "@/components/navigation/ActionsButton.vue";

export default {
  name: "CustomerForm",
  components: {ActionsButton},
  props: ['id', 'search'],
  data: () => {
    return {
      isFormValid: false,
      isEditing: true,

      existCustomer: null,

      name: null,
      street: null,
      city: null,
      phone: null,
      postcode: null,
      emailCustomer: null,
      homeNumber: null,
      lastName: null,

      onlyLettersRules: [
        value => {
          if (!/\d/.test(value)) return true
          return 'Pole nie może zawierać liczb.'
        },
      ],
      phoneRules: [
        value => {
          if (/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/.test(value) || !value) return true
          return 'Numer telefonu może zawierać wyłącznie cyfry (minimum 9) i dodatkowo znak "+".'
        },
      ],
      emailRules: [
        value => {
          if (/.+@.+\..+/.test(value) || !value) return true
          return 'E-mail musi być prawidłowy'
        },
      ],
    }
  },
  watch: {
    existCustomer(newValue) {
      if (newValue !== null) {
        this.loadCustomer(newValue);
      }
    }
  },
  computed: {
    ...mapGetters(['getCustomerById']),
    ...mapState(['customers'])
  },
  mounted() {
    if(this.$props.id){
      this.loadCustomer(this.$props.id)
    }
  },
  methods: {
    checkRoles(roles) {
      let allRoles = this.$store.state.roles = useStorage().getStorageSync("roles");
      return roles.some(role => allRoles.includes(role));
    },
    customFilter(itemTitle, queryText, item) {
      const textOne = item.raw.firstName.toLowerCase()
      const textTwo = item.raw.lastName.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
    },
    loadCustomer(idCustomer) {
      const issetCustomer = this.getCustomerById(idCustomer);
      this.customerId = issetCustomer.id;
      this.name = issetCustomer.firstName
      this.lastName = issetCustomer.lastName
      this.phone = issetCustomer.phone
      this.emailCustomer = issetCustomer.email
      this.customerAddressId = issetCustomer.address.id;
      this.street = issetCustomer.address.street;
      this.homeNumber = issetCustomer.address.homeNumber;
      this.postcode = issetCustomer.address.postcode;
      this.city = issetCustomer.address.city;
    },
    async save() {
      const data = {
        firstName: this.name,
        lastName: this.lastName,
        phone: this.phone,
        email: this.emailCustomer,
        address: this.customerAddressId,
        street: this.street,
        homeNumber: this.homeNumber,
        postcode: this.postcode,
        city: this.city
      }

      console.log(this.customerId);

      if(this.customerId !== undefined){
        const dataToUpdate = {
          id: this.customerId,
          item: data
        }
        await this.$store.dispatch('updateCustomer', dataToUpdate);
      }else{
        await this.$store.dispatch('addCustomer', data);
      }

      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});

      this.$emit('annulled');

    }
  }
}
</script>

<style scoped>

</style>
