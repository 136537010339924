<template>
  <v-btn class="mx-2" append-icon="mdi-trash-can" size="x-small" fab dark color="green">
    Szczegóły
    <v-dialog
        v-model="dialog"
        activator="parent"
        persistent
    >
      <v-card>
        <v-card-title>
          <v-btn icon @click="dialog=false" class="float-end"
                 variant="text"
                 density="compact">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-item>
          <v-form v-model="isFormValid">
          <v-text-field label="Nazwa projektu" v-model="title" class="mt-4" :rules="projectNameRules"></v-text-field>
          <v-select label="Typ projektu" v-model="typeProject"
                    :items="types.types" item-value="id" item-title="name" :rules="projectItemRules">
          </v-select>
          <v-select label="Status" v-model="status"
                    :items="statusItems" item-value="value" item-title="label" :rules="projectTypeRules">
          </v-select>
          </v-form>
        </v-card-item>
        <v-card-actions>
          <v-btn
              color="green"
              size="small"
              variant="tonal"
              prepend-icon="mdi-content-save"
              @click="save"
              :disabled="!isFormValid"
          >
            Zapisz
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </v-btn>


</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "ProjectDetail",
  props: ['idProject'],
  data() {
    return {
      dialog: false,
      title: null,
      typeProject: null,
      status: null,
      statusItems: [
        {
          value: 'new',
          label: 'Nowy'
        },
        {
          value: 'draft',
          label: 'Szkic'
        },
        {
          value: 'ready',
          label: 'Gotowy'
        },
        {
          value: 'anulled',
          label: 'Anulowany'
        }
      ],
      isFormValid: false,
      projectNameRules: [
        value => {
          if (value) return true
          return 'Wymagane.'
        },
        value => {
          if (value.length >= 3) return true
          return 'Minimalna liczba znaków to 2.'
        },
        value => {
          if (value.length <= 255) return true
          return 'Maksymalna liczba znaków to 255.'
        },
      ],
      projectItemRules:[
        value => {
          if (value) return true
          return 'Wymagane.'
        },
      ],
      projectTypeRules:[
        value => {
          if (value) return true
          return 'Wymagane.'
        },
      ]
    }
  },
  mounted() {
    this.loadItem();
  },
  methods: {
    loadItem() {
      const project = this.getProjectById(this.$props.idProject)
      this.title = project.title;
      this.typeProject = Number(project.typeProjectId);
      this.status = project.status;
    },
    async save() {



      const dataToUpdate = {
        item: {
          title: this.title,
          typeProject: this.typeProject,
          status: this.status,
        },
        idProject: this.$props.idProject
      }
      await this.$store.dispatch('updateProject', dataToUpdate);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    }
  },
  computed: {
    ...mapGetters(['getProjectById']),
    ...mapState(['types'])
  },

}
</script>

<style scoped>

</style>
