<template>
  <div v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER', 'ROLE_VARIABLES_READ'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
            <v-dialog
                v-model="dialog"
                width="600"
                height="500"
                v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER', 'ROLE_VARIABLES_SAVE'])"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                    color="blue"
                    style="margin-top:6px;"
                    class="float-end"
                    v-bind="props"
                    size="x-small"
                    prepend-icon="mdi-plus"
                    @click="clearValues"
                >
                  Dodaj nową zmienną
                </v-btn>

              </template>
              <v-card>
                <v-card-text>
                  <v-form v-model="isFormValid">
                    <v-text-field
                        label="Nazwa"
                        density="compact"
                        v-model="title"
                        hide-details="auto"
                        class="mb-5"
                        counter
                        :rules="nameRules"
                    ></v-text-field>
                    <v-text-field
                        label="Wartość"
                        density="compact"
                        v-model="value"
                        hide-details="auto"
                        class="mb-5"
                        counter
                        :rules="valueRules"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <ActionsButton @annulled="dialog = false" @save="fetchAddVariable(itemId)" :disabledSave="!isFormValid"></ActionsButton>
              </v-card>
            </v-dialog>

          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              density="compact"
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items-length="totalItems"
              :items="variables.variables"
              :loading="loading"
              :search="search"
              class="elevation-1"
              item-value="id"
          >

            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.selectable.id }}</td>
                <td>{{ item.selectable.title }}</td>
                <td>{{ item.selectable.value }}</td>
                <td align="end">
                  <v-btn class="mx-2" append-icon="mdi-pencil-box" size="x-small" fab dark color="blue"
                         @click="editItem(item.selectable)"
                         v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER', 'ROLE_VARIABLES_SAVE'])"
                  >
                    Edytuj
                  </v-btn>
                  <RemoveButton @confirm="fetchDeleteVariable(item.selectable.id)"
                                v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_VARIABLES_REMOVE'])">

                  </RemoveButton>
                </td>
              </tr>
            </template>

          </v-data-table>
        </v-card>
      </v-main>
    </v-layout>


  </div>
  <div v-else>
    <DenyAccessPage></DenyAccessPage>
  </div>
</template>


<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import DenyAccessPage from "@/components/DenyAccessPage.vue";
import {useStorage} from "vue3-storage";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";
import ActionsButton from "@/components/navigation/ActionsButton.vue";

export default {
  name: 'VariablesView',
  components: {ActionsButton, RemoveButton, DenyAccessPage, BreadcrumbsComponent},
  data: () => {
    return {
      title: '',
      value: '',
      breadcrumbs: [
        {
          title: 'Zmienne',
          disabled: true,
        },
      ],
      dialog: false,
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Wartość', key: 'value', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      loading: false,
      totalItems: null,
      itemId: null,
      items: [],
      isFormValid: false,
      nameRules: [
        value => {
          if (value) return true
          return 'Wymagane.'
        },
        value => {
          if (value.length <= 255) return true
          return 'Maksymalna liczba znaków to 255.'
        },
      ],
      valueRules:[
        value => {
          if (value) return true
          return 'Wymagane.'
        },
        value => {
          if (value.length <= 255) return true
          return 'Maksymalna liczba znaków to 255.'
        },
        value => {
          if (/^[+-]?([0-9]+([.,][0-9]*)?|[.,][0-9]+)$/.test(value) || !value) return true
          return 'Wartość powinna być liczbą'
        },

      ],
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken', "getRoles"]),
    ...mapState(["variables"]),
  },
  mounted(){
    this.totalItems = this.variables.variables.length;
  },
  methods: {
    ...mapActions(['addVariable']),

    clearValues(){
      this.title= '';
      this.value= '';
    },
    async fetchAddVariable(idVariable) {
     //////console.log'fetchAddVariable',idVariable);
      if(idVariable){
        await this.$store.dispatch('updateVariable', {item: {title: this.title, value: this.value}, idVariable: idVariable});
      }else{
        await this.$store.dispatch('addVariable', {title: this.title, value: this.value});
      }
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      if(this.$store.state.users.responseStatus === 'success'){
        this.dialog = false;
        this.itemId = null;
      }
    },
    async fetchDeleteVariable(idVariable) {
      await this.$store.dispatch('removeVariable', idVariable);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    editItem(variable) {
      this.dialog = true
      this.title = variable.title
      this.value = variable.value
      this.itemId = variable.id
    },
    checkRoles(roles) {
      let allRoles = this.$store.state.roles;
      if (allRoles === null) {
        allRoles = useStorage().getStorageSync("roles");
      }
      return roles.some(role => allRoles.includes(role));
    },
  }
}
</script>
