<template>
  <div v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_USER_READ'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
            <v-dialog
                v-model="dialog"
                persistent
                width="1024"
                v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_USER_SAVE'])">
              <template v-slot:activator="{ props }">
                <v-btn
                    color="blue"
                    style="margin-top:6px;"
                    class="float-end"
                    size="x-small"
                    prepend-icon="mdi-plus"
                    v-bind="props"
                >
                  Dodaj użytkownika
                </v-btn>
              </template>
              <v-card>
                <v-form v-model="isFormValid">
                  <v-card-title>
                    <span class="text-h5">Dodawanie użytkownika</span>
                  </v-card-title>
                  <v-card-text>
                    <v-text-field
                        prepend-icon="mdi-email"
                        name="email"
                        label="Email"
                        type="text"
                        v-model="email"
                        :rules="[rules.required, rules.counter, rules.email]"
                    ></v-text-field>
<!--                    <v-text-field
                        id="password"
                        prepend-icon="mdi-lock"
                        name="password"
                        label="Password"
                        type="password"
                        v-model="password"
                        :rules="[rules.required, rules.counterMin, rules.pass]"
                    ></v-text-field>-->
                    <v-row>
                      <v-col>
                        <v-text-field
                            prepend-icon="mdi-email"
                            name="name"
                            label="Imię"
                            density="compact"
                            type="text"
                            v-model="firstName"
                            :rules="firstNameRules"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                            prepend-icon="mdi-email"
                            name="lastName"
                            label="Nazwisko"
                            density="compact"
                            type="text"
                            v-model="lastName"
                            :rules="lastNameRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <ActionsButton @annulled="dialog = false" @save="fetchAddUser"
                                 :disabledSave="!isFormValid"></ActionsButton>
                </v-form>
              </v-card>
            </v-dialog>

          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              density="compact"
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items-length="totalItems"
              :items="users.users"
              :loading="loading"
              :search="search"
              class="elevation-1"
              item-value="id"
          >

            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.selectable.id }}</td>
                <td>{{ item.selectable.email }}</td>
                <td>{{ item.selectable.firstName }}</td>
                <td>{{ item.selectable.lastName }}</td>
                <td align="end">

                  <v-btn class="mx-2" append-icon="mdi-pencil-box" size="x-small" fab dark color="blue"
                         :to="'/user/profile/' + item.selectable.id"
                         v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_USER_SAVE'])">
                    Edytuj
                  </v-btn>
                  <RemoveButton @confirm="fetchDeleteUser(item.selectable.id)"
                                v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN','ROLE_OWNER','ROLE_USER_REMOVE'])">

                  </RemoveButton>

                </td>
              </tr>
            </template>

          </v-data-table>
        </v-card>
      </v-main>
    </v-layout>


  </div>
  <div v-else>
    <DenyAccessPage></DenyAccessPage>
  </div>
</template>


<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import DenyAccessPage from "@/components/DenyAccessPage.vue";
import {useStorage} from "vue3-storage";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";
import ActionsButton from "@/components/navigation/ActionsButton.vue";

export default {
  name: 'UsersView',
  components: {ActionsButton, RemoveButton, DenyAccessPage, BreadcrumbsComponent},
  data: () => {
    return {
      isFormValid: false,
      rules: {
        required: value => !!value || 'Wymagane.',
        counter: value => value.length <= 180 || 'Maksymalna ilość znaków to 180',
        counterMin: value => value.length >= 8 || 'Minimalna ilość znaków to 8',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Błędny format adresu email.'
        },
        pass: value => {
          const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).*$/
          return pattern.test(value) || 'Hasło musi zawierać przynajmniej jedna cyfrę, jedną małą literę, jedną wielką literę i jeden znak specjalny.'
        },
      },
      breadcrumbs: [
        {
          title: 'Użytkownicy',
          disabled: false,
          to: '/users',
        },
      ],

      dialog: false,
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Email', key: 'email', align: 'start'},
        {title: 'Imię', key: 'firstName', align: 'start'},
        {title: 'Nazwisko', key: 'lastName', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      loading: false,
      totalItems: null,
      itemId: null,
      email: "",
      password: "",
      passwordRules: [
        value => {
          if (value) return true
          return 'Hasło jest wymagane.'
        },
      ],

      firstName: "",
      firstNameRules: [
        value => {
          if (value) return true
          return 'Wymagane'
        },
      ],
      lastName: "",
      lastNameRules: [
        value => {
          if (value) return true
          return 'Wymagane'
        },
      ],

    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken', "getRoles"]),
    ...mapState(["users"])
  },
  mounted() {
    this.totalItems = this.users.users.length;
  },
  methods: {
    ...mapActions(['addUser']),

    async fetchAddUser() {
      await this.$store.dispatch('addUser', {
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
      });
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      if (this.$store.state.users.responseStatus === 'success') {
        this.dialog = false;
      }
    },
    async fetchDeleteUser(idUser) {
      await this.$store.dispatch('removeUser', idUser);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },

    checkRoles(roles) {
      let allRoles = this.$store.state.roles;
      if (allRoles === null) {
        allRoles = useStorage().getStorageSync("roles");
      }
      return roles.some(role => allRoles.includes(role));
    },
  }
}
</script>
