import axios from "axios";

const success_addCustomer = "Klienta dodano poprawnie";
const success_updateCustomer = "Klienta zaktualizowano poprawnie";
const success_removeCustomer = "Klienta usunięto poprawnie";
const host = process.env.VUE_APP_API_LOCATION + '';
export const customers = {
    state: {
        customers: [],
        responseStatus: '',
        responseMessage: ''
    },
    getters: {
        getCustomers: state => {
            return state.customers;
        },
        getCustomerById: (state) => (id) => {
            return state.customers.find(item => Number(item.id) === Number(id));
        }
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        },
        setResponseStatus(state, status) {
            state.responseStatus = status;
        },
        setCustomers(state, items) {
            state.customers = items;
        },
        addCustomer(state, items) {

            console.log(items);

            state.customers.push(items);
        },
        updateCustomer(state, data) {

            //console.log(data);

            const index = state.customers.findIndex(item => Number(item.id) === Number(data.id));
            state.customers.splice(index, 1, data);
        },
        removeCustomer(state, itemId) {
            state.customers = state.customers.filter(item => Number(item.id) !== Number(itemId));
        },
    },
    actions: {
        async loadCustomers({commit, rootState}) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_LOCATION + '/api/customers',
                    {headers: {Authorization: `Bearer ${rootState.token}`}});
                const items = response.data;
                commit('setCustomers', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async addCustomer({commit, rootState}, data) {
            try {
                await axios.post(host + '/api/customers',
                    data,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('addCustomer', items);
                        commit('setResponseMessage', success_addCustomer);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateCustomer({commit, rootState}, data) {
            try {
                await axios.put(host + '/api/customers/' + data.id,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('updateCustomer', items);
                        commit('setResponseMessage', success_updateCustomer);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async removeCustomer({commit, rootState}, id) {
            try {
                await axios.delete(host + '/api/customers/' + id,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(() => {
                        commit('removeCustomer', id);
                        commit('setResponseMessage', success_removeCustomer);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.error);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
    }
}
