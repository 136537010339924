<template>
  <v-btn color="grey"
         :prepend-icon="icon ?? 'mdi-close'"
         variant="elevated" size="small" @click="$emit('action')">{{ $props.label }}
  </v-btn>
</template>
<script>
export default {
  name: "AnnulledButton",
  props: ['label','icon'],
}
</script>
