<template>

  <v-card v-show="child.isPublic" class="branch" variant="outlined"
          style="border-color: #ccc; max-width: 400px; margin:4px;"
  >
    <v-card-title class="ma-1">


      <label v-if="searchProductById(child.parentId)?.oneChildren === true">
        <!-- :disabled="checkSelected(child.id) === undefined" -->
        <input type="radio" :name="'radio_' + child.parentId" :value="child.id"
               @click="handleSelected($event.target.value)" :required="child.isRequired"
               :checked="getValueFromScreen(child.id)"
        > {{ child.label }}
      </label>
      <label v-else>
        <input type="checkbox" :value="child.id"
               @click="handleSelected($event.target.value)"
               :checked="getValueFromScreen(child.id)"
        > {{ child.label }} [{{ child.id }}] <span v-if="child.isRequired">*</span>
      </label>
    </v-card-title>
    <v-card-item>
      <v-row>
        <v-col cols="auto">
          {{ readDataFromMathProject(child.id) }}

          <template v-if="child.type === 'ready'">
              <span v-if="readDataFromMathProject(child.id)?.priceReady"><strong>Cena całkowita:</strong> {{
                  readDataFromMathProject(child.id)?.priceReady
                }}</span>
            <span v-if="readDataFromMathProject(child.id)?.priceUnit "><strong>Cena jednostki:</strong>  {{
                readDataFromMathProject(child.id)?.priceUnit
              }}</span>
            <br>
          </template>

          <template v-for="feature in child.features" :key="feature.id">



            <div v-show="readDataFromMathProject(child.id, feature.id)?.eye">


              <template v-if="readDataFromMathProject(child.id, feature.id)?.input === true">
              <span><strong>{{ feature.label ?? feature.title }}</strong>
                <v-text-field :disabled="checkSelected(child.id, feature.id) === undefined"
                              variant="outlined" density="compact"
                              :value="checkSelected(child.id, feature.id) ? checkSelected(child.id, feature.id).value : feature.value"
                              v-if="readDataFromMathProject(child.id, feature.id)?.input === true"
                              @input="updateInput(child.id, feature.id, $event.target.value)"
                              hide-details
                              style="padding:0; display:inline-block; margin: 0 10px 0 10px;"
                              dense
                              height="20px"
                ></v-text-field>

                <Unit :child="child" :feature="feature"
                      :read-data-from-math-project="readDataFromMathProject(child.id, feature.id)"/>
              </span><br>
              </template>

              <template v-if="readDataFromMathProject(child.id, feature.id)?.input !== true">
              <span><strong>{{ feature.label ?? feature.title }}: </strong>
                <span v-if="feature.value !== undefined">{{ feature.value }} </span>
                <Unit :child="child" :feature="feature"
                      :read-data-from-math-project="readDataFromMathProject(child.id, feature.id)"/>
              </span><br>
              </template>
            </div>

          </template>

        </v-col>
        <v-col>
          <Images :child="child" border="1"/>
        </v-col>
      </v-row>
    </v-card-item>

  </v-card>
  <v-row v-show="child.isPublic">
    <v-col cols="auto" v-for="childNew in child.children" :key="childNew.id">
      <BranchView
          :child="childNew"
          :handleSelected="handleSelected"
          :checkSelected="checkSelected"
          :getValueFromScreen="getValueFromScreen"
          :readDataFromMathProject="readDataFromMathProject"
          :updateInput="updateInput"
          :searchProductById="searchProductById"
      />
    </v-col>
  </v-row>
</template>
<script>
import Images from "@/views/tickets/Images.vue";
import Unit from "@/views/tickets/Unit.vue";

export default {
  name: 'BranchView',
  components: {Unit, Images},
  props: {
    child: {},
    handleSelected: {},
    checkSelected: {},
    getValueFromScreen: {},
    readDataFromMathProject: {},
    updateInput: {},
    searchProductById: {}
  }
}
</script>
<style>
.branch .v-field__input {
  height: 20px !important;;
  min-height: 10px !important;;
  padding: 2px;
  width: 100px;
  position: relative;
}

.branch .v-input {
  margin-top: 5px;
}
</style>
