import axios from "axios";
export const productStatus = {
    state: {
        productProductStatuses: [],
        responseProductStatus: '',
        responseMessage: ''
    },
    getters: {
        getProductStatuses: state => {
            return state.productProductStatuses;
        },
        getProductStatusById: (state) => (id) => {
            ////console.log"state",state.productProductStatuses);
            return state.productProductStatuses.find(item => item.id === Number(id));
        },
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        },
        setResponseProductStatus(state, status) {
            state.responseProductStatus = status;
        },
        setProductProductStatuses(state, items) {
            state.productProductStatuses = items;
        },
        addProductStatus(state, items) {
            state.productProductStatuses.push(items);
        },
        updateProductStatus(state, data) {
            const index = state.productProductStatuses.findIndex(item => item.idProductStatus === data.idProductStatus);
            state.productProductStatuses.splice(index, 1, {id: data.idProductStatus ,
                name: data.item.name,
                description: data.item.description,
                icon: data.item.icon,
                color: data.item.color,
            });



        },
        removeProductStatus(state, itemId) {
            state.productProductStatuses = state.productProductStatuses.filter(item => item.id !== itemId);
        },
    },
    actions: {
        async loadProductProductStatuses({commit, rootState }) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_LOCATION + '/api/product_statuses',
                    {headers: {Authorization: `Bearer ${rootState .token}`}});
                const items = response.data;
                commit('setProductProductStatuses', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseProductStatus', 'error');
            }
        },
        async addProductStatus({commit, rootState }, data) {
            try {
                await axios.post(process.env.VUE_APP_API_LOCATION + '/api/product_statuses',
                    data,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('addProductStatus', items);
                        commit('setResponseMessage', 'ProductStatus został dodany');
                        commit('setResponseProductStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseProductStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseProductStatus', 'error');
            }
        },
        async updateProductStatus({commit, rootState }, data) {
           ////console.log'updateProductStatus', data)
            try {
                await axios.put(process.env.VUE_APP_API_LOCATION + '/api/product_statuses/' + data.idProductStatus,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(() => {
                        commit('updateProductStatus', data);
                        commit('setResponseMessage', 'ProductStatus został zaktualizowany');
                        commit('setResponseProductStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseProductStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseProductStatus', 'error');
            }
        },
        async removeProductStatus({commit, rootState }, itemId) {
            try {
                await axios.delete(process.env.VUE_APP_API_LOCATION + '/api/product_statuses/' + itemId,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(() => {
                        commit('removeProductStatus', itemId);
                        commit('setResponseMessage', 'ProductStatus został usunięty');
                        commit('setResponseProductStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseProductStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseProductStatus', 'error');
            }
        }
    }
}
