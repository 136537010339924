<template>
  <v-btn class="mx-2" append-icon="mdi-trash-can" size="x-small" fab dark color="green">
    Zobacz
    <v-dialog
        v-model="dialog[$props.customerId]"
        activator="parent"
        height="600"
    >
      <v-card>
        <v-card-title>
          ID: {{ $props.customerId }}
          <v-btn icon @click="dialog[$props.customerId] = false" class="float-end"
                 variant="plain"
                 density="compact">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-item>
          <CustomerForm :id="$props.customerId" @annulled="dialog[$props.customerId] = false"></CustomerForm>
        </v-card-item>
      </v-card>
    </v-dialog>
  </v-btn>

</template>

<script>
import CustomerForm from "@/components/CustomerForm.vue";

export default {
  name: "CustomerDetail",
  components: {CustomerForm},
  props: ['customerId'],
  data: () =>{
    return {
      dialog: []
    }
  }
}
</script>

<style scoped>

</style>
